import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';

import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
} from 'react-table';
import Page from 'components/page/page';
import useCourseChats from 'hooks/use-course-chats.hooks';
import { SortingIndicator} from 'components';

/* Interfaces */
enum PAGE_STATE {
    ERROR,
    BOOTING,
    EDITING,
    SAVING,
}

type USER = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    sessionsCompleted: number;
    modulesCompleted: number;
    createdAt: string;
};

import styles from './course-chat.module.css';

type ColumnType = {
    Header: string;
    accessor: keyof USER;
};

const columns: ColumnType[] = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'First Name',
        accessor: 'firstName',
    },
    {
        Header: 'Last Name',
        accessor: 'lastName',
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Sessions Completed',
        accessor: 'sessionsCompleted',
    },
    {
        Header: 'Modules Completed',
        accessor: 'modulesCompleted',
    },
    {
        Header: 'Messages Sent',
        accessor: 'messageCount' as keyof USER,
    },
    {
        Header: 'Joined At',
        accessor: 'createdAt',
    },
];

const CourseChatsPage = (): JSX.Element => {
    /* Hooks n State */
    const kajabiCourse = useCourseChats();
    const [_kajabiUsers, _setKajabiUsers] = useState<USER[]>([]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: _kajabiUsers,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
    );

    /// Load sessions on init
    useEffect(() => {
        kajabiCourse.get();
    }, []);

    useEffect(() => {
        const _users = kajabiCourse.all
            .map((item) => {
                // const possibleMessageCount = kajabiCourse?.conversationCounts[
                //     item.userId
                // ] as any;
                console.log(item.createdAt);

                return {
                    id: item.userId,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    email: item.email,
                    modulesCompleted: Object.values(
                        item?.cultivatingDesire?.modulesCompleted || {},
                    ).length,
                    sessionsCompleted: Object.values(
                        item?.cultivatingDesire?.sessionsCompleted || {},
                    ).length,
                    createdAt: moment(item.createdAt).format(
                        'MMM DD, YYYY',
                    ),
                    messageCount: item.messageCount,
                };
            })
            .filter((item) => item.email);
        _setKajabiUsers(_users);
    }, [kajabiCourse.all, kajabiCourse.conversationCounts]);

    /* Handlers */
    const _downloadConvo = async (userId: string) => {
        const csv = await kajabiCourse.getConversationAsCSV(userId);

        // Save the downloaded CSV file
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
        const url = URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'data.csv';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    /* Render */
    const _isLoading = false;
    return (
        <Page
            isLoading={_isLoading}
            horizontalPadding={{left: false, right: false}}
            title="Kajabi Participants">
            <div className={styles.mainContainer}>
                <table
                    {...getTableProps()}
                    className={styles.mainTable}
                    cellSpacing={0}>
                    <thead>
                        {headerGroups.map((headerGroup, i) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={`header-group-${i}`}>
                                {headerGroup.headers.map((column) => {
                                    const _headerProps = column.getHeaderProps(
                                        column.getSortByToggleProps(),
                                    );

                                    if (column.id === 'status') return null;

                                    return (
                                        // to do - when I assign a key here typescript says that it's already been assigned
                                        <th
                                            {..._headerProps}
                                            key={_headerProps.key}>
                                            <div className={styles.columnTitle}>
                                                {column.render('Header')}
                                                <SortingIndicator
                                                    column={column}
                                                />
                                            </div>
                                            {/* If we want to bring back the filtering by column */}
                                            {/* <div>
                                            {column.id !== 'col1' && <Filter column={column} />}
                                        </div> */}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr
                                    className={styles.row}
                                    {...row.getRowProps()}
                                    key={index}
                                    onClick={() =>
                                        _downloadConvo(`${row.original.id}`)
                                    }>
                                    {row.cells.map((cell) => {
                                        const _cellProps = cell.getCellProps();

                                        return (
                                            <td
                                                {..._cellProps}
                                                key={_cellProps.key}
                                                // To do - how to do dynamic styling in css modules
                                                className={`${styles.cell} `}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Page>
    );
};

export default CourseChatsPage;
