import {useContext} from 'react';

import {snackbarContext} from 'providers/snackbar/snackbar.provider';
import {SnackbarAPI} from 'providers/snackbar/snackbar.types';

function useSnackbar(): SnackbarAPI {
    const _snackbar = useContext(snackbarContext);

    return _snackbar;
}

export default useSnackbar;
