import React, {useEffect, useState} from 'react';

import Divider from '@material-ui/core/Divider';

import FeaturedCarousel from 'components/featured-carousel/featured-carousel.view';
import GalleryCarousel from 'components/screen-block/gallery-carousel/gallery-carousel.view';
import ExpertsCarousel from 'components/screen-block/experts-carousel/experts-carousel.view';
import ProgramsCarousel from 'components/screen-block/programs-carousel/programs-carousel.view';
import Page, {PageProps, FabOption} from 'components/page/page';
import Upsell from 'components/screen-block/upsell/upsell.view';
import StatusChip from 'components/status-chip/status-chip';
import SyncBar from 'components/sync-bar/sync-bar';
import {
    APP_SCREEN,
    CONTENT_BLOCK,
    BLOCK_TYPE,
    SortDirection,
    CB_STATUS,
    CB_FEATURED_CONTENT,
} from 'types/app-screen.types';
import {
    getAppScreen,
    createNewBlockForScreen,
    saveScreen,
    syncScreenWithProduction,
} from 'api/app-screens.api';
import {translateBlockType} from 'helpers/app-screen.helper';
import useSnackbar from 'hooks/use-snackbar.hooks';

import styles from './app-screen.module.css';
import ErrorMessage from 'components/error-message/error-message.view';

interface AppScreenProps extends Omit<PageProps, 'children'> {
    screenId: string;
}

enum PAGE_STATE {
    ERROR,
    BOOTING,
    EDITING,
    SAVING,
}

const fabOptions: FabOption[] = [];
for (const _type in BLOCK_TYPE) {
    fabOptions.push({
        label: translateBlockType(BLOCK_TYPE[_type as keyof typeof BLOCK_TYPE]),
        value: BLOCK_TYPE[_type as keyof typeof BLOCK_TYPE],
    });
}

const Screen = ({screenId, ...props}: AppScreenProps): JSX.Element => {
    /* Hooks n State*/
    const [_pageState, _setPageState] = useState<PAGE_STATE>(
        PAGE_STATE.BOOTING,
    );

    const _snackbar = useSnackbar();
    const [_screen, _setScreen] = useState<APP_SCREEN | null>(null);
    const [_blocks, _setBlocks] = useState<CONTENT_BLOCK[]>([]);

    const _errors = {};

    // Load content on mount
    useEffect(() => {
        async function _load() {
            const _screenData = await getAppScreen(screenId);
            _setPageState(PAGE_STATE.EDITING);
            _setScreen(_screenData.screen);
            _setBlocks(_screenData.blocks);
        }

        _load();
    }, [screenId]);

    /* Handlers */
    async function _onSave() {
        if (_screen) {
            try {
                _setPageState(PAGE_STATE.SAVING);
                const {blocks, screen} = await saveScreen(_screen, _blocks);
                _setBlocks(blocks);
                _setScreen(screen);
                _setPageState(PAGE_STATE.EDITING);
            } catch (error) {
                _setPageState(PAGE_STATE.EDITING);
                _snackbar.error(error.message);
            }
        }
    }

    async function _onCreateBlockClick(type: BLOCK_TYPE) {
        try {
            _setPageState(PAGE_STATE.SAVING);
            const _newBlock = await createNewBlockForScreen(
                screenId,
                type,
                _blocks.length,
            );
            _setBlocks([..._blocks, _newBlock]);
            _setPageState(PAGE_STATE.EDITING);
        } catch (error) {
            _setPageState(PAGE_STATE.EDITING);
            _snackbar.error(error.message);
        }
    }

    function _onPropertyChange(blockId: string, key: string, value: any) {
        const _block = _blocks.find(
            (block: CONTENT_BLOCK) => block.id === blockId,
        );

        const _blockIndex = _blocks.findIndex(
            (block: CONTENT_BLOCK) => block.id === blockId,
        );

        if (_block) {
            const _newBlocks = [..._blocks];
            (_block as any)[key] = value;
            _block.isDirty = true;
            _newBlocks[_blockIndex] = _block;

            _setBlocks(_newBlocks);
        }
    }

    async function _handleSync() {
        if (_screen) {
            try {
                _setPageState(PAGE_STATE.SAVING);
                const {blocks, screen} = await saveScreen(
                    _screen,
                    _blocks,
                    true,
                );
                await syncScreenWithProduction(_screen.id);
                _setBlocks(blocks);
                _setScreen(screen);
                _setPageState(PAGE_STATE.EDITING);
            } catch (error) {
                _setPageState(PAGE_STATE.EDITING);
                _snackbar.error(error.message);
            }
        }
    }

    async function _updateBlockSort(sort: number, direction: SortDirection) {
        const _newBlocks = [..._blocks];
        let _movedBlock = null;
        const _indexToUpdate = direction === 'up' ? sort - 1 : sort + 1;

        _movedBlock = _newBlocks[_indexToUpdate];
        _newBlocks[_indexToUpdate] = _newBlocks[sort];
        _newBlocks[_indexToUpdate].sort = _indexToUpdate;
        _newBlocks[_indexToUpdate].isDirty = true;
        _newBlocks[sort] = _movedBlock;
        _newBlocks[sort].sort = sort;

        _setBlocks(_newBlocks);
    }

    function _onFeaturedContentChange(content: CB_FEATURED_CONTENT[]) {
        if (_screen) {
            const _updatedScreen = {
                ..._screen,
                featured: content,
            };

            _setScreen(_updatedScreen);
        }
    }

    /* Render */
    const _isLoading =
        _pageState === PAGE_STATE.BOOTING || _pageState === PAGE_STATE.SAVING;

    let _StatusChipNode = null;
    if (_screen) {
        _StatusChipNode = (
            <StatusChip
                // clickable={true}
                // onChange={_onStatusChange}
                status={_screen.status}
                releaseDate={_screen.createdAt}
                lastSaveDate={_screen.lastSaveDate}
                lastSyncDate={_screen.lastSyncDate}
            />
        );
    }

    return (
        <Page
            {...props}
            rightButton={{
                action: _onSave,
                cta: 'Save',
            }}
            onFabPress={_onCreateBlockClick}
            fabOptions={fabOptions}
            titleAdornment={_StatusChipNode}
            isLoading={_isLoading}>
            <div className={styles.container}>
                {_screen && (
                    <div className={styles.section}>
                        <div className={styles.sectionHead}>
                            <div className={styles.sectionTitle}>
                                1. Featured Content
                            </div>
                            <div className={styles.sectionText}>
                                Highlighted content, shown in a slider on top of
                                the Exercises screen
                            </div>
                        </div>
                        <Divider />
                        <div className={styles.sectionContent}>
                            <FeaturedCarousel
                                onChange={_onFeaturedContentChange}
                                value={_screen.featured}
                            />
                        </div>
                    </div>
                )}
                {_screen && _blocks && (
                    <div className={styles.section}>
                        <div className={styles.sectionHead}>
                            <div className={styles.sectionTitle}>
                                2. Content blocks
                            </div>
                            <div className={styles.sectionText}>
                                Highlighted content, shown in a slider on top of
                                the Exercises screen
                            </div>
                        </div>
                        <Divider />
                        <div className={styles.sectionContent}>
                            {_blocks.length ? (
                                <div className={styles.blocks}>
                                    {_blocks.map((block: CONTENT_BLOCK) => {
                                        if (
                                            block.status ===
                                            CB_STATUS.UNPUBLISHED
                                        )
                                            return null;
                                        switch (block.type) {
                                            case BLOCK_TYPE.GALLERY_CAROUSEL:
                                                return (
                                                    <GalleryCarousel
                                                        key={block.id}
                                                        block={block}
                                                        blocksCount={
                                                            _blocks.length
                                                        }
                                                        errors={_errors}
                                                        onChange={
                                                            _onPropertyChange
                                                        }
                                                        onSort={
                                                            _updateBlockSort
                                                        }
                                                    />
                                                );
                                            case BLOCK_TYPE.PROGRAMS_CAROUSEL:
                                                return (
                                                    <ProgramsCarousel
                                                        key={block.id}
                                                        block={block}
                                                        blocksCount={
                                                            _blocks.length
                                                        }
                                                        errors={_errors}
                                                        onChange={
                                                            _onPropertyChange
                                                        }
                                                        onSort={
                                                            _updateBlockSort
                                                        }
                                                    />
                                                );
                                            case BLOCK_TYPE.UPSELL:
                                                return (
                                                    <Upsell
                                                        key={block.id}
                                                        block={block}
                                                        blocksCount={
                                                            _blocks.length
                                                        }
                                                        errors={_errors}
                                                        onChange={
                                                            _onPropertyChange
                                                        }
                                                        onSort={
                                                            _updateBlockSort
                                                        }
                                                    />
                                                );
                                            case BLOCK_TYPE.EXPERTS:
                                                return (
                                                    <ExpertsCarousel
                                                        key={block.id}
                                                        block={block}
                                                        blocksCount={
                                                            _blocks.length
                                                        }
                                                        errors={_errors}
                                                        onChange={
                                                            _onPropertyChange
                                                        }
                                                        onSort={
                                                            _updateBlockSort
                                                        }
                                                    />
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </div>
                            ) : (
                                <ErrorMessage message="This screen has no blocks attached to it and will be empty in the app." />
                            )}
                        </div>
                    </div>
                )}
            </div>
            {_screen && (
                <SyncBar
                    type="screen"
                    onSyncClick={_handleSync}
                    lastSaveDate={_screen.lastSaveDate}
                    lastSyncDate={_screen.lastSyncDate}
                />
            )}
        </Page>
    );
};

export default Screen;
