import {firestore, functions} from 'firebase/app';
import {
    firestoreObjectToProgram,
    generateNewProgram,
} from 'helpers/program.helper';
import {ACTIVITY_STATUS} from 'types/activity.types';
import {FIRESTORE_PROGRAM, PROGRAM} from 'types/programs.types';
export async function subscribeToPrograms(
    handler: (programs: PROGRAM[]) => void,
): Promise<() => void> {
    try {
        const _firestore = firestore();

        const _subscription = _firestore
            .collection('programmes')
            .orderBy('lastSaveDate', 'desc')
            .onSnapshot((snapshot) => {
                const _programs: PROGRAM[] = [];
                snapshot.forEach((program) => {
                    const _programData = program.data() as FIRESTORE_PROGRAM;
                    _programs.push(
                        firestoreObjectToProgram(_programData, program.id),
                    );
                });
                handler(_programs);
            });

        return _subscription;
    } catch (error) {
        throw new Error(error);
    }
}

export async function getProgram(id: string): Promise<PROGRAM> {
    try {
        const _firestore = firestore();
        const _docRef = _firestore.collection('programmes').doc(id);

        const _doc = await _docRef.get();
        if (!_doc.exists) throw new Error("Document doesn't exist");

        const _data = _doc.data() as FIRESTORE_PROGRAM;

        if (_data) {
            return firestoreObjectToProgram(_data, _doc.id);
        }

        throw new Error('Something went wrong');
    } catch (error) {
        throw new Error(error);
    }
}

/* Create new program */
export async function createProgram(source?: PROGRAM): Promise<string> {
    try {
        const _firestore = firestore();
        const _program = generateNewProgram(source);

        const _docRef = await _firestore.collection('programmes').add(_program);
        return _docRef.id;
    } catch (error) {
        throw new Error(error);
    }
}

/* Save program */
export async function updateProgram(
    program: PROGRAM | Partial<PROGRAM>,
    id: string,
    fromSync = false,
    partialSave = false,
): Promise<PROGRAM> {
    try {
        const _firestore = firestore();
        const _programRef = _firestore.collection('programmes').doc(id);

        const _lastSaveDate = new Date();

        const _dataToSave: Partial<PROGRAM> = {
            ...program,
            lastSaveDate: _lastSaveDate,
        };

        if (fromSync) {
            _dataToSave.lastSyncDate = _lastSaveDate;
        }

        if (
            _dataToSave.status &&
            _dataToSave.status !== ACTIVITY_STATUS.UNPUBLISHED &&
            _dataToSave.status !== ACTIVITY_STATUS.DRAFT &&
            !fromSync
        ) {
            _dataToSave.status = ACTIVITY_STATUS.NOT_SYNCED;
        }
        await _programRef.set(_dataToSave, {
            merge: fromSync || partialSave,
        });

        const _newSnapshot = await _programRef.get();
        const _newProgramData = _newSnapshot.data() as FIRESTORE_PROGRAM;

        return firestoreObjectToProgram(_newProgramData, id);
    } catch (error) {
        throw new Error(error);
    }
}

/* Sync with Production database */
export async function syncProgramWithProduction(id: string): Promise<PROGRAM> {
    try {
        const _oldProgramData = await getProgram(id);
        const _savedProgram = await updateProgram(
            {
                status: ACTIVITY_STATUS.PUBLISHED,
                previousLatestVersion: null,
            },
            id,
            true,
        );
        const _cloudHandler = functions().httpsCallable(
            'syncProgramWithProduction',
        );
        await _cloudHandler({
            id,
            isDev: process.env.REACT_APP_ENVIRONMENT === 'development',
        });

        if (_oldProgramData.previousLatestVersion) {
            await syncProgramWithProduction(
                _oldProgramData.previousLatestVersion,
            );
        }

        return _savedProgram;
    } catch (error) {
        throw new Error(error);
    }
}

export async function updateLiveVersion(
    id: string,
    previousLiveId: string,
): Promise<PROGRAM> {
    await updateProgram(
        {
            latest_version: false,
            status: ACTIVITY_STATUS.NOT_SYNCED,
        },
        previousLiveId,
        false,
        true,
    );

    const _updatedProgram = await updateProgram(
        {
            latest_version: true,
            previousLatestVersion: previousLiveId,
            status: ACTIVITY_STATUS.NOT_SYNCED,
        },
        id,
        false,
        true,
    );

    return _updatedProgram;
}
