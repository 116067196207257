import React from 'react';
// to do - consistent capitalisation
import StyledSignInButton from './sign-in-button.styles';

interface Props {
    className: string;
    onClick: () => void;
}

const PublishedChip: React.FC<Props> = (props: Props) => {
    const {onClick, className} = props;

    return (
        <StyledSignInButton
            onClick={onClick}
            className={className}
            color="primary"
            variant="contained">
            Sign in with Google
        </StyledSignInButton>
    );
};
export default PublishedChip;
