import React, {useEffect, useState} from 'react';
import {JsonEditor as Editor} from 'jsoneditor-react';
// import ace from 'brace';
import Ajv from 'ajv/dist/jtd';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/chrome';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import {TransitionProps} from '@material-ui/core/transitions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import CodeIcon from '@material-ui/icons/Code';
import EventIcon from '@material-ui/icons/Event';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

import Loader from 'components/loader/loader';
import {generateId} from 'helpers/generate-id.helper';
import {jsonToProgram} from 'helpers/program.helper';
import {PROGRAM, ProgramValidationSchema} from 'types/programs.types';

import styles from './json-editor-dialog.module.css';
import useSnackbar from 'hooks/use-snackbar.hooks';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {children?: React.ReactElement},
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

const AJV = new Ajv();

interface JsonEditorDialogProps {
    open: boolean;
    isSaving: boolean;
    multiselect?: boolean;
    onClose: () => void;
    onChange?: (value: any) => void;
    onSave: (value: any) => void;
    snippets?: Snippet[];
    title: string;
    value: any | null;
    validate: (json: any) => boolean;
}

export interface Snippet {
    key: string;
    label: string;
    snippet: any;
}

const _today = new Date();

const JSONEditorDialog = (props: JsonEditorDialogProps): JSX.Element => {
    /* Hooks n State */
    const _snackbar = useSnackbar();
    const [_activeMenu, _setActiveMenu] = useState<HTMLElement | null>(null);
    const [_isPickingDate, _setIsPickingDate] = useState<boolean>(false);
    const [_value, _setValue] = useState<any>(props.value);

    useEffect(() => {
        _setValue(props.value);
    }, [props.value]);

    /* Handlers */
    function _onChange(data: any) {
        _setValue(data);
    }

    function _onDatePickerClose() {
        _setIsPickingDate(false);
    }

    async function _onGenerateId() {
        try {
            const _id = await generateId();

            await navigator.clipboard.writeText(_id);
            _snackbar.success('Copied "' + _id + '" to clipboard!');
        } catch (error) {
            _snackbar.error(error.message);
        }
    }

    function _onPickDate() {
        _setIsPickingDate(true);
    }

    async function _onSnippetSelect(snippet: Snippet) {
        try {
            const _json = JSON.stringify(snippet.snippet, null, '\t');

            await navigator.clipboard.writeText(_json);
            _snackbar.success('Copied ' + snippet.label + ' to clipboard!');
        } catch (error) {
            _snackbar.error("Whoops! Couldn't copy this snippet");
        }
    }

    function _onSnippetsShow(event: React.MouseEvent<HTMLButtonElement>) {
        _setActiveMenu(event.currentTarget);
    }

    function _onSnippetsClose() {
        _setActiveMenu(null);
    }

    async function _onDatePickerChange(date: MaterialUiPickersDate) {
        if (date) {
            try {
                await navigator.clipboard.writeText(date.format());
                _snackbar.success('Copied timestamp to clipboard!');
            } catch (error) {
                _snackbar.error(error.message);
            }
        }
    }

    function _onSave() {
        const _validate = AJV.compile(ProgramValidationSchema);
        const _isValid = _validate(_value);

        // TODO: sValidate props.validate
        if (!_isValid && _validate.errors?.length) {
            _snackbar.error(
                _validate.errors[0].message || 'Something is missing!',
                _validate.errors[0].instancePath || '',
            );
        } else {
            props.onSave(jsonToProgram(_value));
        }
    }

    /* Render */
    return (
        <Dialog open={props.open} fullScreen TransitionComponent={Transition}>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    <div className={styles.headerTitle}>{props.title}</div>
                </div>
                <div className={styles.headerRight}>
                    <IconButton
                        className={styles.closeButton}
                        color="secondary"
                        onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <div className={styles.container}>
                <Editor
                    ace={ace}
                    ajv={AJV}
                    theme="ace/theme/chrome"
                    mode="code"
                    value={props.value}
                    onChange={_onChange}
                    schema={ProgramValidationSchema}
                />
            </div>
            <div className={styles.bottomBar}>
                <div className={styles.bottomBarContent}>
                    <div className={styles.bottomBarContentItem}>
                        <Button
                            onClick={_onGenerateId}
                            variant="outlined"
                            startIcon={<AddCircleIcon fontSize="small" />}>
                            Generate an ID
                        </Button>
                    </div>
                    <div className={styles.bottomBarContentItem}>
                        <Button
                            onClick={_onPickDate}
                            variant="outlined"
                            startIcon={<EventIcon fontSize="small" />}>
                            Pick a date
                        </Button>
                    </div>
                    {props.snippets && (
                        <div className={styles.bottomBarContentItem}>
                            <Button
                                color="secondary"
                                onClick={_onSnippetsShow}
                                variant="outlined"
                                startIcon={<CodeIcon fontSize="small" />}>
                                {'Copy a snippet (' +
                                    props.snippets.length +
                                    ')'}
                            </Button>
                            <Menu
                                id={'menu-snippets'}
                                anchorEl={_activeMenu}
                                onClose={_onSnippetsClose}
                                open={Boolean(_activeMenu)}>
                                {props.snippets.map((snippet) => (
                                    <MenuItem
                                        key={snippet.key}
                                        onClick={() =>
                                            _onSnippetSelect(snippet)
                                        }>
                                        {snippet.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    )}
                </div>
                <Button color="primary" variant="contained" onClick={_onSave}>
                    Save
                </Button>
            </div>
            <KeyboardDateTimePicker
                className={styles.datepicker}
                format="DD/MM/yyyy"
                onChange={_onDatePickerChange}
                onClose={_onDatePickerClose}
                open={_isPickingDate}
                value={_today}
                variant="dialog"
            />
            {props.isSaving && <Loader overlay />}
        </Dialog>
    );
};

export default JSONEditorDialog;
