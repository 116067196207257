import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';

import {
    getProgram,
    syncProgramWithProduction,
    updateLiveVersion,
    updateProgram,
} from 'api/programs.api';
import ContentDetailBlocks from 'components/content-detail-blocks/content-detail-blocks.view';
import JSONEditorDialog, {
    Snippet,
} from 'components/json-editor-dialog/json-editor-dialog.view';
import OtherProgramVersions from 'components/other-program-versions/other-program-versions.view';
import Page from 'components/page/page';
import ProgramModules from 'components/program-modules/program-modules.view';
import StatusChip from 'components/status-chip/status-chip';
import SyncBar from 'components/sync-bar/sync-bar';
import {
    PROGRAM_SESSION_SNIPPET,
    PROGRAM_MODULE_SNIPPET,
    PROGRAM_INTRODUCTION_SNIPPET,
    DETAIL_BLOCK_KEY_POINTS_SNIPPET,
    DETAIL_BLOCK_TEXT_SNIPPET,
    DETAIL_BLOCK_QUOTE_SNIPPET,
    DETAIL_BLOCK_PHOTO_SNIPPET,
    DETAIL_BLOCK_DIVIDER_SNIPPET,
} from 'helpers/snippets.helper';
import useSnackbar from 'hooks/use-snackbar.hooks';
import usePrograms from 'hooks/use-programs.hooks';
import {PROGRAM} from 'types/programs.types';

import styles from './program.module.css';
import useModal from 'hooks/use-modal';

/* Interfaces */
interface UrlParams {
    id: string;
}

enum PAGE_STATE {
    ERROR,
    BOOTING,
    READY,
    SAVING,
}

const SNIPPETS: Snippet[] = [
    {
        key: 'session',
        label: 'Empty session',
        snippet: PROGRAM_SESSION_SNIPPET,
    },
    {
        key: 'module',
        label: 'Empty module',
        snippet: PROGRAM_MODULE_SNIPPET,
    },
    {
        key: 'introduction',
        label: 'Introduction',
        snippet: PROGRAM_INTRODUCTION_SNIPPET,
    },
    {
        key: 'db_keypoints',
        label: 'Detail block: Key points',
        snippet: DETAIL_BLOCK_KEY_POINTS_SNIPPET,
    },
    {
        key: 'db_text',
        label: 'Detail block: Text',
        snippet: DETAIL_BLOCK_TEXT_SNIPPET,
    },
    {
        key: 'db_quote',
        label: 'Detail block: Quote',
        snippet: DETAIL_BLOCK_QUOTE_SNIPPET,
    },
    {
        key: 'db_photo',
        label: 'Detail block: Photo',
        snippet: DETAIL_BLOCK_PHOTO_SNIPPET,
    },
    {
        key: 'db_divider',
        label: 'Detail block: Divider',
        snippet: DETAIL_BLOCK_DIVIDER_SNIPPET,
    },
];

const ProgramScreen = (): JSX.Element => {
    /* Hooks n State */
    const [_pageState, _setPageState] = useState<PAGE_STATE>(
        PAGE_STATE.BOOTING,
    );
    const [_program, _setProgram] = useState<PROGRAM | null>(null);
    const [_isEditorVisible, _setIsEditorVisible] = useState<boolean>(false);
    const [_otherProgramVersions, _setOtherProgramVersions] = useState<
        PROGRAM[]
    >([]);

    const _snackbar = useSnackbar();
    const _modal = useModal();
    const {id: _currentProgramId} = useParams<UrlParams>();
    const _programs = usePrograms().all;

    // Init
    useEffect(() => {
        async function _get() {
            try {
                const _fetchedProgram = await getProgram(_currentProgramId);
                _setProgram(_fetchedProgram);

                _setPageState(PAGE_STATE.READY);
            } catch (error) {
                _snackbar.error(error.message);
            }
        }

        if (_currentProgramId) {
            _get();
        }
    }, [_currentProgramId]);

    useEffect(() => {
        if (_program && _programs.length) {
            const _matchingPrograms = _programs
                .filter(
                    (program) =>
                        program.key === _program.key &&
                        program.id !== _program.id,
                )
                .sort((a, b) => b.version - a.version);

            _setOtherProgramVersions(_matchingPrograms);
        }
    }, [_programs, _program]);

    /* Handlers */
    async function _onEdit() {
        _setIsEditorVisible(true);
    }

    function _onEditorClose() {
        _setIsEditorVisible(false);
    }

    async function _onEditorSave(program: PROGRAM) {
        try {
            _setPageState(PAGE_STATE.SAVING);
            const _updatedProgram = await updateProgram(program, program.id);
            _setProgram(_updatedProgram);
            _setPageState(PAGE_STATE.READY);
            _setIsEditorVisible(false);
        } catch (error) {
            _snackbar.error(error.message);
            _setPageState(PAGE_STATE.READY);
        }
    }

    function _validate(program: PROGRAM): boolean {
        const _isValid = true;

        //TODO: Check if actionType exists when type === 'activity'

        return _isValid;
    }

    async function _handleSync() {
        if (_program) {
            try {
                if (_program.previousLatestVersion) {
                    const _modalResult = await _modal.confirm({
                        title: 'Replace live version in Ferly app?',
                        message:
                            'Syncing this program will replace it as the live version (' +
                            _program.previousLatestVersion +
                            ') in the Ferly app. Are you sure you want to replace this?',
                    });

                    if (!_modalResult.success) {
                        return;
                    }
                }
                _setPageState(PAGE_STATE.SAVING);
                const _updatedProgram = await syncProgramWithProduction(
                    _program.id,
                );
                _setProgram(_updatedProgram);
                _setPageState(PAGE_STATE.READY);
            } catch (error) {
                _setPageState(PAGE_STATE.READY);
                _snackbar.error(error.message);
            }
        }
    }

    async function _onMakeLive() {
        const _currentlyActiveProgram = _otherProgramVersions.find(
            (program) => program.latest_version,
        );
        if (_currentlyActiveProgram) {
            const _confirmation = await _modal.confirm({
                title: 'Do you want to make this the live version?',
                message:
                    'Doing so will replace V' +
                    _currentlyActiveProgram.version +
                    ' of this program on Ferly Internal.',
            });
            if (_confirmation.success && _program) {
                _setPageState(PAGE_STATE.SAVING);
                const _newProgram = await updateLiveVersion(
                    _program.id,
                    _currentlyActiveProgram.id,
                );
                _setProgram(_newProgram);
                _setPageState(PAGE_STATE.READY);
            }
        } else {
            _snackbar.error("Couldn't find the latest version.");
        }
    }

    /* Render */
    const _isLoading =
        _pageState === PAGE_STATE.BOOTING || _pageState === PAGE_STATE.SAVING;

    const _title = _program?.title || '';

    let _StatusChipNode = null;
    let _LatestVersionInfo = null;
    if (_program) {
        _StatusChipNode = (
            <StatusChip
                status={_program.status}
                lastSaveDate={_program.lastSaveDate}
                lastSyncDate={_program.lastSyncDate}
                releaseDate={_program.releaseDate}
            />
        );

        // Latest version info
        let _latestVersionText = 'This version is not live';
        let _versionStatusClass = styles.latestVersionInfoSuccess;
        let _LatestVersionIcon = null;
        let _LiveButton = null;

        if (_program.latest_version) {
            _LatestVersionIcon = (
                <CheckCircleIcon
                    fontSize="small"
                    className={cn(
                        styles.latestVersionIcon,
                        styles.latestVersionIconSuccess,
                    )}
                />
            );
            if (!_otherProgramVersions.length) {
                _latestVersionText = "This is this program's only version";
            } else {
                _latestVersionText = "This is this program's live version";
            }
        } else {
            _LatestVersionIcon = (
                <ErrorOutlineIcon
                    fontSize="small"
                    className={cn(
                        styles.latestVersionIcon,
                        styles.latestVersionIconError,
                    )}
                />
            );
            _versionStatusClass = styles.latestVersionInfoError;
            _LiveButton = (
                <Button variant="outlined" onClick={_onMakeLive}>
                    {'Make this the live version'}
                </Button>
            );
        }

        _LatestVersionInfo = (
            <div className={cn(styles.latestVersionInfo, _versionStatusClass)}>
                {_LatestVersionIcon}
                <div className={styles.latestVersionInfoText}>
                    {_latestVersionText}
                </div>
                <div className={styles.btnMakeLive}>{_LiveButton}</div>
            </div>
        );
    }

    let _ThumbnailImage = null;
    if (_program?.backgroundUrl) {
        _ThumbnailImage = (
            <img src={_program.backgroundUrl} alt={_program.title} />
        );
    } else {
        _ThumbnailImage = (
            <div className={styles.noImage}>
                <BrokenImageIcon fontSize="large" />
                <div className={styles.noImageText}>
                    This program has no thumbnail
                </div>
            </div>
        );
    }

    return (
        <Page
            isLoading={_isLoading}
            rightButton={{
                action: _onEdit,
                cta: 'Edit',
            }}
            title={_title}
            titleAdornment={_StatusChipNode}>
            {_program && (
                <div className={styles.container}>
                    <div className={styles.section}>
                        <div className={styles.sectionHead}>
                            <div className={styles.sectionTitle}>
                                {'1. General info'}
                            </div>
                            <div className={styles.sectionText}>
                                {
                                    'General program properties, Fields with a * are required'
                                }
                            </div>
                        </div>
                        <Divider />
                        <div className={styles.sectionContent}>
                            <div className={styles.propertiesAndVersion}>
                                <div className={styles.properties}>
                                    <div className={styles.propertiesRow}>
                                        <div
                                            className={
                                                styles.propertiesRowItem
                                            }>
                                            <div className={styles.property}>
                                                <div
                                                    className={
                                                        styles.propertyTitle
                                                    }>
                                                    {'Image*'}
                                                </div>
                                                <div
                                                    className={
                                                        styles.propertyValue
                                                    }>
                                                    <div
                                                        className={
                                                            styles.thumbnail
                                                        }>
                                                        {_ThumbnailImage}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                styles.propertiesRowItem
                                            }>
                                            <div className={styles.property}>
                                                <div
                                                    className={
                                                        styles.propertyTitle
                                                    }>
                                                    {'Release Date*'}
                                                </div>
                                                <div
                                                    className={
                                                        styles.propertyValue
                                                    }>
                                                    {moment(
                                                        _program.releaseDate,
                                                    ).format('MMM DD, YYYY')}
                                                </div>
                                            </div>
                                            <div className={styles.property}>
                                                <div
                                                    className={
                                                        styles.propertyTitle
                                                    }>
                                                    {'Recommended frequency*'}
                                                </div>
                                                <div
                                                    className={
                                                        styles.propertyValue
                                                    }>
                                                    {_program.recommendedFrequency +
                                                        'x/week'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.propertiesRow}>
                                        <div
                                            className={
                                                styles.propertiesRowItem
                                            }>
                                            <div className={styles.property}>
                                                <div
                                                    className={
                                                        styles.propertyTitle
                                                    }>
                                                    {'Description*'}
                                                </div>
                                                <div
                                                    className={
                                                        styles.propertyValue
                                                    }>
                                                    {_program.description}
                                                </div>
                                            </div>
                                            <div className={styles.property}>
                                                <div
                                                    className={
                                                        styles.propertyTitle
                                                    }>
                                                    {'Designed for'}
                                                </div>
                                                <div
                                                    className={
                                                        styles.propertyValue
                                                    }>
                                                    {_program.designedFor}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.version}>
                                    <div className={styles.propertiesRow}>
                                        <div
                                            className={
                                                styles.propertiesRowItem
                                            }>
                                            <div className={styles.property}>
                                                <div
                                                    className={
                                                        styles.propertyTitle
                                                    }>
                                                    Program key
                                                </div>
                                                <div
                                                    className={
                                                        styles.propertyValue
                                                    }>
                                                    {_program.key}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                styles.propertiesRowItem
                                            }>
                                            <div className={styles.property}>
                                                <div
                                                    className={
                                                        styles.propertyTitle
                                                    }>
                                                    Version
                                                </div>
                                                <div
                                                    className={
                                                        styles.propertyValue
                                                    }>
                                                    {'V' + _program.version}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.otherVersions}>
                                        {_LatestVersionInfo}
                                        <div className={styles.propertyValue}>
                                            <div
                                                className={
                                                    styles.otherVersionsList
                                                }>
                                                <OtherProgramVersions
                                                    programs={
                                                        _otherProgramVersions
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.sectionHead}>
                            <div className={styles.sectionTitle}>
                                {'2. Program Detail Blocks'}
                            </div>
                            <div className={styles.sectionText}>
                                {
                                    "Blocks shown on the program's 'About' tab in the app."
                                }
                            </div>
                        </div>
                        <Divider />
                        <div className={styles.sectionContent}>
                            {_program.blocks?.length ? (
                                <ContentDetailBlocks blocks={_program.blocks} />
                            ) : (
                                <div className={styles.empty}>
                                    <ErrorIcon color="error" />
                                    <div className={styles.emptyText}>
                                        {
                                            'This program has no detail content blocks attached to it.'
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.sectionHead}>
                            <div className={styles.sectionTitle}>
                                {'3. Modules & Sessions'}
                            </div>
                            <div className={styles.sectionText}>
                                {"This program's modules and sessions."}
                            </div>
                        </div>
                        <Divider />
                        <div className={styles.sectionContent}>
                            {_program.modules.length ? (
                                <ProgramModules
                                    introduction={_program.introduction}
                                    modules={_program.modules}
                                />
                            ) : (
                                <div className={styles.empty}>
                                    <ErrorIcon color="error" />
                                    <div className={styles.emptyText}>
                                        {'This program has modules'}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <JSONEditorDialog
                        value={_program}
                        onSave={_onEditorSave}
                        onClose={_onEditorClose}
                        open={_isEditorVisible}
                        isSaving={_pageState === PAGE_STATE.SAVING}
                        snippets={SNIPPETS}
                        title={'Edit program'}
                        validate={_validate}
                    />
                    <SyncBar
                        onSyncClick={_handleSync}
                        type="content"
                        lastSaveDate={_program.lastSaveDate}
                        lastSyncDate={_program.lastSyncDate}
                    />
                </div>
            )}
        </Page>
    );
};

export default ProgramScreen;
