import React from 'react';
import {PROGRAM} from 'types/programs.types';

import ProgramsOverviewItem from './programs-overview-item.view';
import styles from './programs-overview.module.css';

interface ProgramsOverviewProps {
    programs: PROGRAM[];
}

const ProgramsOverview = (props: ProgramsOverviewProps): JSX.Element => {
    /* Hooks n State */

    /* Render */
    return (
        <table className={styles.table} cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th className={styles.th}></th>
                    <th className={styles.th}>Title</th>
                    <th className={styles.th}>Parent/version</th>
                    <th className={styles.th}>Mod./Sess.</th>
                    <th className={styles.th}>Release date</th>
                    <th className={styles.th}>Last saved on</th>
                    <th className={styles.th}>ID</th>
                </tr>
            </thead>
            <tbody className={styles.tbody}>
                {props.programs &&
                    props.programs.map((program) => {
                        return (
                            <ProgramsOverviewItem
                                key={program.id}
                                program={program}
                            />
                        );
                    })}
            </tbody>
        </table>
    );
};

export default ProgramsOverview;
