import React, {useEffect, useState, useRef, MutableRefObject} from 'react';
import cn from 'classnames';

import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';

import ActivitiesPickerDialog from 'components/activities-picker/activities-picker-dialog.view';
import ProgramPickerContent from 'components/program-picker-content/program-picker-content.view';
import FeaturedCarouselItem from './featured-carousel-item.view';
import {TEMP_PREFIX} from 'constants/strings.constants';
import useActivities from 'hooks/use-activities.hooks';
import usePrograms from 'hooks/use-programs.hooks';
import useModal from 'hooks/use-modal';

import {
    CB_ACTIVITY,
    CB_FEATURED_CONTENT,
    FeaturedCarouselData,
    FEATURED_CONTENT_TYPE,
    SortDirection,
} from 'types/app-screen.types';
import {PROGRAM_SOURCE} from 'types/programs.types';

import styles from './featured-carousel.module.css';

interface FeaturedCarouselProps {
    value: CB_FEATURED_CONTENT[];
    onChange: (featuredContent: CB_FEATURED_CONTENT[]) => void;
}

const FeaturedCarousel = (props: FeaturedCarouselProps): JSX.Element => {
    /* Hooks n State */
    const _activities = useActivities().all;
    const _programs = usePrograms().allByKey;

    const _modal = useModal();

    const [_featuredContent, _setFeaturedContent] = useState<
        FeaturedCarouselData[]
    >([]);

    const _modalSelectedProgram: MutableRefObject<PROGRAM_SOURCE | null> = useRef(
        null,
    );

    const [
        _selectedActivityContent,
        _setSelectedActivityContent,
    ] = useState<CB_ACTIVITY | null>(null);

    useEffect(() => {
        const _newFeaturedContent: FeaturedCarouselData[] = [];

        props.value.forEach((item) => {
            let _itemToAdd = null;

            if (!item.id?.startsWith(TEMP_PREFIX)) {
                switch (item.type) {
                    case FEATURED_CONTENT_TYPE.ACTIVITY:
                        const _activity = _activities.find(
                            (activity) => activity.id === item.id,
                        );

                        if (_activity) {
                            _itemToAdd = {
                                data: _activity,
                                type: item.type,
                                id: item.id,
                            };
                        }
                        break;
                    case FEATURED_CONTENT_TYPE.PROGRAM:
                        const _program = _programs.find(
                            (program) => program.key === item.key,
                        );

                        if (_program) {
                            _itemToAdd = {
                                data: _program,
                                type: item.type,
                                key: item.key,
                            };
                        }
                        break;
                    default:
                        break;
                }
            } else {
                _itemToAdd = {
                    data: null,
                    type: item.type,
                    id: item.id,
                };
            }

            if (_itemToAdd) _newFeaturedContent.push(_itemToAdd);
        });

        _setFeaturedContent(_newFeaturedContent);
    }, [props.value]);

    /* Handlers */
    function _onActivityClick(id: string, index: number) {
        _setSelectedActivityContent({
            id,
            sort: index,
        });
    }

    const _onProgramPickerChange = (programs: PROGRAM_SOURCE[]) => {
        _modalSelectedProgram.current = programs[0];
    };

    const _onProgramClick = async (key: string, index: number) => {
        const _result = await _modal.component({
            title: 'Select a program',
            message: 'Which program do you want to feature?',
            component: (
                <ProgramPickerContent
                    selectOnly={true}
                    latestOnly={true}
                    onChange={_onProgramPickerChange}
                />
            ),
        });

        if (_result.success) {
            if (_modalSelectedProgram.current) {
                const _index = index;
                const _newValue = [...props.value];
                _newValue[_index] = {
                    key: _modalSelectedProgram.current.key,
                    type: FEATURED_CONTENT_TYPE.PROGRAM,
                };

                props.onChange(_newValue);
            }
        }

        _modalSelectedProgram.current = null;
    };

    function _onDialogClose() {
        _setSelectedActivityContent(null);
    }

    function _onDeleteItem(index: number) {
        const _newValue = [...props.value];
        _newValue.splice(index, 1);
        props.onChange(_newValue);
    }

    function _onArrowClick(index: number, direction: SortDirection) {
        const _newValue = [...props.value];
        let _movedItem = null;
        const _indexToUpdate = direction === 'up' ? index - 1 : index + 1;

        _movedItem = _newValue[_indexToUpdate];
        _newValue[_indexToUpdate] = _newValue[index];
        _newValue[index] = _movedItem;

        props.onChange(_newValue);
    }

    function _onActivityContentPicked(activities: CB_ACTIVITY[]) {
        if (_selectedActivityContent) {
            const _index = _selectedActivityContent.sort;
            const _newValue = [...props.value];
            _newValue[_index].id = activities[0].id;
            props.onChange(_newValue);
        }
        _onDialogClose();
    }

    function _onItemAdd() {
        const _newValue = [...props.value];
        _newValue.unshift({
            id: TEMP_PREFIX + Date.now(),
            type: FEATURED_CONTENT_TYPE.ACTIVITY,
        });

        props.onChange(_newValue);
    }

    /* Render */
    return (
        <div className={styles.container}>
            {_featuredContent.length < 4 && (
                <div className={styles.item}>
                    <Box
                        boxShadow={3}
                        className={styles.buttonCreate}
                        component="button"
                        onClick={_onItemAdd}>
                        <AddIcon color="primary" />
                    </Box>
                </div>
            )}
            {_featuredContent.map((item, index) => {
                return (
                    <div
                        key={index + '-' + (item.id || item.key)}
                        className={styles.item}>
                        <FeaturedCarouselItem
                            onActivityClick={_onActivityClick}
                            onProgramClick={_onProgramClick}
                            onArrowClick={_onArrowClick}
                            onDelete={_onDeleteItem}
                            index={index}
                            totalCount={_featuredContent.length}
                            value={item}
                        />
                    </div>
                );
            })}
            <ActivitiesPickerDialog
                onClose={_onDialogClose}
                open={_selectedActivityContent !== null}
                onChange={_onActivityContentPicked}
                value={_selectedActivityContent}
            />
        </div>
    );
};

export default FeaturedCarousel;
