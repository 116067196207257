import firebase from 'config/firebase';
import {ACTIVITY, ACTIVITY_STATUS} from './activity.types';
import {PROGRAM} from 'types/programs.types';

/* Blocks */
export enum BLOCK_TYPE {
    GALLERY_CAROUSEL = 'gallery_carousel',
    PROGRAMS_CAROUSEL = 'programmes_carousel',
    UPSELL = 'upsell',
    EXPERTS = 'experts',
    // UNSPECIFIED = '',
}

export enum APP_SCREEN_STATUS {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    NOT_SYNCED = 'not_synced',
    UNPUBLISHED = 'unpublished',
}

export interface BLOCK_TYPE_TRANSLATIONS {
    [key: string]: string;
}

export enum CB_STATUS {
    PUBLISHED = 'published',
    UNPUBLISHED = 'unpublished',
}

export interface CB_ACTIVITY {
    id: string;
    sort: number;
}

export interface CB_PROGRAM {
    key: string;
    sort: number;
}

export interface CB_EXPERT {
    id: string;
    sort: number;
}

export interface CB_TAG {
    id: string;
}

export enum CB_BUTTON_STYLE {
    DARK = 'dark',
    LIGHT = 'light',
}

export interface CB_URL {
    premium: boolean;
    url: string | null;
}

export interface CB_BUTTON {
    label: string;
    style: CB_BUTTON_STYLE;
    url: CB_URL;
}

export interface BASE_CONTENT_BLOCK {
    id: string;
    type: BLOCK_TYPE;
    sort: number;
    status: CB_STATUS;

    /* CB_CAROUSEL */
    activities?: CB_ACTIVITY[];
    tags?: CB_TAG[];

    /* EXPERTS */
    experts?: CB_EXPERT[];

    /* PROGRAMS */
    programmes: CB_PROGRAM[];

    /* For internal use */
    screenName?: string;
}

export interface FIRESTORE_CONTENT_BLOCK_CONTENT {
    title: string;
    internalTitle: string;
    text: string;

    /* CB_UPSELL */
    button?: CB_BUTTON;
}

export interface FIRESTORE_CONTENT_BLOCK extends BASE_CONTENT_BLOCK {
    content: FIRESTORE_CONTENT_BLOCK_CONTENT;
    createdAt: firebase.firestore.Timestamp;
    lastSaveDate: firebase.firestore.Timestamp | null;
    lastSyncDate: firebase.firestore.Timestamp | null;
    startDate: firebase.firestore.Timestamp | null;
    endDate: firebase.firestore.Timestamp | null;
}

export interface CONTENT_BLOCK extends BASE_CONTENT_BLOCK {
    createdAt: Date | null;
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
    startDate: Date | null;
    endDate: Date | null;

    /* Content prop */
    title: string;
    internalTitle: string;
    text: string;
    id: string;

    /* CB_UPSELL */
    buttonLabel?: string;
    buttonStyle?: CB_BUTTON_STYLE;
    buttonUrl?: string | null;
    buttonPremium?: boolean;

    /* UI Specific types */
    isDirty?: boolean;
}

/* Screens */
interface BASE_APP_SCREEN {
    id: string;
    featured: CB_FEATURED_CONTENT[];
    screen: string;
    status: ACTIVITY_STATUS;
}

export interface FIRESTORE_APP_SCREEN extends BASE_APP_SCREEN {
    createdAt: firebase.firestore.Timestamp;
    lastSaveDate: firebase.firestore.Timestamp | null;
    lastSyncDate: firebase.firestore.Timestamp | null;
    blocks: FIRESTORE_CONTENT_BLOCK[];
}

export interface APP_SCREEN extends BASE_APP_SCREEN {
    createdAt: Date;
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
}

export interface SCREEN_WITH_BLOCKS {
    screen: APP_SCREEN;
    blocks: CONTENT_BLOCK[];
}

export type SortDirection = 'up' | 'down';

export enum FEATURED_CONTENT_TYPE {
    ACTIVITY = 'activity',
    PROGRAM = 'programme',
}

/* Featured content */

export interface CB_FEATURED_CONTENT {
    id?: string;
    key?: string;
    type: FEATURED_CONTENT_TYPE;
}

export interface FeaturedCarouselData {
    id?: string;
    key?: string;
    type: FEATURED_CONTENT_TYPE;
    data: ACTIVITY | PROGRAM | null;
}

/* Initial states */
export const emptyCarouselBlock: Partial<FIRESTORE_CONTENT_BLOCK> = {
    content: {
        text: '',
        title: '',
        internalTitle: '',
    },
    type: BLOCK_TYPE.GALLERY_CAROUSEL,
    lastSaveDate: null,
    lastSyncDate: null,
    startDate: null,
    endDate: null,
    sort: -1,
    activities: [],
    tags: [],
    status: CB_STATUS.PUBLISHED,
};

export const emptyProgramsBlock: Partial<FIRESTORE_CONTENT_BLOCK> = {
    content: {
        text: '',
        title: '',
        internalTitle: '',
    },
    type: BLOCK_TYPE.PROGRAMS_CAROUSEL,
    lastSaveDate: null,
    lastSyncDate: null,
    startDate: null,
    endDate: null,
    sort: -1,
    programmes: [],
    tags: [],
    status: CB_STATUS.PUBLISHED,
};

export const emptyExpertsBlock: Partial<FIRESTORE_CONTENT_BLOCK> = {
    content: {
        text: '',
        title: '',
        internalTitle: '',
    },
    type: BLOCK_TYPE.EXPERTS,
    lastSaveDate: null,
    lastSyncDate: null,
    startDate: null,
    endDate: null,
    sort: -1,
    experts: [],
    status: CB_STATUS.PUBLISHED,
};

export const emptyUpsellBlock: Partial<FIRESTORE_CONTENT_BLOCK> = {
    content: {
        text:
            'Become a Ferly member today and access all stories, practices and more.',
        title: 'Unlock Your Pleasure',
        internalTitle: '',
        button: {
            label: 'Start free trial',
            style: CB_BUTTON_STYLE.DARK,
            url: {
                premium: false,
                url: '',
            },
        },
    },
    type: BLOCK_TYPE.UPSELL,
    lastSaveDate: null,
    lastSyncDate: null,
    startDate: null,
    endDate: null,
    sort: -1,
    status: CB_STATUS.PUBLISHED,
};

/* Programs */
export interface CB_PROGRAM {
    id: string;
}
