import React from 'react';

import Box from '@material-ui/core/Box';

import {PROGRAM_BLOCK, PROGRAM_BLOCK_TYPES} from 'types/programs.types';

import styles from './content-detail-blocks.module.css';

interface ContentDetailBlocksProps {
    blocks?: PROGRAM_BLOCK[];
}

const blockTypes = {
    divider: 'Divider',
    key_points: 'Key points',
    photo: 'Image',
    quote: 'Quote',
    text: 'Text',
};

const ContentDetailBlocks = (
    props: ContentDetailBlocksProps,
): JSX.Element | null => {
    if (!props.blocks) return null;
    return (
        <div className={styles.blocks}>
            {props.blocks.map((block) => {
                let _Title = null;
                let _Caption = null;
                let _Image = null;
                let _Keypoints = null;

                if (
                    block.type === PROGRAM_BLOCK_TYPES.TEXT ||
                    block.type === PROGRAM_BLOCK_TYPES.QUOTE
                ) {
                    _Title = (
                        <div className={styles.property}>
                            <div className={styles.propertyTitle}>
                                {'Title'}
                            </div>
                            <div className={styles.propertyValue}>
                                {block.content?.title || '/'}
                            </div>
                        </div>
                    );
                }

                if (
                    block.type === PROGRAM_BLOCK_TYPES.KEY_POINTS ||
                    block.type === PROGRAM_BLOCK_TYPES.TEXT ||
                    block.type === PROGRAM_BLOCK_TYPES.QUOTE
                ) {
                    _Caption = (
                        <div className={styles.property}>
                            <div className={styles.propertyTitle}>
                                {'Caption'}
                            </div>
                            <div className={styles.propertyValue}>
                                {block.content?.caption || '/'}
                            </div>
                        </div>
                    );
                }

                if (block.type === PROGRAM_BLOCK_TYPES.PHOTO) {
                    _Image = (
                        <div className={styles.image}>
                            {block.style?.backgroundImage ? (
                                <div
                                    className={styles.imageInner}
                                    style={{
                                        backgroundImage: `url(${block.style.backgroundImage})`,
                                    }}
                                />
                            ) : (
                                ' /'
                            )}
                        </div>
                    );
                }

                if (block.type === PROGRAM_BLOCK_TYPES.KEY_POINTS) {
                    _Keypoints = (
                        <div className={styles.property}>
                            <div className={styles.propertyTitle}>
                                {'Title'}
                            </div>
                            <div className={styles.propertyValue}>
                                {block.keyPoints ? (
                                    <ul className={styles.propertyList}>
                                        {block.keyPoints.map(
                                            (keyPoint, index) => {
                                                return (
                                                    <li
                                                        className={
                                                            styles.propertyListItem
                                                        }
                                                        key={index}>
                                                        <span
                                                            className={
                                                                styles.propertyListItemTitle
                                                            }>
                                                            {keyPoint.title}
                                                        </span>
                                                        <span
                                                            className={
                                                                styles.propertyListItemText
                                                            }>
                                                            {keyPoint.text ||
                                                                '/'}
                                                        </span>
                                                    </li>
                                                );
                                            },
                                        )}
                                    </ul>
                                ) : (
                                    ' /'
                                )}
                            </div>
                        </div>
                    );
                }

                return (
                    <Box
                        boxShadow={3}
                        key={block.sort}
                        className={styles.block}>
                        <div className={styles.type}>
                            {blockTypes[block.type]}
                        </div>
                        {_Title}
                        {_Caption}
                        {_Image}
                        {_Keypoints}
                    </Box>
                );
            })}
        </div>
    );
};

export default ContentDetailBlocks;
