export enum TRANSCRIPT_BLOCK_TYPES {
    TITLE = 'title',
    TEXT = 'text',
    IMAGE = 'image',
    CALLOUT = 'callout',
    DIVIDER = 'divider',
}

export interface TRANSCRIPT {
    language: 'en';
    createdAt: Date;
    lastSaveDate: Date | null;

    blocks: TRANSCRIPT_BLOCK[];
}

export type TRANSCRIPT_BLOCK_CONTENT =
    | TRANSCRIPT_BLOCK_TITLE
    | TRANSCRIPT_BLOCK_TEXT
    | TRANSCRIPT_BLOCK_IMAGE
    | TRANSCRIPT_BLOCK_CALLOUT;
// | TRANSCRIPT_BLOCK_LIST;
// | TRANSCRIPT_BLOCK_DIVIDER

export interface TRANSCRIPT_BLOCK {
    id: string;
    sort: number;
    type: TRANSCRIPT_BLOCK_TYPES;
    content?: TRANSCRIPT_BLOCK_CONTENT;
    dirty?: boolean;
}

export interface TRANSCRIPT_BLOCK_TITLE {
    text: {
        [language: string]: string;
    };
}

export interface TRANSCRIPT_BLOCK_TEXT {
    text: {
        [language: string]: string;
    };
}

export type TRANSCRIPT_BLOCK_IMAGE_OPTION = 0 | 1 | 2 | 3;
export interface TRANSCRIPT_BLOCK_IMAGE {
    // image: TRANSCRIPT_BLOCK_IMAGE_OPTION;
    version: number;
}

export interface TRANSCRIPT_BLOCK_CALLOUT {
    text: {
        [language: string]: string;
    };
}

export interface TRANSCRIPT_BLOCK_LIST {
    text: string; // label before
    items: string[];
}

// export interface TRANSCRIPT_BLOCK_DIVIDER {}
