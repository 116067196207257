import {CONTENT_TYPE, FIRESTORE_ACTIVITY, ACTIVITY} from 'types/activity.types';
export const fireStoreObjectToActivity = (
    session: FIRESTORE_ACTIVITY,
    id: string,
): ACTIVITY => {
    const _data: ACTIVITY = {
        ...session,
        id,
        createdAt: session.createdAt ? session.createdAt.toDate() : null,
        lastSaveDate: session.lastSaveDate
            ? session.lastSaveDate.toDate()
            : null,
        lastSyncDate: session.lastSyncDate
            ? session.lastSyncDate.toDate()
            : null,
        releaseDate: session.releaseDate ? session.releaseDate.toDate() : null,
    };

    return _data;
};

export const getActivityBackgroundColor = (activity: ACTIVITY): string => {
    switch (activity?.contentType) {
        case CONTENT_TYPE.FOOD_FOR_THOUGHT:
            return '#FFD5D1';
            break;
        case CONTENT_TYPE.GUIDED_PRACTICE:
            return '#ECEDFF';
            break;
        case CONTENT_TYPE.PODCAST:
            return '#F8F8FE';
            break;
        case CONTENT_TYPE.STORY:
            return '#FFFAF9';
            break;
        default:
            return '#FFFAF9';
            break;
    }
};
