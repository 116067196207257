import React, {createContext, useRef, useState} from 'react';
import {subscribeToExperts} from 'api/experts.api';
import {EXPERT} from 'types/experts.types';

export interface ExpertsApi {
    all: EXPERT[];
    get: () => Promise<null | (() => void)>;
}

const _initialState: ExpertsApi = {
    all: [],
    get: async () => null,
};

type Props = {
    children: React.ReactNode;
};

export const ExpertsContext = createContext<ExpertsApi>(_initialState);

const ExpertsProvider = (props: Props): JSX.Element => {
    /* Hooks n State */
    const [_experts, _setExperts] = useState<EXPERT[]>([]);

    const _subscriptionRef = useRef<(() => void) | null>(null);

    /* Handlers */
    function _onExpertsSnapShot(experts: EXPERT[]) {
        _setExperts(experts);
    }

    async function _getExperts() {
        const _unsubscribe = await subscribeToExperts(_onExpertsSnapShot);

        _subscriptionRef.current = _unsubscribe;

        return _unsubscribe;
    }

    /* Render */
    const _value: ExpertsApi = {
        all: _experts,

        get: _getExperts,
    };

    return (
        <ExpertsContext.Provider value={_value}>
            {props.children}
        </ExpertsContext.Provider>
    );
};

export default ExpertsProvider;
