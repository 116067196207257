import React from 'react';
import AppScreen from 'components/app-screen/app-screen';
import {SCREENS} from 'config/screens.config';

const ExploreScreen = (): JSX.Element => {
    /* Hooks n State */

    /* Render */
    return <AppScreen title="Relax screen" screenId={SCREENS.RELAX} />;
};

export default ExploreScreen;
