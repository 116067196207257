import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import firebase from 'firebase/app';

import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

import FileUpload from 'components/file-upload/file-upload';
import Loader from 'components/loader/loader';
import Page from 'components/page/page';
import StatusChip from 'components/status-chip/status-chip';
import SyncBar from 'components/sync-bar/sync-bar';

import {
    getActivity,
    getActivityTranscriptBlocks,
    updateActivity,
} from 'api/activities.api';
import validationSchema from 'validation/validationSchema';
import initialActivityState from 'constants/initial-activity-state.constants';
import {MAX_AUDIO_SIZE} from 'constants/file-size.constants';
import {
    CONTENT_TYPE,
    ACTIVITY,
    VOICE,
    SwitchedOnAreas,
    ACTIVITY_STATUS,
    ACTIVITY_WITH_TRANSCRIPT,
} from 'types/activity.types';
import getOptions from 'helpers/get-options.helper';

import {
    FileToUpload,
    FileName,
    uploadAllFiles,
    MediaUrlProperty,
    DownloadUrl,
} from 'api/storage.api';

import useSnackbar from 'hooks/use-snackbar.hooks';
import useTags from 'hooks/use-tags.hooks';

import styles from './activity.module.css';
import TranscriptPreview from '../../components/transcript-preview/transcript-preview.view';
import {TRANSCRIPT_BLOCK} from '../../types/activity-transcript.types';
import {TEMP_FILE_NAME} from '../../constants/file-locations.constants';

const voiceOptions = getOptions(VOICE);
const contentTypeOptions = getOptions(CONTENT_TYPE);

/* Interfaces */
interface UrlParams {
    id: string;
}

enum PAGE_STATE {
    ERROR,
    BOOTING,
    EDITING,
    SAVING,
}

type Area = 'general' | 'media' | 'availability';

/* Session Page */
const Session = (): JSX.Element => {
    /* Lifecycle */
    const [_hasSubmitted, _setHasSubmitted] = useState<boolean>(false);
    const [_pageState, _setPageState] = useState<PAGE_STATE>(
        PAGE_STATE.BOOTING,
    );
    const [_switchedOnAreas, _setSwitchedOnAreas] = useState<SwitchedOnAreas>({
        general: true,
        media: true,
        availability: true,
    });
    const [_filesToUpload, _setFilesToUpload] = useState<FileToUpload[]>([]);
    const [_loadingText, _setLoadingText] = useState<string>('');
    const [_isLoading, _setIsLoading] = useState<boolean>(false);

    const _snackbar = useSnackbar();
    const _tags = useTags().all;

    // Save id from params;
    const {id: _currentSessionId} = useParams<UrlParams>();

    // Init form
    const _formik = useFormik<ACTIVITY_WITH_TRANSCRIPT>({
        initialValues: initialActivityState,
        onSubmit: _handleSubmit,
        validationSchema: () => validationSchema(_switchedOnAreas),
        validateOnChange: _hasSubmitted,
        validateOnBlur: _hasSubmitted,
    });

    // Load session by id
    useEffect(() => {
        if (_currentSessionId) {
            getActivity(_currentSessionId)
                .then(async (session: ACTIVITY) => {
                    _formik.setValues(session);
                    if (session.hasTranscript) {
                        const _sessionTranscript = await getActivityTranscriptBlocks(
                            _currentSessionId,
                        );
                        _setTranscriptBlocks(_sessionTranscript);
                    }
                    _setPageState(PAGE_STATE.EDITING);
                })
                .catch((error: Error) => {
                    _snackbar.error(error.message);
                });
        }
    }, [_currentSessionId]);

    /* Handlers */
    function _setTranscriptBlocks(blocks?: TRANSCRIPT_BLOCK[]) {
        if (blocks) {
            _formik.setFieldValue('transcriptBlocks', blocks);
        }
    }

    // console.log(_formik.values.transcriptBlocks);

    async function _handleSubmit() {
        _setHasSubmitted(true);
        _setLoadingText('Saving session to database');
        _setPageState(PAGE_STATE.SAVING);
        try {
            const _formData = _formik.values;
            const _transcriptBlocks = [...(_formData.transcriptBlocks || [])];

            if (_filesToUpload.length) {
                _setLoadingText('Uploading content');

                const _uploadedFiles = await uploadAllFiles(
                    _currentSessionId,
                    _filesToUpload,
                );
                _uploadedFiles.forEach((_newUrl: DownloadUrl) => {
                    _formData[_newUrl.property] = _newUrl.url;
                });

                _setLoadingText('Linking content to session');
            }
            const _updatedSession = await updateActivity(
                _currentSessionId,
                _formData,
                false,
                _filesToUpload.length > 0,
            );
            _setLoadingText('');

            await _formik.setValues(_updatedSession);
            _setTranscriptBlocks(_transcriptBlocks);

            _setPageState(PAGE_STATE.EDITING);
        } catch (error) {
            _setPageState(PAGE_STATE.EDITING);
            _snackbar.error(error.message);
        }
    }

    async function _handleSync() {
        _setLoadingText('Syncing session with live database');
        _setPageState(PAGE_STATE.SAVING);

        try {
            _setLoadingText('Syncing session with live database');
            // Save session with new dates
            const _transcriptBlocks = [
                ...(_formik.values.transcriptBlocks || []),
            ];
            const _shouldSyncFiles = _formik.values.hasUnsyncedMedia;
            const _updatedSession = await updateActivity(
                _currentSessionId,
                {
                    hasUnsyncedMedia: false,
                    status: _formik.values.status,
                },
                true,
                false,
            );

            const _cloudHandler = firebase
                .functions()
                .httpsCallable('syncWithProduction');
            await _cloudHandler({
                id: _currentSessionId,
                isDev: process.env.REACT_APP_ENVIRONMENT === 'development',
                // isDev: false,
                shouldSyncFiles: _shouldSyncFiles,
            });

            // Show newly fetched session
            await _formik.setValues(_updatedSession);
            _setTranscriptBlocks(_transcriptBlocks);
            _setPageState(PAGE_STATE.EDITING);
            _setLoadingText('');
        } catch (error) {
            _setPageState(PAGE_STATE.EDITING);
            _snackbar.error(error.message);
        }
    }

    function _onDateChange(date: MaterialUiPickersDate) {
        if (date) {
            _formik.setFieldValue('releaseDate', date.toDate());
        }
    }

    // function _onSectionToggle(value: Area) {

    // 	const _newState: SwitchedOnAreas = {
    // 		..._switchedOnAreas,
    // 		[value]: !_switchedOnAreas[value],
    // 	};

    // 	_setSwitchedOnAreas(_newState);
    // }

    function _onStatusChange(status: ACTIVITY_STATUS) {
        _formik.setFieldValue('status', status);
    }

    function _onFileInputChange(
        property: MediaUrlProperty,
        file: File | null,
        error?: string | undefined,
    ) {
        const _updatedFilesArray = _filesToUpload;
        _formik.setFieldError(property, error);
        if (file) {
            _formik.setFieldTouched(property, true, true);
            _formik.setFieldValue(property, TEMP_FILE_NAME);
        } else {
            return;
        }

        const _name = FileName[property as keyof typeof FileName];
        _updatedFilesArray.push({
            name: _name,
            file,
            property,
        });

        _setFilesToUpload(_updatedFilesArray);
    }

    function _onHasTranscriptChange(
        event: React.ChangeEvent<HTMLInputElement>,
    ) {
        _formik.setFieldValue('hasTranscript', event.target.checked);
    }

    function _onHasAudioChange(event: React.ChangeEvent<HTMLInputElement>) {
        _formik.setFieldValue('hasAudio', event.target.checked);
    }

    async function _onTranscriptBlocksChange(
        blocks: TRANSCRIPT_BLOCK[],
        shouldSaveActivity?: boolean,
    ) {
        if (shouldSaveActivity) {
            _setIsLoading(true);
            const _updatedSession = await updateActivity(
                _currentSessionId,
                {
                    status: _formik.values.status,
                },
                false,
                false,
            );
            await _formik.setValues(_updatedSession);
            _setIsLoading(false);
        }
        _setTranscriptBlocks(blocks);
    }

    /* Render */
    const _defaultInputProps: TextFieldProps = {
        variant: 'outlined',
        classes: {
            root: styles.formControl,
        },
    };

    const _titleTextFieldConfig = {
        error: _formik.errors.title !== undefined,
        helperText: _formik.errors.title,
        id: 'title',
        onChange: _formik.handleChange,
        value: _formik.values.title,
    };

    const _StatusChipNode = (
        <StatusChip
            clickable={true}
            onChange={_onStatusChange}
            releaseDate={_formik.values.releaseDate}
            lastSaveDate={_formik.values.lastSaveDate}
            lastSyncDate={_formik.values.lastSyncDate}
            status={_formik.values.status}
        />
    );

    if (_pageState === PAGE_STATE.BOOTING) return <Loader />;

    return (
        <Page
            isLoading={_pageState === PAGE_STATE.SAVING}
            loadingText={_loadingText}
            titleAdornment={_StatusChipNode}
            textFieldProps={_titleTextFieldConfig}
            rightButton={{
                loading: _isLoading,
                action: _formik.handleSubmit,
                cta: 'Save',
            }}>
            <div className={styles.mainContainer}>
                {/* Block 1: General info */}
                <div
                    className={cn(styles.block, {
                        [styles.blockDisabled]: !_switchedOnAreas.general,
                    })}>
                    <div className={styles.blockTitle}>
                        {'1. General info'}
                        {/* <Switch
							className={styles.blockSwitch}
							size="small"
							checked={_switchedOnAreas.general}
							onChange={() => _onSectionToggle('general')}
						/> */}
                    </div>
                    <div className={styles.blockDescription}>
                        <p>
                            {
                                'Basic properties and images shown in the app. These are all required.'
                            }
                        </p>
                    </div>
                    <Divider />
                    <div className={styles.blockContent}>
                        <div className={styles.blockInputs}>
                            <div className={styles.blockInputRow}>
                                {/* Blurb */}
                                <TextField
                                    {..._defaultInputProps}
                                    disabled={!_switchedOnAreas.general}
                                    classes={{
                                        root: cn(
                                            styles.formControl,
                                            styles.formControlXL,
                                        ),
                                    }}
                                    id="shortBlurb"
                                    label="Blurb*"
                                    value={_formik.values.shortBlurb}
                                    onChange={_formik.handleChange}
                                    multiline={true}
                                    error={
                                        _formik.errors.shortBlurb !== undefined
                                    }
                                    helperText={_formik.errors.shortBlurb}
                                    rows={9}
                                />
                                <div
                                    className={cn(
                                        styles.formControl,
                                        styles.formControlMedia,
                                    )}>
                                    <FileUpload
                                        id={_currentSessionId}
                                        name="thumbnailUrl"
                                        label="Thumbnail image*"
                                        type="image"
                                        onChange={_onFileInputChange}
                                        value={_formik.values.thumbnailUrl}
                                        error={
                                            _formik.errors.thumbnailUrl !==
                                            undefined
                                        }
                                        helperText={_formik.errors.thumbnailUrl}
                                    />
                                </div>
                            </div>
                            <div className={styles.blockInputRow}>
                                {/* Release date */}
                                <KeyboardDatePicker
                                    disabled={!_switchedOnAreas.general}
                                    margin="normal"
                                    id="releaseDate"
                                    name="releaseDate"
                                    label="Release date*"
                                    format="DD/MM/yyyy"
                                    value={_formik.values.releaseDate}
                                    onChange={_onDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    inputVariant="outlined"
                                    className={cn(
                                        styles.formControl,
                                        styles.formControlDate,
                                    )}
                                    error={
                                        _formik.errors.releaseDate !== undefined
                                    }
                                    helperText={_formik.errors.releaseDate}
                                    leftArrowButtonProps={{color: 'primary'}}
                                    rightArrowButtonProps={{color: 'primary'}}
                                />
                                {/* <TextField
                                    {..._defaultInputProps}
                                    disabled={!_switchedOnAreas.general}
                                    id="releaseDate"
                                    label="releaseDate (0-5)"
                                    name="releaseDate"
                                    // value={_formik.values.releaseDate}
                                    onChange={_formik.handleChange}
                                    error={
                                        _formik.errors.releaseDate !== undefined
                                    }
                                    helperText={_formik.errors.releaseDate}
                                /> */}

                                {/* Hotness */}
                                <TextField
                                    {..._defaultInputProps}
                                    disabled={!_switchedOnAreas.general}
                                    id="hotness"
                                    label="Hotness (0-5)"
                                    name="hotness"
                                    value={_formik.values.hotness}
                                    onChange={_formik.handleChange}
                                    error={_formik.errors.hotness !== undefined}
                                    helperText={_formik.errors.hotness}
                                    type="number"
                                    inputProps={{
                                        max: 5,
                                    }}
                                />

                                {/* License */}
                                <FormControlLabel
                                    className={styles.formControl}
                                    control={
                                        <Checkbox
                                            disabled={!_switchedOnAreas.general}
                                            checked={_formik.values.licensed}
                                            onChange={_formik.handleChange}
                                            name="licensed"
                                        />
                                    }
                                    label="This content is licensed"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Block 2: Media */}
                <div
                    className={cn(styles.block, {
                        [styles.blockDisabled]: !_switchedOnAreas.media,
                    })}>
                    <div className={styles.blockTitle}>
                        {'2. Audio'}
                        <Switch
                            className={styles.blockSwitch}
                            checked={_formik.values.hasAudio}
                            onChange={_onHasAudioChange}
                        />
                    </div>
                    <div className={styles.blockDescription}>
                        <p>
                            {
                                'Audio file and audio specific properties. Fields with a * are required.'
                            }
                        </p>
                    </div>
                    <Divider />
                    <div className={styles.blockContent}>
                        <div className={styles.blockInputs}>
                            {_formik.values.hasAudio ? (
                                <div className={styles.blockInputRow}>
                                    <div
                                        className={cn(
                                            styles.formControl,
                                            styles.formControlMedia,
                                        )}>
                                        <FileUpload
                                            id={_currentSessionId}
                                            name="downloadUrl"
                                            error={
                                                _formik.errors.downloadUrl !==
                                                undefined
                                            }
                                            helperText={
                                                _formik.errors.downloadUrl
                                            }
                                            label="Audio file*"
                                            type="audio"
                                            maxFileSize={MAX_AUDIO_SIZE}
                                            onChange={_onFileInputChange}
                                            value={_formik.values.downloadUrl}
                                        />
                                    </div>
                                    <div className={styles.blockInputRowGroup}>
                                        {/* Duration */}
                                        <TextField
                                            {..._defaultInputProps}
                                            disabled={!_switchedOnAreas.general}
                                            id="durationInSeconds"
                                            label="Duration*"
                                            name="durationInSeconds"
                                            value={
                                                _formik.values.durationInSeconds
                                            }
                                            onChange={_formik.handleChange}
                                            error={
                                                _formik.errors
                                                    .durationInSeconds !==
                                                undefined
                                            }
                                            helperText={
                                                _formik.errors.durationInSeconds
                                            }
                                            inputProps={{
                                                min: 0,
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        seconds
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                        />
                                        {/* Public listen */}
                                        <FormControlLabel
                                            className={styles.formControl}
                                            control={
                                                <Checkbox
                                                    disabled={
                                                        !_switchedOnAreas.general
                                                    }
                                                    checked={
                                                        _formik.values.public
                                                    }
                                                    onChange={
                                                        _formik.handleChange
                                                    }
                                                    name="public"
                                                />
                                            }
                                            label="Suitable to listen in public"
                                        />
                                    </div>
                                    <div className={styles.blockInputRowGroup}>
                                        {/* Voice */}
                                        <TextField
                                            {..._defaultInputProps}
                                            disabled={!_switchedOnAreas.general}
                                            id="voice"
                                            label="Voice*"
                                            name="voice"
                                            value={_formik.values.voice}
                                            onChange={_formik.handleChange}
                                            error={
                                                _formik.errors.voice !==
                                                undefined
                                            }
                                            helperText={_formik.errors.voice}
                                            select={true}
                                            SelectProps={{
                                                classes: {
                                                    icon: styles.inputIcon,
                                                },
                                            }}>
                                            {voiceOptions.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {/* Voice Actor */}
                                        <TextField
                                            {..._defaultInputProps}
                                            disabled={!_switchedOnAreas.general}
                                            id="voiceActor"
                                            label="Voice actor"
                                            name="voiceActor"
                                            value={_formik.values.voiceActor}
                                            onChange={_formik.handleChange}
                                            error={
                                                _formik.errors.voiceActor !==
                                                undefined
                                            }
                                            helperText={
                                                _formik.errors.voiceActor
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.emptyRow}>
                                    Audio is disabled for this activity
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Block 3: Transcript */}
                <div
                    className={cn(styles.block, {
                        [styles.blockDisabled]: !_switchedOnAreas.general,
                    })}>
                    <div className={styles.blockTitle}>
                        {'3. Transcript'}
                        {/* <Switch
							size="small"
							checked={_switchedOnAreas.general}
							onChange={() => _onSectionToggle('general')}
						/> */}
                        <Switch
                            className={styles.blockSwitch}
                            checked={_formik.values.hasTranscript}
                            onChange={_onHasTranscriptChange}
                        />
                    </div>
                    <div className={styles.blockDescription}>
                        <p>
                            {
                                'Transcript blocks and read-specific properties. Fields with a * are required.'
                            }
                        </p>
                    </div>
                    <Divider />
                    <div className={styles.blockContent}>
                        <div className={styles.blockInputs}>
                            {_formik.values.hasTranscript ? (
                                <>
                                    <div className={styles.blockInputRow}>
                                        {/* Duration */}
                                        <TextField
                                            {..._defaultInputProps}
                                            id="transcriptDurationInSeconds"
                                            label="Time to read *"
                                            name="transcriptDurationInSeconds"
                                            value={
                                                _formik.values
                                                    .transcriptDurationInSeconds
                                            }
                                            onChange={_formik.handleChange}
                                            error={
                                                _formik.errors
                                                    .transcriptDurationInSeconds !==
                                                undefined
                                            }
                                            helperText={
                                                _formik.errors
                                                    .transcriptDurationInSeconds
                                            }
                                            inputProps={{
                                                min: 0,
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        seconds
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <TranscriptPreview
                                            activityID={_currentSessionId}
                                            blocks={
                                                _formik.values.transcriptBlocks
                                            }
                                            onChange={_onTranscriptBlocksChange}
                                            error={
                                                _formik.errors.transcriptBlocks
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className={styles.emptyRow}>
                                    Transcripts are disabled for this activity
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Block 4: Availability */}
                <div
                    className={cn(styles.block, {
                        [styles.blockDisabled]: !_switchedOnAreas.availability,
                    })}>
                    <div className={styles.blockTitle}>
                        {'4. Availability'}
                        {/* <Switch
							className={styles.blockSwitch}
							size="small"
							checked={_switchedOnAreas.availability}
							onChange={() => _onSectionToggle('availability')}
						/> */}
                    </div>
                    <div className={styles.blockDescription}>
                        <p>
                            {
                                'Where is this piece shown in the app? And is it available for everybody? Fields with a * are required.'
                            }
                        </p>
                    </div>
                    <Divider />
                    <div className={styles.blockContent}>
                        <div className={styles.blockInputs}>
                            <div className={styles.blockInputRow}>
                                {/* Premium */}
                                <FormControlLabel
                                    className={styles.formControl}
                                    control={
                                        <Checkbox
                                            disabled={
                                                !_switchedOnAreas.availability
                                            }
                                            checked={_formik.values.premium}
                                            onChange={_formik.handleChange}
                                            name="premium"
                                        />
                                    }
                                    label="Available for paid users only"
                                />

                                {/* Hidden */}
                                <FormControlLabel
                                    classes={{
                                        root: cn(
                                            styles.formControl,
                                            styles.formControlXL,
                                        ),
                                    }}
                                    control={
                                        <Checkbox
                                            disabled={
                                                !_switchedOnAreas.availability
                                            }
                                            checked={!!_formik.values.hidden}
                                            onChange={_formik.handleChange}
                                            name="hidden"
                                        />
                                    }
                                    label="Hide from carousels?"
                                />
                            </div>
                            <div className={styles.blockInputRow}>
                                {/* Content type */}
                                <TextField
                                    {..._defaultInputProps}
                                    disabled={!_switchedOnAreas.availability}
                                    id="contentType"
                                    label="Content type*"
                                    name="contentType"
                                    value={_formik.values.contentType}
                                    onChange={_formik.handleChange}
                                    error={
                                        _formik.errors.contentType !== undefined
                                    }
                                    helperText={_formik.errors.contentType}
                                    select={true}
                                    SelectProps={{
                                        classes: {
                                            icon: styles.inputIcon,
                                        },
                                    }}>
                                    {contentTypeOptions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {/* Tags */}
                                <TextField
                                    {..._defaultInputProps}
                                    classes={{
                                        root: cn(
                                            styles.formControl,
                                            styles.formControlLG,
                                        ),
                                    }}
                                    disabled={!_switchedOnAreas.availability}
                                    id="contentTags"
                                    label="Tags"
                                    name="contentTags"
                                    value={_formik.values.contentTags}
                                    onChange={_formik.handleChange}
                                    error={
                                        _formik.errors.contentTags !== undefined
                                    }
                                    helperText={_formik.errors.contentTags}
                                    select
                                    rows={4}
                                    SelectProps={{
                                        classes: {
                                            root: styles.formControlHigh,
                                            icon: styles.inputIcon,
                                        },
                                        multiple: true,
                                        renderValue: function renderTags(
                                            selected,
                                        ) {
                                            return (
                                                <div
                                                    className={styles.tagChips}>
                                                    {(selected as string[]).map(
                                                        (value) => {
                                                            const _matchingTag = _tags.find(
                                                                (tag) =>
                                                                    tag.id ===
                                                                    value,
                                                            );
                                                            if (!_matchingTag)
                                                                return null;
                                                            return (
                                                                <Chip
                                                                    key={value}
                                                                    label={
                                                                        _matchingTag.label
                                                                    }
                                                                    className={
                                                                        styles.tagChip
                                                                    }
                                                                    size="small"
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            );
                                        },
                                    }}>
                                    {_tags.map((option) => (
                                        <MenuItem
                                            key={'tag' + option.id}
                                            value={option.id}
                                            className={styles.menuItem}>
                                            <Checkbox
                                                checked={
                                                    _formik.values.contentTags?.indexOf(
                                                        option.id,
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText>
                                                {option.label}
                                            </ListItemText>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {_formik.values.status !== ACTIVITY_STATUS.DRAFT && (
                <SyncBar
                    onSyncClick={_handleSync}
                    type="content"
                    lastSaveDate={_formik.values.lastSaveDate}
                    lastSyncDate={_formik.values.lastSyncDate}
                />
            )}
        </Page>
    );
};

export default Session;
