import React from 'react';

import Chip from '@material-ui/core/Chip';

import {CB_TAG} from 'types/app-screen.types';
import {TAG} from 'types/tags.types';

import styles from './tags-picker.module.css';

interface Props {
    all: TAG[];
    name: string;
    value: CB_TAG[] | null;
    onChange: (tags: CB_TAG[], name: string) => void;
}

const TagsPicker = (props: Props): JSX.Element => {
    /* Hooks n State */

    /* Handlers */
    function _onDelete(id: string) {
        if (props.value) {
            const _indexToDelete = props.value.findIndex(
                (tag) => tag.id === id,
            );
            if (_indexToDelete > -1) {
                const _newValue = [...props.value];
                _newValue.splice(_indexToDelete, 1);

                props.onChange(_newValue, props.name);
            }
        }
    }

    function _onSelect(id: string) {
        if (props.value) {
            const _tagToAdd: CB_TAG = {
                id,
            };

            props.onChange([...props.value, _tagToAdd], props.name);
        }
    }

    /* Render */
    return (
        <div className={styles.tagsList}>
            {props.all.map((tag) => {
                const _matchingTags = props.value?.filter(
                    (selectedTag) => selectedTag.id === tag.id,
                );
                const _isSelected = _matchingTags && _matchingTags.length > 0;

                return (
                    <Chip
                        className={styles.chip}
                        color={_isSelected ? 'primary' : 'secondary'}
                        key={tag.id}
                        label={tag.label}
                        variant={_isSelected ? 'default' : 'outlined'}
                        size="small"
                        onClick={
                            _isSelected ? undefined : () => _onSelect(tag.id)
                        }
                        onDelete={
                            _isSelected ? () => _onDelete(tag.id) : undefined
                        }
                    />
                );
            })}
        </div>
    );
};

export default TagsPicker;
