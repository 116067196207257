import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'theme';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import MainRouter from 'routes/main-router';

import ActivitiesProvider from 'providers/activities/activities.provider';
import ProgramsProvider from 'providers/programs/programs.provider';
import ModalProvider from 'providers/modal/modal.provider';
import SnackbarProvider from 'providers/snackbar/snackbar.provider';
import TagsProvider from 'providers/tags/tags.provider';
import ExpertsProvider from 'providers/experts/experts.provider';
import UserProvider from 'providers/user.provider';
import KajabiCourseProvider from 'providers/kajabi-course/kajabi-course.provider';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

const App: React.FC = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={theme}>
                <ProgramsProvider>
                    <ActivitiesProvider>
                        <TagsProvider>
                            <ExpertsProvider>
                                <KajabiCourseProvider>
                                    <UserProvider>
                                        <ModalProvider>
                                            <SnackbarProvider>
                                                <MuiPickersUtilsProvider
                                                    utils={MomentUtils}>
                                                    <MainRouter />
                                                </MuiPickersUtilsProvider>
                                            </SnackbarProvider>
                                        </ModalProvider>
                                    </UserProvider>
                                </KajabiCourseProvider>
                            </ExpertsProvider>
                        </TagsProvider>
                    </ActivitiesProvider>
                </ProgramsProvider>
            </ThemeProvider>
        </DndProvider>
    );
};

export default App;
