import React, {useState} from 'react';
import cn from 'classnames';
import moment from 'moment';

import {KeyboardDatePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import Box from '@material-ui/core/Box';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {translateBlockType} from 'helpers/app-screen.helper';
import useModal from 'hooks/use-modal';
import {CONTENT_BLOCK, SortDirection, CB_STATUS} from 'types/app-screen.types';

import styles from './screen-block.module.css';

interface ScreenBlockProps {
    block: CONTENT_BLOCK;
    children: React.ReactNode;
    blocksCount: number;
    errors?: any;
    onChange: (blockId: string, key: string, value: any) => void;
    onSort: (sort: number, direction: SortDirection) => void;
}

const ScreenBlock = (props: ScreenBlockProps): JSX.Element => {
    /* Hooks n State */
    const [_activeMenu, _setActiveMenu] = useState<HTMLElement | null>(null);
    const _modal = useModal();

    /* Handlers */
    function _onStartDateChange(date: MaterialUiPickersDate) {
        if (props.block.id) {
            const _newDate = date ? date.toDate() : null;
            props.onChange(props.block.id, 'startDate', _newDate);
        }
    }

    function _onEndDateChange(date: MaterialUiPickersDate) {
        if (props.block.id) {
            const _newDate = date ? date.toDate() : null;
            props.onChange(props.block.id, 'endDate', _newDate);
        }
    }

    function _onArrowDownClick() {
        props.onSort(props.block.sort, 'down');
    }

    function _onArrowUpClick() {
        props.onSort(props.block.sort, 'up');
    }

    function _onMenuClose() {
        _setActiveMenu(null);
    }

    function _onMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        _setActiveMenu(event.currentTarget);
    }

    async function _onUnpublishClick() {
        _setActiveMenu(null);
        const _modalResult = await _modal.confirm({
            title: 'Are you sure?',
            message:
                'After unpublishing this block, you can no longer edit it.',
            confirmCta: 'Unpublish',
        });
        if (!_modalResult.cancelled) {
            props.onChange(props.block.id, 'status', CB_STATUS.UNPUBLISHED);
        }
    }

    /* Render */
    const _typeLabel = translateBlockType(props.block.type);

    return (
        <div className={styles.wrapper} id={props.block.id}>
            <Box boxShadow={3} className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.headerLeft}>
                        <div className={styles.typeText}>{_typeLabel}</div>
                        <div className={styles.idText}>#{props.block.id}</div>
                    </div>
                    <div className={styles.headerRight}>
                        <IconButton color="secondary" onClick={_onMenuClick}>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            id={'menu-' + props.block.id}
                            anchorEl={_activeMenu}
                            open={Boolean(_activeMenu)}
                            onClose={_onMenuClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <MenuItem onClick={_onUnpublishClick}>
                                {'Unpublish'}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className={styles.body}>{props.children}</div>
                <div className={styles.footer}>
                    <div className={styles.footerText}>Live from</div>
                    {/* Start date */}
                    <KeyboardDatePicker
                        margin="normal"
                        id={'startDate-' + props.block.id}
                        name="startDate"
                        label="Start date*"
                        format="DD/MM/yyyy"
                        value={props.block.startDate}
                        onChange={_onStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        className={cn(
                            styles.formControl,
                            styles.formControlDate,
                        )}
                        error={props.errors.startDate !== undefined}
                        helperText={props.errors.startDate}
                        leftArrowButtonProps={{color: 'primary'}}
                        rightArrowButtonProps={{color: 'primary'}}
                    />
                    <div className={styles.footerText}>until</div>
                    {/* End date */}
                    <KeyboardDatePicker
                        margin="normal"
                        id={'endDate-' + props.block.id}
                        name="endDate"
                        label="End date"
                        format="DD/MM/yyyy"
                        value={props.block.endDate}
                        onChange={_onEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        clearable
                        inputVariant="outlined"
                        className={cn(
                            styles.formControl,
                            styles.formControlDate,
                        )}
                        error={props.errors.endDate !== undefined}
                        helperText={props.errors.endDate}
                        shouldDisableDate={(date) => {
                            return (
                                props.block.startDate !== null &&
                                date !== null &&
                                date.isBefore(moment(props.block.startDate))
                            );
                        }}
                        leftArrowButtonProps={{color: 'primary'}}
                        rightArrowButtonProps={{color: 'primary'}}
                    />
                </div>
            </Box>
            <div className={styles.arrows}>
                <div className={styles.arrowUp}>
                    <IconButton
                        color="secondary"
                        disabled={props.block.sort === 0}
                        onClick={_onArrowUpClick}>
                        <ArrowUpwardIcon />
                    </IconButton>
                </div>
                <div className={styles.arrowDown}>
                    <IconButton
                        color="secondary"
                        disabled={props.block.sort === props.blocksCount - 1}
                        onClick={_onArrowDownClick}>
                        <ArrowDownwardIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default ScreenBlock;
