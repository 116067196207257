import React, {useState} from 'react';
import {TextField} from '@material-ui/core';
import {useAsyncDebounce, Row} from 'react-table';

// to do - consistent capitalisation
import styles from './global-filter.module.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
    preGlobalFilteredRows: Row<any>[];
    globalFilter: string;
    setGlobalFilter(value: string | undefined): void;
}

const GlobalFilter: React.FC<Props> = (props: Props) => {
    const {globalFilter, setGlobalFilter} = props;
    // const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const handleChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <TextField
            className={styles.searchBar}
            variant="outlined"
            value={value || ''}
            size="small"
            onChange={(e) => {
                setValue(e.target.value);
                handleChange(e.target.value);
            }}
            placeholder={`Search sessions by name`}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon classes={{root: styles.searchIcon}} />
                    </InputAdornment>
                ),
            }}
        />
    );
};
export default GlobalFilter;
