import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';
import ButtonBase from '@material-ui/core/ButtonBase';

import ScreenBlock from 'components/screen-block/screen-block.view';
import {CONTENT_BLOCK, SortDirection, CB_PROGRAM} from 'types/app-screen.types';

import useModal from 'hooks/use-modal';
import usePrograms from 'hooks/use-programs.hooks';
import ProgramsPickerItem from 'components/program-picker-content/programs-picker-item.view';
import ProgramPickerContent from 'components/program-picker-content/program-picker-content.view';
import {ArrowDirection} from '../../experts-picker-content/experts-picker-item.view';

import blockStyles from 'components/screen-block/screen-block.module.css';
import styles from './programs-carousel.module.css';
import {PROGRAM, PROGRAM_SOURCE} from 'types/programs.types';

interface ProgramsCarouselProps {
    block: CONTENT_BLOCK;
    blocksCount: number;
    errors: any;
    onChange: (blockId: string, key: string, value: any) => void;
    onSort: (sort: number, direction: SortDirection) => void;
}

const ProgramsCarousel = (props: ProgramsCarouselProps): JSX.Element => {
    const _modal = useModal();

    /* Hooks n State */
    // const _experts = useExperts().all;
    const _programs = usePrograms().allByKey;
    const [_programsToDisplay, _setProgramsToDisplay] = useState<PROGRAM[]>([]);

    useEffect(() => {
        const _matchingPrograms: PROGRAM[] = [];
        if (props.block.programmes) {
            props.block.programmes.forEach((selectedProgram: CB_PROGRAM) => {
                const _matchingProgram = _programs.find(
                    (program) =>
                        selectedProgram.key === program.key &&
                        program.latest_version,
                );

                if (_matchingProgram) {
                    _matchingPrograms.push(_matchingProgram);
                }
            });

            _setProgramsToDisplay(_matchingPrograms);
        }
    }, [props.block.programmes, _programs]);

    /* Handlers */
    function _onInputChange({target}: React.ChangeEvent<HTMLInputElement>) {
        if (props.block.id) {
            props.onChange(props.block.id, target.name, target.value);
        }
    }

    function _onDeleteClick(index: number) {
        let _newValue = [...props.block.programmes];
        _newValue.splice(index, 1);
        _newValue = _newValue.map((item, index) => {
            return {
                ...item,
                sort: index,
            };
        });

        props.onChange(props.block.id, 'programmes', _newValue);
    }

    function _onArrowClick(index: number, direction: ArrowDirection) {
        const _newValue = [...props.block.programmes];

        const _indexToUpdate = direction === 'left' ? index - 1 : index + 1;
        const _movedBlock = _newValue[_indexToUpdate];
        _newValue[_indexToUpdate] = _newValue[index];
        _newValue[_indexToUpdate].sort = _indexToUpdate;
        _newValue[index] = _movedBlock;
        _newValue[index].sort = index;

        props.onChange(props.block.id, 'programmes', _newValue);
    }

    async function _onClick() {
        _modal.setPromiseData(props.block.programmes);

        const _result = await _modal.component({
            title: 'Select programs',
            message: 'Which programs should be displayed in this carousel?',
            component: (
                <ProgramPickerContent
                    // selected={_selectedExperts}
                    onChange={_onProgramPickerChange}
                    selectOnly
                    latestOnly
                    multiselect
                    value={props.block.programmes}
                />
            ),
        });

        if (_result.success) {
            props.onChange(props.block.id, 'programmes', _result.value);
        }
    }

    function _onProgramPickerChange(value: PROGRAM_SOURCE[]) {
        _modal.setPromiseData(value);
    }

    /* Render */
    const _defaultInputProps: TextFieldProps = {
        variant: 'outlined',
        classes: {
            root: blockStyles.formInput,
        },
    };

    return (
        <ScreenBlock
            block={props.block}
            blocksCount={props.blocksCount}
            onChange={props.onChange}
            onSort={props.onSort}
            errors={props.errors}>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>General info</div>
                <div className={blockStyles.inputRow}>
                    {/* Title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'title' + props.block.id}
                            label="Title*"
                            name="title"
                            value={props.block.title}
                            onChange={_onInputChange}
                            error={props.errors.title !== undefined}
                            helperText={props.errors.title}
                        />
                    </div>

                    {/* Internal title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'internal-title' + props.block.id}
                            label="Internal title"
                            name="internalTitle"
                            value={props.block.internalTitle}
                            onChange={_onInputChange}
                            error={props.errors.internalTitle !== undefined}
                            helperText={props.errors.internalTitle}
                        />
                    </div>
                </div>
                <div className={blockStyles.inputRow}>
                    {/* Description */}
                    <div
                        className={cn(
                            blockStyles.formControl,
                            blockStyles.formControlXL,
                        )}>
                        <TextField
                            {..._defaultInputProps}
                            id={'text' + props.block.id}
                            label="Text*"
                            name="text"
                            value={props.block.text}
                            onChange={_onInputChange}
                            error={props.errors.text !== undefined}
                            helperText={props.errors.text}
                            multiline={true}
                            rows={4}
                        />
                    </div>
                </div>
            </div>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>
                    Content*
                    {props.block.programmes &&
                        props.block.programmes.length > 0 && (
                            <span>({props.block.programmes?.length})</span>
                        )}
                </div>

                <div className={styles.grid}>
                    <div className={styles.gridItem} key={-1}>
                        <ButtonBase
                            className={cn(styles.addButton, styles.session)}
                            onClick={_onClick}>
                            <AddIcon color="primary" />
                        </ButtonBase>
                    </div>
                    {_programsToDisplay.map((program, index) => {
                        return (
                            <div className={styles.gridItem} key={program.key}>
                                <ProgramsPickerItem
                                    program={program}
                                    index={index}
                                    totalCount={_programsToDisplay.length}
                                    onArrowClick={_onArrowClick}
                                    onDelete={_onDeleteClick}
                                />
                                {/* <div>{'Sort: ' + program.sort}</div> */}
                            </div>
                        );
                    })}
                </div>
            </div>
        </ScreenBlock>
    );
};

export default ProgramsCarousel;
