import React, {useEffect, useState} from 'react';

import Page from 'components/page/page';

import {getAppScreen} from 'api/app-screens.api';
import {createTag, syncTagsWithProduction} from 'api/tags.api';
import ContentTagInfo from 'components/content-tag-info/content-tag-info.view';
import SyncBar from 'components/sync-bar/sync-bar';
import {SCREENS} from 'config/screens.config';
import {lowerCase, titleCase} from 'helpers/strings.helper';
import useActivities from 'hooks/use-activities.hooks';
import useTags from 'hooks/use-tags.hooks';
import useModal from 'hooks/use-modal';
import useSnackbar from 'hooks/use-snackbar.hooks';
import {CONTENT_BLOCK, SCREEN_WITH_BLOCKS} from 'types/app-screen.types';
import {TAG_WITH_ACTIVITIES} from 'types/tags.types';
import moment from 'moment';

import styles from './tags.module.css';

enum PAGE_STATE {
    ERROR,
    BOOTING,
    EDITING,
    SAVING,
}

interface SyncState {
    synced: boolean;
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
}
const TagsScreen = (): JSX.Element => {
    /* Hooks n State */
    const [_screens, _setScreens] = useState<SCREEN_WITH_BLOCKS[]>([]);
    const [_tagsWithActivities, _setTagsWithActivities] = useState<
        TAG_WITH_ACTIVITIES[]
    >([]);

    const [_pageState, _setPageState] = useState<PAGE_STATE>(
        PAGE_STATE.BOOTING,
    );
    const [_syncState, _setSyncState] = useState<SyncState>({
        synced: false,
        lastSaveDate: null,
        lastSyncDate: null,
    });

    const _tags = useTags().all;
    const _activities = useActivities().all;
    const _modal = useModal();
    const _snackbar = useSnackbar();

    useEffect(() => {
        async function _loadScreens() {
            const _promises = Object.keys(SCREENS).map((key) => {
                return getAppScreen(SCREENS[key as keyof typeof SCREENS]);
            });

            const _fetchedScreens = await Promise.all(_promises);
            _setScreens(_fetchedScreens);
        }

        _loadScreens();
    }, []);

    useEffect(() => {
        if (_tags.length && _screens.length && _activities.length) {
            const _newSyncState: SyncState = {
                synced: true,
                lastSaveDate: null,
                lastSyncDate: null,
            };
            // Add activities to tags
            const _newTagsWithActivities = _tags.map((tag) => {
                //Compare sync/start dates
                if (!tag.lastSyncDate) {
                    _newSyncState.synced = false;
                } else if (
                    !_newSyncState.lastSyncDate ||
                    moment(tag.lastSyncDate).isBefore(
                        _newSyncState.lastSyncDate,
                    )
                ) {
                    _newSyncState.synced = false;
                    _newSyncState.lastSyncDate = tag.lastSyncDate;
                }

                if (!tag.lastSaveDate) {
                    _newSyncState.synced = false;
                } else if (
                    !_newSyncState.lastSaveDate ||
                    moment(_newSyncState.lastSaveDate).isBefore(
                        tag.lastSaveDate,
                    )
                ) {
                    _newSyncState.synced = false;
                    _newSyncState.lastSaveDate = tag.lastSaveDate;
                }

                // Find matching activities for each tag
                const _activitiesWithTag = _activities.filter((activity) => {
                    return activity.contentTags.includes(tag.id);
                });

                // Find matching content blocks for each tag
                // const _blocksWithTag: CONTENT_BLOCK[] = _screens.map(
                //     (screen) => {
                //         return screen.blocks.filter((block) => {
                //             return block.tags?.find(
                //                 (blockTag) => tag.id === blockTag.id,
                //             );
                //         });
                //     },
                // );
                const _blocksWithTag: CONTENT_BLOCK[] = [];
                _screens.forEach((screen) => {
                    screen.blocks.forEach((screenBlock) => {
                        if (
                            screenBlock.tags &&
                            screenBlock.tags.findIndex(
                                (blockTag) => blockTag.id === tag.id,
                            ) > -1
                        ) {
                            _blocksWithTag.push({
                                ...screenBlock,
                                screenName: screen.screen.screen,
                            });
                        }
                    });
                });

                return {
                    tag: tag,
                    activities: _activitiesWithTag,
                    blocks: _blocksWithTag,
                };
            });

            _setSyncState(_newSyncState);
            _setTagsWithActivities(_newTagsWithActivities);

            // Hide loader on init
            if (!_tagsWithActivities.length) {
                _setPageState(PAGE_STATE.EDITING);
            }
        }
    }, [_activities, _tags, _screens]);

    /* Handlers */
    async function _onFabPress() {
        const _tagLabel = await _modal.prompt({
            title: 'Create new tag',
            message:
                "How would you like this tag to be displayed on the app? Please make sure this tag doesn't exist before creating it.",
            confirmCta: 'Create tag',
            textInputConfig: {
                placeholder: 'Tag name',
            },
        });

        if (_tagLabel.value) {
            _setPageState(PAGE_STATE.SAVING);

            // Reformat label to be capitalized
            const _formattedLabel = titleCase(_tagLabel.value);

            // Make sure it doesn't exist yet.
            const _alreadyExists = _tags.some(
                (tag) =>
                    lowerCase(tag.label) === lowerCase(_tagLabel.value || ''),
            );

            if (_alreadyExists) {
                _setPageState(PAGE_STATE.EDITING);
                _snackbar.error('Tag already exists!');
            } else {
                try {
                    await createTag(_formattedLabel);
                    _setPageState(PAGE_STATE.EDITING);
                    _snackbar.success(
                        'Successfully created tag "' + _formattedLabel + '"!',
                    );
                } catch (error) {
                    _setPageState(PAGE_STATE.EDITING);
                    _snackbar.error(error.message);
                }
            }
        }
    }

    async function _syncTagsWithProduction() {
        _setPageState(PAGE_STATE.SAVING);
        try {
            await syncTagsWithProduction();
            _setPageState(PAGE_STATE.EDITING);
        } catch (error) {
            _setPageState(PAGE_STATE.EDITING);
            _snackbar.error(error.message);
        }
    }

    /* Render */
    return (
        <Page
            title="Tags"
            onFabPress={_onFabPress}
            isLoading={
                _pageState === PAGE_STATE.SAVING ||
                _pageState === PAGE_STATE.BOOTING
            }>
            <div className={styles.container}>
                {_tagsWithActivities.map((tag) => {
                    return <ContentTagInfo key={tag.tag.id} data={tag} />;
                })}
                {!_syncState.synced && (
                    <SyncBar
                        type="tags"
                        lastSaveDate={_syncState.lastSaveDate}
                        lastSyncDate={_syncState.lastSyncDate}
                        onSyncClick={_syncTagsWithProduction}
                    />
                )}
            </div>
        </Page>
    );
};

export default TagsScreen;
