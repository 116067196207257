import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles';

const _font = "'Lato', sans-serif";
const theme = createMuiTheme({
    typography: {
        fontFamily: _font,
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            light: '#D9DCFF', //light pink
            main: '#6369bd', // purple
            dark: '#3A3E7C', //dark green
            contrastText: '#ffffff', // whitee
        },
        secondary: {
            light: '#FF8276', //light blue
            main: '#363D2B', // dark green
            dark: '#20241A', // super dark green
            contrastText: '#ffffff', // white
        },
        action: {
            active: '#fff',
        },
    },
});
export default theme;
