import React from 'react';
import {TextField} from '@material-ui/core';
interface Props {
    column: {
        filterValue: string;
        setFilter(filter: string | undefined): void;
        preFilteredRows: {length: number};
    };
}

const DefaultColumnFilter: React.FC<Props> = (props: Props) => {
    const {
        column: {
            filterValue,
            setFilter,
            preFilteredRows: {length},
        },
    } = props;

    return (
        <TextField
            size="small"
            type="input"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`search (${length}) ...`}
        />
    );
};
export default DefaultColumnFilter;
