import {ACTIVITY_STATUS} from 'types/activity.types';
import {
    EMPTY_PROGRAM,
    FIRESTORE_PROGRAM,
    MODULE,
    PROGRAM,
} from 'types/programs.types';

export const firestoreObjectToProgram = (
    program: FIRESTORE_PROGRAM,
    id: string,
): PROGRAM => {
    const _data: PROGRAM = {
        ...program,
        id,
        createdAt: program.createdAt ? program.createdAt.toDate() : null,
        lastSaveDate: program.lastSaveDate
            ? program.lastSaveDate.toDate()
            : null,
        lastSyncDate: program.lastSyncDate
            ? program.lastSyncDate.toDate()
            : null,
        modules: program.modules.map((module) => ({
            ...module,
            releaseDate: module.releaseDate.toDate(),
        })),
        releaseDate: program.releaseDate ? program.releaseDate.toDate() : null,
    };

    //Sort blocks
    if (_data.blocks) {
        _data.blocks = _data.blocks.sort((a, b) => a.sort - b.sort);
    }

    return _data;
};

export const generateNewProgram = (sourceProgram?: PROGRAM): PROGRAM => {
    const _newProgram: PROGRAM = sourceProgram || {
        ...(EMPTY_PROGRAM as PROGRAM),
    };

    const _creationDate = new Date();
    _newProgram.createdAt = _creationDate;
    _newProgram.lastSaveDate = _creationDate;
    _newProgram.lastSyncDate = null;
    _newProgram.version += 1;
    _newProgram.status = ACTIVITY_STATUS.DRAFT;

    if (sourceProgram) {
        _newProgram.latest_version = false;
    }

    return _newProgram;
};

export const jsonToProgram = (program: any): PROGRAM => {
    return {
        ...program,
        createdAt: new Date(program.createdAt),
        lastSaveDate: program.lastSaveDate
            ? new Date(program.lastSaveDate)
            : null,
        lastSyncDate: program.lastSyncDate
            ? new Date(program.lastSyncDate)
            : null,
        releaseDate: program.releaseDate ? new Date(program.releaseDate) : null,
        modules: program.modules.map((module: MODULE) => ({
            ...module,
            releaseDate: new Date(module.releaseDate),
        })),
    };
};
