import IMG_FIRST_LEFT from 'assets/images/transcript/img-block/first-left.png';
import IMG_FIRST_RIGHT from 'assets/images/transcript/img-block/first-right.png';
import IMG_SECOND_LEFT from 'assets/images/transcript/img-block/second-left.png';
import IMG_SECOND_RIGHT from 'assets/images/transcript/img-block/second-right.png';
import IMG_THIRD_LEFT from 'assets/images/transcript/img-block/third-left.png';
import IMG_THIRD_RIGHT from 'assets/images/transcript/img-block/third-right.png';

export interface TranscriptImageMap {
    left: string;
    right: string;
}

export const IMAGE_MAP: TranscriptImageMap[] = [
    {
        left: IMG_FIRST_LEFT,
        right: IMG_FIRST_RIGHT,
    },
    {
        left: IMG_SECOND_LEFT,
        right: IMG_SECOND_RIGHT,
    },
    {
        left: IMG_THIRD_LEFT,
        right: IMG_THIRD_RIGHT,
    },
];
