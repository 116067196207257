import React, {createContext, useEffect, useRef, useState} from 'react';

import {
    KAJABI_USER,
    KAJABI_USER_CONVERSATIONS,
} from 'types/kajabi-course.types';
import {
    getAllKajabiUsers,
    getUserKajabiConversationAsCSV,
} from 'api/kajabi-course.api';

export interface CourseChatsAPI {
    all: KAJABI_USER[];
    conversationCounts: KAJABI_USER_CONVERSATIONS;
    get: () => Promise<void>;
    getConversationAsCSV: (userId: string) => Promise<string>;
}

const _initialState: CourseChatsAPI = {
    all: [],
    conversationCounts: {},
    get: async () => {},
    getConversationAsCSV: async () => '',
};

interface Props {
    children: React.ReactNode;
}

export const CourseChatsContext = createContext<CourseChatsAPI>(_initialState);

const KajabiCourseProvider = (props: Props): JSX.Element => {
    /* Hooks n State */
    const [_kajabiUsers, _setKajabiUsers] = useState<KAJABI_USER[]>([]);
    const [
        _kajabiUserMessageCount,
        _setKajabiUserMessageCount,
    ] = useState<KAJABI_USER_CONVERSATIONS>({});

    const _subscriptionRef = useRef<(() => void) | null>(null);

    useEffect(() => {
        return () => {
            if (_subscriptionRef.current) {
                _subscriptionRef.current();
            }
        };
    }, []);

    /* Handlers */

    async function _getKajabiUsers() {
        const _users = await getAllKajabiUsers();
        _setKajabiUsers(_users);
    }

    /* Render */
    const _value: CourseChatsAPI = {
        all: _kajabiUsers,
        conversationCounts: _kajabiUserMessageCount,
        get: _getKajabiUsers,
        getConversationAsCSV: getUserKajabiConversationAsCSV,
    };

    return (
        <CourseChatsContext.Provider value={_value}>
            {props.children}
        </CourseChatsContext.Provider>
    );
};

export default KajabiCourseProvider;
