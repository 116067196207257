import React, {useEffect, useRef, useState} from 'react';
import MDEditor, {commands} from '@uiw/react-md-editor';
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';

import Dialog from '@material-ui/core/Dialog';
import {TransitionProps} from '@material-ui/core/transitions';
import {Button, Fade, IconButton, TextField} from '@material-ui/core';

import styles from './transcript-editor-dialog.module.css';
import {Close} from '@material-ui/icons';
import {
    TRANSCRIPT_BLOCK,
    TRANSCRIPT_BLOCK_TEXT,
    TRANSCRIPT_BLOCK_IMAGE,
    TRANSCRIPT_BLOCK_TYPES,
    TRANSCRIPT_BLOCK_CONTENT,
    TRANSCRIPT_BLOCK_IMAGE_OPTION,
} from '../../types/activity-transcript.types';
import TranscriptImagePicker from './transcript-image-picker/transcript-image-picker.view';
import {clearTextFromNewLines} from '../../helpers/mardown.helper';

interface TranscriptEditorDialogProps {
    block?: TRANSCRIPT_BLOCK;
    onClose: () => void;
    onSave: (value?: TRANSCRIPT_BLOCK_CONTENT) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {children?: React.ReactElement},
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

function TranscriptEditorDialog(
    props: TranscriptEditorDialogProps,
): JSX.Element {
    /* Hooks n State */
    const [_editorState, _setEditorState] = useState<
        string | number | undefined
    >(undefined);

    useEffect(() => {
        let _newValue = null;
        if (props.block?.content) {
            switch (props.block.type) {
                case TRANSCRIPT_BLOCK_TYPES.TEXT:
                case TRANSCRIPT_BLOCK_TYPES.TITLE:
                case TRANSCRIPT_BLOCK_TYPES.CALLOUT:
                    const _typedTextContent = props.block
                        .content as TRANSCRIPT_BLOCK_TEXT;
                    const _textContent = _typedTextContent.text.en;
                    if (_textContent) {
                        _newValue = _textContent;
                    }
                    break;
                case TRANSCRIPT_BLOCK_TYPES.IMAGE:
                    const _typedImageContent = props.block
                        .content as TRANSCRIPT_BLOCK_IMAGE;
                    const _imageValue = _typedImageContent.version;
                    if (_imageValue) {
                        _newValue = _imageValue;
                    }
                    break;
                default:
                    break;
            }
        }

        _setEditorState(_newValue || '');
    }, [props.block]);

    /* Handlers */
    function _onEditorChange(text?: string | number) {
        const _newText =
            text && typeof text === 'string'
                ? clearTextFromNewLines(text)
                : text;
        _setEditorState(_newText);
    }

    function _onTextAreaChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        _setEditorState(event.target.value);
    }

    function _onSubmit() {
        let _value: TRANSCRIPT_BLOCK_CONTENT | null = null;
        if (props.block && _editorState) {
            switch (props.block.type) {
                case TRANSCRIPT_BLOCK_TYPES.TEXT:
                case TRANSCRIPT_BLOCK_TYPES.TITLE:
                case TRANSCRIPT_BLOCK_TYPES.CALLOUT:
                    _value = {text: {en: _editorState as string}};
                    break;
                case TRANSCRIPT_BLOCK_TYPES.IMAGE:
                    _value = {
                        version: (_editorState as unknown) as TRANSCRIPT_BLOCK_IMAGE_OPTION,
                    };
                    break;
                default:
                    break;
            }

            if (_value) {
                props.onSave(_value);
            }
        }
    }

    /* Render */
    const _isOpen = Boolean(props.block);

    let _Editor = null;
    let _maxWidth: 'lg' | 'sm' | false = false;
    if (props.block) {
        switch (props.block.type) {
            case TRANSCRIPT_BLOCK_TYPES.TITLE:
                _maxWidth = 'sm';
                _Editor = (
                    <div className={styles.textareaWrapper}>
                        <TextField
                            autoFocus
                            multiline
                            fullWidth
                            onChange={_onTextAreaChange}
                            value={_editorState}
                        />
                    </div>
                );
                break;
            case TRANSCRIPT_BLOCK_TYPES.CALLOUT:
            case TRANSCRIPT_BLOCK_TYPES.TEXT:
                _Editor = (
                    <MDEditor
                        height={500}
                        commands={[
                            commands.bold,
                            commands.italic,
                            commands.divider,
                            commands.orderedListCommand,
                            commands.unorderedListCommand,
                        ]}
                        extraCommands={[
                            commands.codeEdit,
                            commands.codeLive,
                            commands.codePreview,
                            commands.fullscreen,
                        ]}
                        onChange={_onEditorChange}
                        value={_editorState as string}
                    />
                );
                break;
            case TRANSCRIPT_BLOCK_TYPES.IMAGE:
                _maxWidth = 'sm';
                _Editor = (
                    <TranscriptImagePicker
                        onChange={_onEditorChange}
                        value={_editorState as number}
                    />
                );
                break;
            default:
                break;
        }
    }

    return (
        <Dialog
            open={_isOpen}
            onClose={props.onClose}
            TransitionComponent={Transition}
            maxWidth={_maxWidth}
            fullWidth>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    <div
                        className={
                            styles.headerTitle
                        }>{`Edit ${props.block?.type}`}</div>
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={_onSubmit}
                    disabled={!_editorState}>
                    {'Save block'}
                </Button>
                <div className={styles.headerRight}>
                    <IconButton
                        color="secondary"
                        className={styles.closeButton}
                        onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </div>
            </div>
            <div className={styles.container} data-color-mode="light">
                <div className="wmde-markdown-var"> </div>
                {_Editor}
            </div>
        </Dialog>
    );
}

export default TranscriptEditorDialog;
