import React, {useContext, useState} from 'react';
import {auth} from 'firebase/app';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import firebase from 'config/firebase';

// to do - consistent capitalisation
import {LandingPageBackground} from 'assets/images/';
import SignInButton from 'components/buttons/sign-in-button/sign-in-button';
import {isFerlyMember} from 'helpers/user.helper';
import {snackbarContext} from 'providers/snackbar/snackbar.provider';

import IMG_LOGO from 'assets/images/logo/logo.svg';
import IMG_FERLY_LOGO from 'assets/images/logo/ferly.svg';
import IMG_SCRIBBLE from 'assets/images/visuals/scribble-purple.png';
import styles from './landing.module.css';

const Landing = (): JSX.Element => {
    // LifeCycle
    const [_isLoading, _setIsLoading] = useState<boolean>(false);

    const _snackbar = useContext(snackbarContext);

    // Handlers
    const _startSignIn = (): void => {
        _setIsLoading(true);
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then(({user}: auth.UserCredential) => {
                if (!isFerlyMember(user)) {
                    _setIsLoading(false);
                    _snackbar.error('Please login with your ferly account');
                }
            })
            .catch((err: firebase.FirebaseError) => {
                _setIsLoading(false);
                if (err.code !== 'auth/popup-closed-by-user') {
                    _snackbar.error(err.message);
                }
            });
    };

    // Render

    return (
        <div className={styles.mainContainer}>
            <div className={styles.loginSection}>
                <div className={styles.logo}>
                    <img src={IMG_LOGO} alt="AVERY" />
                </div>
                <p className={styles.ferlyDescription}>
                    Ferly’s content management platform.
                    <br /> Log in with your Ferly email address to access
                    content.
                </p>
                <div id="firebaseui-auth-container"></div>
                <SignInButton
                    onClick={_startSignIn}
                    className={styles.signInButton}
                />
            </div>
            <img src={LandingPageBackground} className={styles.collage} />
            <img src={IMG_SCRIBBLE} className={styles.scribble} />
            <img
                src={IMG_FERLY_LOGO}
                alt="Ferly"
                className={styles.ferlyLogo}
            />
            <Backdrop open={_isLoading} classes={{root: styles.backdrop}}>
                <CircularProgress color="secondary" />
            </Backdrop>
        </div>
    );
};
export default Landing;
