import React from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {Button, Theme} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.secondary.contrastText,
            },
        },
    });
const StyledSaveButton = withStyles(styles)(Button);
export default StyledSaveButton;
