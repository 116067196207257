import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './error-message.module.css';

interface ErrorMessageProps {
    message: string;
}

const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
    return (
        <div className={styles.container}>
            <ErrorIcon color="error" />
            <div className={styles.text}>{props.message}</div>
        </div>
    );
};

export default ErrorMessage;
