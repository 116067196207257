import * as yup from 'yup';

import {ACTIVITY_STATUS, SwitchedOnAreas} from 'types/activity.types';

function _requiredValidation(status: any): boolean {
    return status !== ACTIVITY_STATUS.DRAFT;
}

function _requiredAudioValidation(status: any, hasAudio: boolean): boolean {
    return status !== ACTIVITY_STATUS.DRAFT && hasAudio;
}

function _requiredTranscriptValidation(
    status: any,
    hasTranscript: boolean,
): boolean {
    return status !== ACTIVITY_STATUS.DRAFT && hasTranscript;
}

const validationSchema = (areas: SwitchedOnAreas): yup.ObjectSchema => {
    return yup.object({
        /* General - Required  */
        title: yup.string().label('Title').when('status', {
            is: _requiredValidation,
            then: yup.string().required(),
        }),
        shortBlurb: yup.string().label('Blurb').when('status', {
            is: _requiredValidation,
            then: yup.string().required(),
        }),
        thumbnailUrl: yup.string().label('Audio file').when('status', {
            is: _requiredValidation,
            then: yup.string().required(),
        }),
        contentType: yup.string().label('Content type').when('status', {
            is: _requiredValidation,
            then: yup.string().required(),
        }),
        releaseDate: yup
            .string()
            .label('Release date')
            .nullable()
            .when('status', {
                is: _requiredValidation,
                then: yup.string().required(),
            }),
        hotness: yup.number().min(0).max(5).integer(),
        actionType: yup.string(),

        /* Audio Specific - Required */
        downloadUrl: yup
            .string()
            .label('Audio file')
            .when(['status', 'hasAudio'], {
                is: _requiredAudioValidation,
                then: yup.string().required(),
            }),
        durationInSeconds: yup
            .number()
            .label('Audio duration')
            .when(['status', 'hasAudio'], {
                is: _requiredAudioValidation,
                then: yup
                    .number()
                    .min(
                        1,
                        'Duration must be more than 0 for published content',
                    )
                    .required(),
            }),
        voice: yup.string().label('Voice').when(['status', 'hasAudio'], {
            is: _requiredAudioValidation,
            then: yup.string().required(),
        }),
        voiceActor: yup.string(),

        /* Read Specific - Required */
        transcriptBlocks: yup
            .array()
            .label('Transcript blocks')
            .when(['status', 'hasTranscript'], {
                is: _requiredTranscriptValidation,
                then: yup
                    .array()
                    .min(1)
                    .required('Please add at least one block to transcript.'),
            }),
        transcriptDurationInSeconds: yup
            .number()
            .label('Read duration')
            .when(['status', 'hasTranscript'], {
                is: _requiredTranscriptValidation,
                then: yup
                    .number()
                    .min(
                        1,
                        'Duration must be more than 0 for published content.',
                    )
                    .required(),
            }),
    });
};
export default validationSchema;
