import React, {useEffect, useState} from 'react';

import ImageIcon from '@material-ui/icons/Image';
import HeadsetIcon from '@material-ui/icons/Headset';
import EditIcon from '@material-ui/icons/Edit';
import FormHelperText from '@material-ui/core/FormHelperText';

import cn from 'classnames';

import {
    FileMetadata,
    getFileInfoByUrl,
    MediaUrlProperty,
} from 'api/storage.api';

import {bytesToMB} from 'helpers/numbers.helper';
import {MAX_AUDIO_SIZE} from 'constants/file-size.constants';
import {TEMP_FILE_NAME} from 'constants/file-locations.constants';

import styles from './file-upload.module.css';

type FileUploadType = 'image' | 'audio';

interface FileUploadProps {
    id: string;
    name: MediaUrlProperty;
    type: FileUploadType;
    label: string;
    onChange: (
        property: MediaUrlProperty,
        file: File | null,
        error?: string | undefined,
    ) => void;
    value: string | null;
    maxFileSize?: number;
    error?: boolean;
    helperText?: string | undefined;
}

const SIZE_ERROR_MESSAGE =
    "Please upload  a file that's less than " + bytesToMB(MAX_AUDIO_SIZE, true);

const FileUpload = (props: FileUploadProps): JSX.Element => {
    /* Hooks n State */
    const [_url, _setUrl] = useState<string>('');
    const [_metadata, _setMetadata] = useState<FileMetadata | null>(null);

    useEffect(() => {
        async function _getInfo(url: string) {
            if (props.type === 'audio') {
                const _data = await getFileInfoByUrl(url);
                _setMetadata(_data);

                if (_data.size > MAX_AUDIO_SIZE) {
                    props.onChange(props.name, null, SIZE_ERROR_MESSAGE);
                }
            }
        }

        if (props.value && props.value !== TEMP_FILE_NAME) {
            _setUrl(props.value);

            if (props.type === 'audio') {
                _getInfo(props.value);
            }
        }
    }, [props.value]);

    /* Handlers */
    function _onChange(event: React.FormEvent<HTMLInputElement>): void {
        const _target = event.target as HTMLInputElement;

        const _files = _target.files as FileList;
        if (_files?.length) {
            _setUrl(URL.createObjectURL(_files[0]));

            let _data = null;
            let _error = undefined;

            if (props.maxFileSize && _files[0].size > props.maxFileSize) {
                _error = SIZE_ERROR_MESSAGE;
            } else {
                _data = _files[0];
            }

            props.onChange(props.name, _data, _error);
            _setMetadata({
                size: _files[0].size,
                deprecatedBucket: false,
            });
        }
    }

    /* Render */

    let _TypeIcon = ImageIcon;
    let _placeholder = 'Upload an image';

    const _isShowingPlaceholder = !_url;
    let _acceptedTypes = '.jpg,.jpeg,.png';

    let _ContentNode = null;

    if (props.type === 'audio') {
        _TypeIcon = HeadsetIcon;

        _acceptedTypes = '.mp3';

        if (_url) {
            _placeholder = '';
            let _MetadataNode = null;
            if (_metadata) {
                _MetadataNode = (
                    <div className={styles.metadata}>
                        {_metadata.size > -1 && (
                            <div className={styles.metadataRow}>
                                <div className={styles.metadataText}>
                                    uploaded size:
                                </div>
                                <div
                                    className={cn(
                                        styles.metadataText,
                                        styles.metadataTextBold,
                                    )}>
                                    {bytesToMB(_metadata.size, true)}
                                </div>
                            </div>
                        )}
                        {_metadata.deprecatedBucket && (
                            <div className={styles.metadataRow}>
                                <div className={styles.metadataText}>
                                    uploaded in the old bucket
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
            _ContentNode = (
                <div>
                    {_MetadataNode}
                    <audio controls src={_url} className={styles.audio} />
                </div>
            );
        } else {
            _placeholder = 'Upload an audio file';
        }
    } else {
        if (_url) {
            _ContentNode = (
                <div
                    className={styles.image}
                    style={{backgroundImage: `url(${_url})`}}></div>
            );
        }
    }

    const _containerClasses = cn(
        styles.container,
        styles['container_' + props.type],
        {
            [styles.containerError]: props.error,
        },
    );
    const _inputClasses = cn(styles.input, {
        [styles.inputWithValue]: _url,
        [styles.inputWithImage]: _url && props.type === 'image',
    });

    return (
        <div className={_containerClasses}>
            <label className={styles.wrappingLabel} htmlFor={props.name}>
                <fieldset className={styles.fieldset}>
                    <legend className={styles.legend}>
                        <span>{props.label}</span>
                    </legend>
                </fieldset>
                <div className={_inputClasses}>
                    <input
                        id={props.name}
                        type="file"
                        name={props.name}
                        hidden
                        onChange={_onChange}
                        accept={_acceptedTypes}
                    />
                    {_isShowingPlaceholder ? (
                        <>
                            <_TypeIcon
                                fontSize={'large'}
                                className={styles.typeIcon}
                            />
                            {_placeholder && (
                                <div
                                    className={cn(
                                        styles.metadataText,
                                        styles.metadataTextBold,
                                    )}>
                                    {_placeholder}
                                </div>
                            )}
                        </>
                    ) : (
                        _ContentNode
                    )}
                    <div className={styles.editOverlay}>
                        <EditIcon color="primary" />
                    </div>
                </div>
                <div className={styles.label}>{props.label}</div>
            </label>

            <FormHelperText error={props.error}>
                {props.helperText}
            </FormHelperText>
        </div>
    );
};

export default FileUpload;
