import React from 'react';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import StatusChip from 'components/status-chip/status-chip';

import {PROGRAM} from 'types/programs.types';
import styles from './programs-picker-item.module.css';

export type ArrowDirection = 'left' | 'right';
interface ProgramsPickerItemProps {
    program: PROGRAM | null;

    index?: number;
    onClick?: () => void;
    onDelete?: (index: number) => void;
    onArrowClick?: (index: number, direction: ArrowDirection) => void;
    totalCount?: number;
}

const ProgramsPickerItem = (props: ProgramsPickerItemProps): JSX.Element => {
    /* Handlers */
    function _onDeleteClick() {
        if (props.onDelete && props.index !== undefined)
            props.onDelete(props.index);
    }

    function _onArrowLeftClick() {
        if (props.onArrowClick && props.index !== undefined)
            props.onArrowClick(props.index, 'left');
    }

    function _onArrowRightClick() {
        if (props.onArrowClick && props.index !== undefined)
            props.onArrowClick(props.index, 'right');
    }

    /* Render */
    let _DeleteButton = null;
    let _ArrowButtons = null;
    if (props.onDelete) {
        _DeleteButton = (
            <IconButton
                color="secondary"
                classes={{root: styles.deleteButton}}
                onClick={_onDeleteClick}>
                <HighlightOffIcon />
            </IconButton>
        );
    }

    if (props.onArrowClick && props.totalCount !== undefined) {
        _ArrowButtons = (
            <div className={styles.arrowButtons}>
                <IconButton
                    disabled={props.index === 0}
                    color="primary"
                    className={styles.arrowButton}
                    onClick={_onArrowLeftClick}>
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    disabled={props.index === props.totalCount - 1}
                    color="primary"
                    className={styles.arrowButton}
                    onClick={_onArrowRightClick}>
                    <ArrowForwardIcon />
                </IconButton>
            </div>
        );
    }
    /* Render */
    if (!props.program) {
        return (
            <Button onClick={props.onClick} className={styles.program}>
                <ErrorOutlineIcon
                    className={styles.warningIcon}
                    fontSize="small"
                />
                Select programme
            </Button>
        );
    }

    const _Content = (
        <>
            <div className={styles.programContent}>
                <div
                    className={styles.programThumb}
                    style={{
                        backgroundImage:
                            'url(' + props.program.backgroundUrl + ')',
                    }}></div>
                <div className={styles.programTitle}>{props.program.title}</div>
                <div className={styles.statusChip}>
                    <StatusChip
                        status={props.program.status}
                        minimal
                        releaseDate={props.program.releaseDate}
                    />
                </div>
                <div className={styles.programButtons}>
                    {_DeleteButton}
                    {_ArrowButtons}
                </div>
            </div>
        </>
    );

    if (props.onClick) {
        return (
            <Button
                className={cn(styles.program, styles.selectedProgram)}
                onClick={props.onClick}>
                {_Content}
            </Button>
        );
    }

    return (
        <div className={cn(styles.program, styles.programWithButtons)}>
            {_Content}
        </div>
    );
};

export default ProgramsPickerItem;
