import React, {useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';

import styles from './page-section.module.css';

interface Action {
    label: string;
    action: () => void;
    key: string;
}

interface PageSectionProps {
    actions?: Action[];
    title: string;
    description: string;
    children: React.ReactNode;
}

const PageSection = (props: PageSectionProps): JSX.Element => {
    /* Hooks n State */
    const [_activeMenu, _setActiveMenu] = useState<HTMLElement | null>(null);
    const _sectionID = useRef<string>(uuid());

    /* Handlers */
    function _onMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
        _setActiveMenu(event.currentTarget);
    }

    function _onMenuClose() {
        _setActiveMenu(null);
    }

    function _onActionClick(handler: () => void) {
        _onMenuClose();
        handler();
    }

    /* Render */
    let _Menu = null;
    if (props.actions) {
        _Menu = (
            <div className={styles.headerRight}>
                <IconButton color="secondary" onClick={_onMenuOpen}>
                    <MoreIcon fontSize="small" />
                </IconButton>
                <Menu
                    id={'menu-' + _sectionID}
                    anchorEl={_activeMenu}
                    open={Boolean(_activeMenu)}
                    onClose={_onMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    {props.actions.map((action) => {
                        return (
                            <MenuItem
                                onClick={() => _onActionClick(action.action)}
                                key={action.key}>
                                {action.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    }
    return (
        <div className={styles.section}>
            <div className={styles.sectionHead}>
                <div className={styles.sectionHeadContent}>
                    <div className={styles.sectionTitle}>{props.title}</div>
                    <div className={styles.sectionText}>
                        {props.description}
                    </div>
                </div>
                {_Menu}
            </div>
            <Divider />
            <div className={styles.sectionContent}>{props.children}</div>
        </div>
    );
};

export default PageSection;
