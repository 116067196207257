import React, {useEffect, useState} from 'react';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {TransitionProps} from '@material-ui/core/transitions';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';

import useActivities from 'hooks/use-activities.hooks';
import useTags from 'hooks/use-tags.hooks';

import {ACTIVITY, ACTIVITY_STATUS} from 'types/activity.types';
import {CB_ACTIVITY} from 'types/app-screen.types';
import {getActivityBackgroundColor} from 'helpers/activity.helper';
import {TAG} from 'types/tags.types';

import styles from './activities-picker-dialog.module.css';
import {filterActivitiesByProp} from 'selectors/activities.selector';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {children?: React.ReactElement},
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

interface Filters {
    [key: string]: string | boolean | string[];
}

const _initialFilters: Filters = {
    search: '',
    hasSelectedAll: false,
    showSelectedOnly: false,
    tags: [],
};

interface ActivitiesPickerDialogProps {
    open: boolean;
    multiselect?: boolean;
    onClose: () => void;
    onChange: (sessions: CB_ACTIVITY[]) => void;
    value: CB_ACTIVITY | CB_ACTIVITY[] | null;
}

const ActivitiesPickerDialog = (
    props: ActivitiesPickerDialogProps,
): JSX.Element => {
    /* Hooks n State */
    const _activities = useActivities();
    const _tags = useTags();

    const [_visibleActivities, _setVisibleActivities] = useState<ACTIVITY[]>(
        [],
    );
    const [_selectedActivities, _setSelectedActivities] = useState<
        CB_ACTIVITY[]
    >([]);

    const [_hasSelectedAll, _setHasSelectedAll] = useState<boolean>(false);
    const [
        _isShowingSelectedOnly,
        _setIsShowingSelectedOnly,
    ] = useState<boolean>(false);

    const [
        _isShowingUnpublishedContent,
        _setIsShowingUnpublishedContent,
    ] = useState<boolean>(false);

    // Filters
    const [_searchValue, _setSearchValue] = useState<string>('');
    const [_selectedTags, _setSelectedTags] = useState<string[]>([]);
    const [_filters, _setFilters] = useState<Filters>(_initialFilters);

    useEffect(() => {
        if (props.value) {
            let _newValue: CB_ACTIVITY[] = [];

            if (Array.isArray(props.value)) {
                _newValue = props.value;
            } else {
                _newValue.push(props.value);
            }

            _setSelectedActivities(_newValue);
        }
    }, [props.value]);

    useEffect(() => {
        _setFilters(_initialFilters);
        _setVisibleActivities(_activities.all);
    }, [_activities.all]);

    function _onItemClick(id: string) {
        if (!props.multiselect) {
            props.onChange([{id, sort: -1}]);
            return;
        }

        const _index = _selectedActivities.findIndex(
            (session: CB_ACTIVITY) => session.id === id,
        );

        const _newActivities = [..._selectedActivities];

        if (_index > -1) {
            _newActivities.splice(_index, 1);
        } else {
            _newActivities.unshift({
                id,
                sort: -1,
            });
        }

        _setSelectedActivities(_newActivities);
    }

    function _onReadyClick() {
        const _sortedActivities = [..._selectedActivities];
        _sortedActivities.forEach((activity, index) => {
            activity.sort = index;
        });
        props.onChange(_sortedActivities);

        props.onClose();
    }

    function _onSelectAll() {
        const _newSelectedActivities = {..._selectedActivities};

        if (_selectedActivities.length === _visibleActivities.length) {
            _visibleActivities.forEach((visibleActivity) => {
                const _indexInSelectedActivities = _selectedActivities.findIndex(
                    (cbActivity) => cbActivity.id === visibleActivity.id,
                );
                // console.log(_indexInSelectedActivities);
            });
        } else {
            // console.log('yo');
        }
        // _setSelectedActivities(_newSelectedActivities);
    }

    function _onShowSelectedChange() {
        // _setIsShowingSelectedOnly(!_isShowingSelectedOnly);
        _handleFilterChange('showSelectedOnly', !_filters.showSelectedOnly);
    }

    function _onShowUnpublishedChange() {
        _setIsShowingUnpublishedContent(!_isShowingUnpublishedContent);
        const _filteredActivities = _visibleActivities.filter(
            (activity) => activity.status !== ACTIVITY_STATUS.UNPUBLISHED,
        );

        _setVisibleActivities(_filteredActivities);
    }

    function _onSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        _handleFilterChange('search', event.currentTarget.value);
    }

    function _onTagSelect(event: React.ChangeEvent<{value: unknown}>) {
        _handleFilterChange('tags', event.target.value);
    }

    function _handleFilterChange(property: string, value: any) {
        const _newFilters = {
            ..._filters,
            [property]: value,
        };
        let _newActivitiesToShow = _activities.all;

        if (_newFilters.search) {
            const _searchResults = filterActivitiesByProp(
                _newActivitiesToShow,
                'title',
                _newFilters.search as string,
            );
            _newActivitiesToShow = _searchResults;
        }

        if (_newFilters.tags.length) {
            _newActivitiesToShow = filterActivitiesByProp(
                _newActivitiesToShow,
                'contentTags',
                _newFilters.tags as string[],
            );
        }

        _setFilters(_newFilters);
        _setVisibleActivities(_newActivitiesToShow);
    }

    function _onFiltersClear() {
        _setFilters(_initialFilters);
        _setVisibleActivities(_activities.all);
    }

    /* Render */
    return (
        <Dialog open={props.open} fullScreen TransitionComponent={Transition}>
            <Fab
                aria-controls="page-fab"
                className={styles.fab}
                color="secondary"
                onClick={props.onClose}>
                <CloseIcon />
            </Fab>
            <div className={styles.container}>
                <div className={styles.sidebar}>
                    <div className={styles.sidebarContent}>
                        <div className={styles.title}>Select activities</div>
                        <div className={styles.selectedActivitiesCounter}>
                            {_selectedActivities.length +
                                ' / ' +
                                _visibleActivities.length +
                                ' selected'}
                        </div>

                        <div className={styles.sidebarInputs}>
                            <div className={styles.sidebarInputGroup}>
                                <div className={styles.sidebarInput}>
                                    <TextField
                                        classes={{root: styles.stretchedInput}}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon
                                                        className={
                                                            styles.inputIcon
                                                        }
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Search"
                                        placeholder="Search by title"
                                        onChange={_onSearchInputChange}
                                        value={_filters.search}
                                    />
                                </div>
                            </div>
                            <div className={styles.sidebarInputGroup}>
                                <div className={styles.sidebarInput}>
                                    <TextField
                                        classes={{root: styles.stretchedInput}}
                                        variant="outlined"
                                        id="dialog-tags"
                                        label="Filter by tag"
                                        onChange={_onTagSelect}
                                        select={true}
                                        value={_filters.tags}
                                        rows={4}
                                        SelectProps={{
                                            classes: {
                                                icon: styles.inputIcon,
                                            },
                                            multiple: true,
                                            renderValue: function renderTags(
                                                selected,
                                            ) {
                                                return (
                                                    <div
                                                        className={
                                                            styles.tagChips
                                                        }>
                                                        {(selected as string[]).map(
                                                            (value) => {
                                                                const _matchingTag = _tags.all.find(
                                                                    (tag) =>
                                                                        tag.id ===
                                                                        value,
                                                                );
                                                                if (
                                                                    !_matchingTag
                                                                )
                                                                    return null;
                                                                return (
                                                                    <Chip
                                                                        key={
                                                                            value
                                                                        }
                                                                        label={
                                                                            _matchingTag.label
                                                                        }
                                                                        className={
                                                                            styles.tagChip
                                                                        }
                                                                        size="small"
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                );
                                            },
                                        }}>
                                        {_tags.all.map((option: TAG) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}>
                                                <Checkbox
                                                    checked={
                                                        (_filters.tags as string[]).indexOf(
                                                            option.id,
                                                        ) > -1
                                                    }
                                                />
                                                <ListItemText>
                                                    {option.label}
                                                </ListItemText>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className={styles.sidebarInputGroup}>
                                <div className={styles.sidebarInput}>
                                    <FormControlLabel
                                        className={styles.sidebarInput}
                                        control={
                                            <Checkbox
                                                checked={
                                                    _filters.showSelectedOnly as boolean
                                                }
                                                onChange={_onShowSelectedChange}
                                                name="showAll"
                                            />
                                        }
                                        label="Only show selected"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                        className={styles.buttonClear}
                        variant="text"
                        onClick={_onFiltersClear}>
                        {'Clear filters'}
                    </Button>
                    <Button
                        className={styles.buttonReady}
                        variant="contained"
                        color="primary"
                        onClick={_onReadyClick}>
                        {'Done'}
                    </Button>
                </div>

                <div className={styles.sessions}>
                    <Grid container spacing={6}>
                        {_visibleActivities.map((session: ACTIVITY) => {
                            if (session.status === ACTIVITY_STATUS.UNPUBLISHED)
                                return null;
                            const _isActive =
                                _selectedActivities.filter(
                                    (selectedSession: CB_ACTIVITY) =>
                                        session.id === selectedSession.id,
                                ).length > 0;

                            const _buttonClasses = cn(
                                styles.sessionButton,
                                styles['sessionButton-' + session.contentType],
                                {
                                    [styles.sessionButtonActive]: _isActive,
                                },
                            );

                            if (!_isActive && _filters.showSelectedOnly)
                                return null;

                            return (
                                <Grid
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    lg={2}
                                    item
                                    key={session.id}>
                                    <ButtonBase
                                        className={_buttonClasses}
                                        onClick={() =>
                                            _onItemClick(session.id)
                                        }>
                                        <div
                                            className={styles.session}
                                            style={{
                                                backgroundColor: getActivityBackgroundColor(
                                                    session,
                                                ),
                                            }}>
                                            <div className={styles.thumbnail}>
                                                <div
                                                    className={
                                                        styles.thumbnailImage
                                                    }
                                                    style={{
                                                        backgroundImage:
                                                            'url(' +
                                                            session.thumbnailUrl +
                                                            ')',
                                                    }}></div>
                                            </div>
                                            <div
                                                className={styles.sessionTitle}>
                                                {session.title}
                                            </div>
                                            {_isActive && (
                                                <CheckCircleIcon
                                                    color="primary"
                                                    className={
                                                        styles.sessionCheck
                                                    }
                                                />
                                            )}
                                        </div>
                                    </ButtonBase>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </div>
        </Dialog>
    );
};

export default ActivitiesPickerDialog;
