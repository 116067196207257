import React, {MutableRefObject, useRef} from 'react';
import cn from 'classnames';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';
import ButtonBase from '@material-ui/core/ButtonBase';
import ScreenBlock from 'components/screen-block/screen-block.view';
import {CONTENT_BLOCK, SortDirection} from 'types/app-screen.types';
import {EXPERT} from 'types/experts.types';

import useModal from 'hooks/use-modal';
import useExperts from 'hooks/use-experts.hook';
import blockStyles from 'components/screen-block/screen-block.module.css';
import styles from './experts-carousel.module.css';
import ExpertsPickerItem from 'components/experts-picker-content/experts-picker-item.view';
import ExpertsPickerContent from 'components/experts-picker-content/experts-picker-content.view';
import {ArrowDirection} from '../../experts-picker-content/experts-picker-item.view';
import {CB_EXPERT} from '../../../types/app-screen.types';

interface ExpertsCarouselProps {
    block: CONTENT_BLOCK;
    blocksCount: number;
    errors: any;
    onChange: (blockId: string, key: string, value: any) => void;
    onSort: (sort: number, direction: SortDirection) => void;
}

const ExpertsCarousel = (props: ExpertsCarouselProps): JSX.Element => {
    const _modal = useModal();

    /* Hooks n State */
    const _experts = useExperts().all;
    const _selectedExperts = _experts.filter((exp) => {
        return !!props.block.experts?.find((item) => item.id === exp.id);
    });
    const _modalSelectedExperts: MutableRefObject<CB_EXPERT[]> = useRef(
        props.block.experts || [],
    );

    /* Handlers */
    function _onInputChange({target}: React.ChangeEvent<HTMLInputElement>) {
        if (props.block.id) {
            props.onChange(props.block.id, target.name, target.value);
        }
    }

    function _onExpertDeleteClick(index: number) {
        let _newValue = [..._modalSelectedExperts.current];
        _newValue.splice(index, 1);
        _newValue = _newValue.map((item, index) => {
            return {
                ...item,
                sort: index,
            };
        });

        props.onChange(props.block.id, 'experts', _newValue);
        _modalSelectedExperts.current = _newValue;
    }

    function _onExpertArrowClick(index: number, direction: ArrowDirection) {
        const _newValue = [..._modalSelectedExperts.current];

        const _indexToUpdate = direction === 'left' ? index - 1 : index + 1;
        const _movedBlock = _newValue[_indexToUpdate];
        _newValue[_indexToUpdate] = _newValue[index];
        _newValue[_indexToUpdate].sort = _indexToUpdate;
        _newValue[index] = _movedBlock;
        _newValue[index].sort = index;

        props.onChange(props.block.id, 'experts', _newValue);
        _modalSelectedExperts.current = _newValue;
    }

    async function _onClick() {
        const _result = await _modal.component({
            title: 'Select your experts',
            message: 'Which experts should be displayed in this carousel?',
            component: (
                <ExpertsPickerContent
                    selected={_selectedExperts}
                    onChange={_onExpertsChange}
                />
            ),
        });

        if (_result.success) {
            props.onChange(
                props.block.id,
                'experts',
                _modalSelectedExperts.current,
            );
        }
    }

    function _onExpertsChange(value: EXPERT[]) {
        _modalSelectedExperts.current = value.map((item, index) => {
            return {id: item.id, sort: index};
        });
    }

    /* Render */
    const _defaultInputProps: TextFieldProps = {
        variant: 'outlined',
        classes: {
            root: blockStyles.formInput,
        },
    };

    return (
        <ScreenBlock
            block={props.block}
            blocksCount={props.blocksCount}
            onChange={props.onChange}
            onSort={props.onSort}
            errors={props.errors}>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>General info</div>
                <div className={blockStyles.inputRow}>
                    {/* Title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'title' + props.block.id}
                            label="Title*"
                            name="title"
                            value={props.block.title}
                            onChange={_onInputChange}
                            error={props.errors.title !== undefined}
                            helperText={props.errors.title}
                        />
                    </div>

                    {/* Internal title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'internal-title' + props.block.id}
                            label="Internal title"
                            name="internalTitle"
                            value={props.block.internalTitle}
                            onChange={_onInputChange}
                            error={props.errors.internalTitle !== undefined}
                            helperText={props.errors.internalTitle}
                        />
                    </div>
                </div>
                <div className={blockStyles.inputRow}>
                    {/* Description */}
                    <div
                        className={cn(
                            blockStyles.formControl,
                            blockStyles.formControlXL,
                        )}>
                        <TextField
                            {..._defaultInputProps}
                            id={'text' + props.block.id}
                            label="Text*"
                            name="text"
                            value={props.block.text}
                            onChange={_onInputChange}
                            error={props.errors.text !== undefined}
                            helperText={props.errors.text}
                            multiline={true}
                            rows={4}
                        />
                    </div>
                </div>
            </div>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>
                    Content*
                    {_modalSelectedExperts.current &&
                        _modalSelectedExperts.current.length > 0 && (
                            <span>
                                {' '}
                                ({_modalSelectedExperts.current?.length})
                            </span>
                        )}
                </div>

                <div className={styles.grid}>
                    <div className={styles.gridItem} key={-1}>
                        <ButtonBase
                            className={cn(styles.addButton, styles.session)}
                            onClick={_onClick}>
                            <AddIcon color="primary" />
                        </ButtonBase>
                    </div>
                    {_modalSelectedExperts.current?.map((item, index) => {
                        const _expert = _experts.find(
                            (exp) => exp.id === item.id,
                        );

                        if (_expert) {
                            return (
                                <div
                                    className={styles.gridItem}
                                    key={_expert.id}>
                                    <ExpertsPickerItem
                                        expert={_expert}
                                        index={index}
                                        onClick={_onClick}
                                        onArrowClick={_onExpertArrowClick}
                                        onDelete={_onExpertDeleteClick}
                                        totalCount={
                                            _modalSelectedExperts.current.length
                                        }
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </ScreenBlock>
    );
};

export default ExpertsCarousel;
