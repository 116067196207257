import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';

import ActivitiesPicker from 'components/activities-picker/activities-picker.view';
import TagsPicker from 'components/tags-picker/tags-picker.view';
import ScreenBlock from 'components/screen-block/screen-block.view';
import {
    CB_ACTIVITY,
    CB_TAG,
    CONTENT_BLOCK,
    SortDirection,
} from 'types/app-screen.types';
import {TAG} from 'types/tags.types';

import blockStyles from 'components/screen-block/screen-block.module.css';
import useActivities from 'hooks/use-activities.hooks';
import useTags from 'hooks/use-tags.hooks';

interface GalleryCarouselProps {
    block: CONTENT_BLOCK;
    blocksCount: number;
    errors: any;
    onChange: (blockId: string, key: string, value: any) => void;
    onSort: (sort: number, direction: SortDirection) => void;
}

const GalleryCarousel = (props: GalleryCarouselProps): JSX.Element => {
    /* Hooks n State */
    const _activities = useActivities().all;
    const _tags = useTags().all;

    const [_availableTags, _setAvailableTags] = useState<TAG[]>([]);

    useEffect(() => {
        const _newAvailableTags: TAG[] = [];
        const _newTagsValue: CB_TAG[] = [];

        // Update list of available tags
        props.block.activities?.forEach((selectedActivity) => {
            const _matchingActivity = _activities.find(
                (session) => session.id === selectedActivity.id,
            );

            if (_matchingActivity && _matchingActivity.contentTags) {
                _matchingActivity.contentTags.forEach((contentTagID) => {
                    const _matchingTag = _newAvailableTags.find(
                        (availableTag) => availableTag.id === contentTagID,
                    );

                    if (!_matchingTag) {
                        const _tagToAdd = _tags.find(
                            (tag) => tag.id === contentTagID,
                        );
                        if (_tagToAdd) {
                            _newAvailableTags.push(_tagToAdd);

                            // block.tags should be updated with only tags that are present in selected activities
                            const _matchingValue = props.block.tags?.find(
                                (selectedTag) =>
                                    selectedTag.id === contentTagID,
                            );

                            if (_matchingValue) {
                                _newTagsValue?.push({id: contentTagID});
                            }
                        }
                    }
                });
            }
        });

        props.onChange(props.block.id, 'tags', _newTagsValue);
        _setAvailableTags(_newAvailableTags);
    }, [props.block.activities]);

    /* Handlers */
    function _onInputChange({target}: React.ChangeEvent<HTMLInputElement>) {
        if (props.block.id) {
            props.onChange(props.block.id, target.name, target.value);
        }
    }

    function _onActivitiesPickerChange(sessions: CB_ACTIVITY[], name: string) {
        if (props.block.id) {
            props.onChange(props.block.id, name, sessions);
        }
    }

    function _onTagsPickerChange(tags: CB_TAG[], name: string) {
        props.onChange(props.block.id, name, tags);
    }

    /* Render */
    const _defaultInputProps: TextFieldProps = {
        variant: 'outlined',
        classes: {
            root: blockStyles.formInput,
        },
    };

    return (
        <ScreenBlock
            block={props.block}
            blocksCount={props.blocksCount}
            onChange={props.onChange}
            onSort={props.onSort}
            errors={props.errors}>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>General info</div>
                <div className={blockStyles.inputRow}>
                    {/* Title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'title' + props.block.id}
                            label="Title*"
                            name="title"
                            value={props.block.title}
                            onChange={_onInputChange}
                            error={props.errors.title !== undefined}
                            helperText={props.errors.title}
                        />
                    </div>

                    {/* Internal title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'internal-title' + props.block.id}
                            label="Internal title"
                            name="internalTitle"
                            value={props.block.internalTitle}
                            onChange={_onInputChange}
                            error={props.errors.internalTitle !== undefined}
                            helperText={props.errors.internalTitle}
                        />
                    </div>
                </div>
                <div className={blockStyles.inputRow}>
                    {/* Description */}
                    <div
                        className={cn(
                            blockStyles.formControl,
                            blockStyles.formControlXL,
                        )}>
                        <TextField
                            {..._defaultInputProps}
                            id={'text' + props.block.id}
                            label="Text*"
                            name="text"
                            value={props.block.text}
                            onChange={_onInputChange}
                            error={props.errors.text !== undefined}
                            helperText={props.errors.text}
                            multiline={true}
                            rows={4}
                        />
                    </div>
                </div>
            </div>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>
                    Content*
                    {props.block.activities &&
                        props.block.activities.length > 0 && (
                            <span> ({props.block.activities?.length})</span>
                        )}
                </div>
                <ActivitiesPicker
                    onChange={_onActivitiesPickerChange}
                    name="activities"
                    value={props.block.activities || null}
                />
            </div>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>
                    Tags*
                    {props.block.tags && props.block.tags.length > 0 && (
                        <span> ({props.block.tags.length})</span>
                    )}
                </div>
                <TagsPicker
                    all={_availableTags}
                    onChange={_onTagsPickerChange}
                    name="tags"
                    value={props.block.tags || null}
                />
            </div>
        </ScreenBlock>
    );
};

export default GalleryCarousel;
