import React from 'react';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import StatusChip from 'components/status-chip/status-chip';
import {getActivityBackgroundColor} from 'helpers/activity.helper';
import {ACTIVITY} from 'types/activity.types';

import styles from './activities-picker-item.module.css';
import {Link} from 'react-router-dom';

export type ArrowDirection = 'left' | 'right';
interface ActivitiesPickerItemProps {
    activity: ACTIVITY | null;
    link?: boolean;
    index?: number;
    onClick?: () => void;
    onDelete?: (index: number) => void;
    onArrowClick?: (index: number, direction: ArrowDirection) => void;
    totalCount?: number;
}

const ActivitiesPickerItem = (
    props: ActivitiesPickerItemProps,
): JSX.Element => {
    /* Handlers */
    function _onDeleteClick() {
        if (props.onDelete && props.index !== undefined)
            props.onDelete(props.index);
    }

    function _onArrowLeftClick() {
        if (props.onArrowClick && props.index !== undefined)
            props.onArrowClick(props.index, 'left');
    }

    function _onArrowRightClick() {
        if (props.onArrowClick && props.index !== undefined)
            props.onArrowClick(props.index, 'right');
    }

    /* Render */
    let _DeleteButton = null;
    let _ArrowButtons = null;
    if (props.onDelete) {
        _DeleteButton = (
            <IconButton
                color="secondary"
                classes={{root: styles.deleteButton}}
                onClick={_onDeleteClick}>
                <HighlightOffIcon />
            </IconButton>
        );
    }

    if (props.onArrowClick && props.totalCount !== undefined) {
        _ArrowButtons = (
            <div className={styles.arrowButtons}>
                <IconButton
                    disabled={props.index === 0}
                    color="primary"
                    className={styles.arrowButton}
                    onClick={_onArrowLeftClick}>
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    disabled={props.index === props.totalCount - 1}
                    color="primary"
                    className={styles.arrowButton}
                    onClick={_onArrowRightClick}>
                    <ArrowForwardIcon />
                </IconButton>
            </div>
        );
    }
    /* Render */
    if (!props.activity) {
        return (
            <Button onClick={props.onClick} className={styles.activity}>
                <ErrorOutlineIcon
                    className={styles.warningIcon}
                    fontSize="small"
                />
                Select activity
            </Button>
        );
    }

    const _Content = (
        <>
            <div
                className={styles.background}
                style={{
                    backgroundColor: getActivityBackgroundColor(props.activity),
                }}></div>
            <div className={styles.activityContent}>
                <div
                    className={styles.activityThumb}
                    style={{
                        backgroundImage:
                            'url(' + props.activity.thumbnailUrl + ')',
                    }}></div>
                <div className={styles.activityTitle}>
                    {props.activity.title}
                </div>
                <div className={styles.statusChip}>
                    <StatusChip
                        status={props.activity.status}
                        minimal
                        releaseDate={props.activity.releaseDate}
                    />
                </div>
                <div className={styles.activityButtons}>
                    {_DeleteButton}
                    {_ArrowButtons}
                </div>
            </div>
        </>
    );

    if (props.link) {
        return (
            <Link
                className={cn(styles.activity, styles.selectedActivity)}
                to={'/audio-content/' + props.activity.id}>
                {_Content}
            </Link>
        );
    }

    if (props.onClick) {
        return (
            <Button
                className={cn(styles.activity, styles.selectedActivity)}
                onClick={props.onClick}>
                {_Content}
            </Button>
        );
    }

    return (
        <div className={cn(styles.activity, styles.activityWithButtons)}>
            {_Content}
        </div>
    );
};

export default ActivitiesPickerItem;
