import firebase, {firestore} from 'firebase/app';

import {
    ACTIVITY,
    FIRESTORE_ACTIVITY,
    ACTIVITY_STATUS,
    ACTIVITY_WITH_TRANSCRIPT,
} from 'types/activity.types';
import {
    ACTIVITIES_COLLECTION,
    ACTIVITIES_COLLECTION_TEST,
} from 'constants/collection-names.constants';
import initialActivityState from 'constants/initial-activity-state.constants';

import {fireStoreObjectToActivity} from 'helpers/activity.helper';
import {TRANSCRIPT_BLOCK} from '../types/activity-transcript.types';
import {LocationSearchingOutlined} from '@material-ui/icons';

const collectionName = process.env.REACT_APP_IS_TEST_COLLECTION
    ? ACTIVITIES_COLLECTION_TEST
    : ACTIVITIES_COLLECTION;

/* Get a single activity */
export async function getActivity(id: string): Promise<ACTIVITY> {
    const db = firebase.firestore();

    const docRef = db.collection(collectionName).doc(id);
    try {
        const doc = await docRef.get();
        if (!doc) throw new Error("sDocument doesn't exist");

        const _data = doc.data() as FIRESTORE_ACTIVITY;

        if (_data) {
            return fireStoreObjectToActivity(_data, doc.id);
        }

        throw new Error('Something went wrong');
    } catch (e) {
        throw new Error(e);
    }
}

/* Get all activities */
export async function getAllActivities(
    handler: (activities: ACTIVITY[]) => void,
): Promise<() => void> {
    try {
        const db = firebase.firestore();

        const _subscription = db
            .collection(collectionName)
            .orderBy('lastSaveDate', 'desc')
            .onSnapshot((snapshot) => {
                const _activities: ACTIVITY[] = [];
                snapshot.forEach((activity) => {
                    const _activityData = activity.data() as FIRESTORE_ACTIVITY;
                    _activities.push(
                        fireStoreObjectToActivity(_activityData, activity.id),
                    );
                });
                handler(_activities);
            });

        return _subscription;
    } catch (error) {
        throw new Error(error);
    }
}

/* Create new activity */
export async function createActivity(): Promise<string> {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
        db.collection(collectionName)
            .add(initialActivityState)
            .then((docRef) => {
                resolve(docRef.id);
            })

            .catch((err: Error) => {
                reject(err);
            });
    });
}

/* Update an activity */
export async function updateActivity(
    id: string,
    data: Partial<ACTIVITY_WITH_TRANSCRIPT>,
    fromSync = false,
    withFiles = false,
): Promise<ACTIVITY> {
    const db = firebase.firestore();

    const _lastSaveDate = new Date();
    let _status = data.status;
    let _lastSyncDate = data.lastSyncDate || null;

    const _transcriptBlocks = data.transcriptBlocks;
    if (data.transcriptBlocks) {
        delete data.transcriptBlocks;
    }

    if (
        data.status &&
        data.status !== ACTIVITY_STATUS.UNPUBLISHED &&
        data.status !== ACTIVITY_STATUS.DRAFT &&
        !fromSync
    ) {
        // Something changed, change status to not synced
        // unless we're unpublishing or in draft mode
        _status = ACTIVITY_STATUS.NOT_SYNCED;
    } else if (fromSync) {
        // Set status to published and make sync dates and save date identical
        if (data.status !== ACTIVITY_STATUS.UNPUBLISHED) {
            _status = ACTIVITY_STATUS.PUBLISHED;
        }
        _lastSyncDate = _lastSaveDate;
    }

    if (!fromSync && _transcriptBlocks) {
        const _promises = [];
        for (const block of _transcriptBlocks) {
            _promises.push(saveTranscriptBlock(id, block));
        }
        await Promise.all(_promises);
    }

    try {
        const _sessionRef = db.collection(collectionName).doc(id);

        const _dataToSave = {
            ...data,
            lastSaveDate: _lastSaveDate,
            lastSyncDate: _lastSyncDate,
        };

        if (withFiles) _dataToSave.hasUnsyncedMedia = true;

        if (_status) _dataToSave.status = _status;
        await _sessionRef.set(_dataToSave, {merge: true});
        const _newSnapshot = await _sessionRef.get();
        const _newDocData = _newSnapshot.data() as FIRESTORE_ACTIVITY;

        return fireStoreObjectToActivity(_newDocData, id);
    } catch (error) {
        throw new Error(error);
    }
}

/* Get transcript blocks for a single activity */
export async function getActivityTranscriptBlocks(
    id: string,
): Promise<TRANSCRIPT_BLOCK[]> {
    const db = firebase.firestore();

    const docRef = db.collection(collectionName).doc(id);
    try {
        const doc = await docRef.get();
        if (!doc) throw new Error("Document doesn't exist");

        const _subcollectionDocs = await docRef
            .collection('transcript')
            .orderBy('sort')
            .get();
        const _blocksToReturn: TRANSCRIPT_BLOCK[] = [];
        _subcollectionDocs.docs.forEach((block) => {
            _blocksToReturn.push({
                ...block.data(),
                id: block.id,
            } as TRANSCRIPT_BLOCK);
        });

        return _blocksToReturn;
        // throw new Error('Something went wrong');
    } catch (e) {
        throw new Error(e);
    }
}

export async function createNewTranscriptBlock(
    activityID: string,
    block: Partial<TRANSCRIPT_BLOCK>,
    addBlockBelowSort: number,
): Promise<TRANSCRIPT_BLOCK[]> {
    const {id, ...blockPropertiesToSave} = block;
    const _activityDoc = firestore().collection(collectionName).doc(activityID);

    const _newBlockRef = await _activityDoc
        .collection('transcript')
        .add(blockPropertiesToSave);

    const _blocks = await getActivityTranscriptBlocks(activityID);

    /* If sort is passed, save sort on blocks below */
    if (addBlockBelowSort > -1) {
        const _blocksPromises: Promise<TRANSCRIPT_BLOCK[] | void>[] = [];
        _blocks.forEach((block) => {
            if (
                block.id !== _newBlockRef.id &&
                block.sort >= addBlockBelowSort
            ) {
                block.sort += 1;
                // console.log('UPDATE SORT FOR BLOCK', block.sort, block);
            }
            _blocksPromises.push(saveTranscriptBlock(activityID, block, false));
        });
        await Promise.all(_blocksPromises);
    }

    // const _blocks = await getActivityTranscriptBlocks(activityID);
    return _blocks.sort((a, b) => a.sort - b.sort);
}

export async function saveTranscriptBlock(
    activityID: string,
    block: TRANSCRIPT_BLOCK,
    shouldGetBlocks?: boolean,
): Promise<TRANSCRIPT_BLOCK[] | void> {
    const {id, dirty, ...blockPropertiesToSave} = block;

    const _blockRef = firestore()
        .collection(collectionName)
        .doc(activityID)
        .collection('transcript')
        .doc(id);
    await _blockRef.set(
        {
            ...blockPropertiesToSave,
        },
        {
            merge: true,
        },
    );

    if (shouldGetBlocks) {
        const _blocks = await getActivityTranscriptBlocks(activityID);
        return _blocks;
    }
}

export async function deleteTranscriptBlock(
    activityID: string,
    blockID: string,
): Promise<TRANSCRIPT_BLOCK[]> {
    const _blockRef = firestore()
        .collection(collectionName)
        .doc(activityID)
        .collection('transcript')
        .doc(blockID);

    await _blockRef.delete();
    const _blocks = await getActivityTranscriptBlocks(activityID);
    return _blocks;
}
