import React, {createContext, useEffect, useRef, useState} from 'react';

import {getAllTags} from 'api/tags.api';
import {TAG} from 'types/tags.types';

export interface TagsAPI {
    all: TAG[];
    get: () => Promise<null | (() => void)>;
}

const _initialState: TagsAPI = {
    all: [],
    get: async () => null,
};

interface Props {
    children: React.ReactNode;
}

export const TagsContext = createContext<TagsAPI>(_initialState);

const TagsProvider = (props: Props): JSX.Element => {
    /* Hooks n State */
    const [_tags, _setTags] = useState<TAG[]>([]);
    const _subscriptionRef = useRef<(() => void) | null>(null);

    useEffect(() => {
        return () => {
            if (_subscriptionRef.current) {
                _subscriptionRef.current();
            }
        };
    }, []);

    /* Handlers */
    function _onTagsSnapshot(tags: TAG[]) {
        _setTags(tags);
    }

    async function _getTags() {
        const _unsubscribe = await getAllTags(_onTagsSnapshot);

        _subscriptionRef.current = _unsubscribe;
        return _unsubscribe;
    }

    /* Render */
    const _value: TagsAPI = {
        all: _tags,
        get: _getTags,
    };

    return (
        <TagsContext.Provider value={_value}>
            {props.children}
        </TagsContext.Provider>
    );
};

export default TagsProvider;
