import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

import {getAllActivities, createActivity} from 'api/activities.api';
import Table from 'components/table/table';
import secondsToMinutes from 'helpers/seconds-to-minutes.helper';
import {snackbarContext} from 'providers/snackbar/snackbar.provider';
import {ACTIVITY, ACTIVITY_TABLE_DATA} from 'types/activity.types';

import Page from 'components/page/page';
import useActivities from 'hooks/use-activities.hooks';

/* Interfaces */
enum PAGE_STATE {
    ERROR,
    BOOTING,
    EDITING,
    SAVING,
}

const Sessions = (): JSX.Element => {
    /* Hooks n State */
    const _snackbar = useContext(snackbarContext);

    const _allActivities = useActivities().all;
    const [_sessions, _setSessions] = useState<ACTIVITY_TABLE_DATA[] | null>(
        null,
    );

    const [_pageState, _setPageState] = useState<PAGE_STATE>(
        PAGE_STATE.EDITING,
    );
    const _history = useHistory();

    /// Load sessions on init
    useEffect(() => {
        const _formattedSessions: ACTIVITY_TABLE_DATA[] = [];
        _allActivities.forEach((session: ACTIVITY) => {
            _formattedSessions.push({
                thumbnailUrl:
                    // 'https://firebasestorage.googleapis.com/v0/b/ferly-dev.appspot.com/o/Thumbnail.png?alt=media&token=85ebd20d-70f3-4ec7-8673-e4c86e855b4e',
                    session.thumbnailUrl,
                title: session.title,
                status: session.status,
                duration: session.durationInSeconds
                    ? secondsToMinutes(session.durationInSeconds)
                    : 'N/A',
                releaseDate: session.releaseDate,
                lastSaveDate: session.lastSaveDate,
                lastSyncDate: session.lastSyncDate,
                id: session.id,
            });
        });

        _setSessions(_formattedSessions);
    }, [_allActivities]);

    /* Handlers */
    async function _onNewSessionClick() {
        _setPageState(PAGE_STATE.SAVING);
        try {
            const _newSessionID = await createActivity();
            if (_newSessionID) {
                _setPageState(PAGE_STATE.EDITING);
                _history.push('/audio-content/' + _newSessionID);
            }
        } catch (error) {
            _setPageState(PAGE_STATE.EDITING);
            _snackbar.error(error.message);
        }
    }

    /* Render */
    const _isLoading =
        _pageState === PAGE_STATE.BOOTING || _pageState === PAGE_STATE.SAVING;
    return (
        <Page
            isLoading={_isLoading}
            onFabPress={_onNewSessionClick}
            horizontalPadding={{left: false, right: false}}
            title="Audio content">
            {_sessions && <Table data={_sessions} />}
        </Page>
    );
};

export default Sessions;
