import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';

import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import StatusChip from 'components/status-chip/status-chip';

import {ACTIVITY_STATUS} from 'types/activity.types';
import {PROGRAM} from 'types/programs.types';

import styles from './programs-overview.module.css';

interface ProgramsOverviewItemProps {
    program: PROGRAM;
}

interface FormattedProgram {
    backgroundUrl: string;
    id: string;
    key: string;
    lastSaveMoment: string;
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
    latest_version: boolean;
    modulesCount: number;
    releaseDate: Date | null;
    releaseMoment: string;
    sessionsCount: number;
    status: ACTIVITY_STATUS;
    synced: boolean;
    title: string;
    url: string;
    version: number;
}

const ProgramsOverviewItem = (
    props: ProgramsOverviewItemProps,
): JSX.Element | null => {
    /* Hooks n State */
    const [
        _formattedProgram,
        _setFormattedProgram,
    ] = useState<FormattedProgram | null>(null);

    useEffect(() => {
        let _sessionsCount = 0;
        for (const _module of props.program.modules) {
            _sessionsCount += _module.sessions.length;
        }
        const _newProgram: FormattedProgram = {
            backgroundUrl: props.program.backgroundUrl || '',
            key: props.program.key,
            id: props.program.id,
            lastSaveMoment: props.program.lastSaveDate
                ? moment(props.program.lastSaveDate).format('MMM DD, YYYY')
                : '',
            lastSaveDate: props.program.lastSaveDate,
            lastSyncDate: props.program.lastSyncDate,
            latest_version: props.program.latest_version,
            releaseDate: props.program.releaseDate,
            releaseMoment: moment(props.program.releaseDate).format(
                'MMM DD, YYYY',
            ),
            modulesCount: props.program.modules.length,
            sessionsCount: _sessionsCount,
            status: props.program.status,
            synced:
                props.program.lastSaveDate?.getTime() ===
                props.program.lastSyncDate?.getTime(),
            title: props.program.title,
            url: '/programs/' + props.program.id,
            version: props.program.version,
        };

        _setFormattedProgram(_newProgram);
    }, [props.program]);

    /* Render */
    if (!_formattedProgram) return null;

    const _rowClasses = cn(styles.row, {
        [styles.rowDeprecated]: !_formattedProgram.latest_version,
    });

    let _shouldShowStatusChip = true;
    if (_formattedProgram.synced && !_formattedProgram.latest_version) {
        _shouldShowStatusChip = false;
    }

    return (
        <tr className={_rowClasses}>
            <td className={styles.cell}>
                <Link to={_formattedProgram.url} className={styles.cellLink}>
                    <div className={styles.thumbCell}>
                        {_formattedProgram.backgroundUrl ? (
                            <img
                                src={_formattedProgram.backgroundUrl}
                                alt={_formattedProgram.title}
                                className={styles.thumbImage}
                            />
                        ) : (
                            <div className={styles.noImage}>
                                <BrokenImageIcon fontSize="large" />
                            </div>
                        )}
                        <div className={styles.status}>
                            {_shouldShowStatusChip && (
                                <StatusChip
                                    status={_formattedProgram.status}
                                    releaseDate={_formattedProgram.releaseDate}
                                    lastSaveDate={
                                        _formattedProgram.lastSaveDate
                                    }
                                    lastSyncDate={
                                        _formattedProgram.lastSyncDate
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Link>
            </td>
            <td className={styles.cell}>
                <Link to={_formattedProgram.url} className={styles.cellLink}>
                    <div className={styles.titleCellText}>
                        {_formattedProgram.title}
                    </div>
                </Link>
            </td>
            <td className={styles.cell}>
                <Link to={_formattedProgram.url} className={styles.cellLink}>
                    {_formattedProgram.key + ' / v' + _formattedProgram.version}
                </Link>
            </td>
            <td className={styles.cell}>
                <Link to={_formattedProgram.url} className={styles.cellLink}>
                    {_formattedProgram.modulesCount +
                        '/' +
                        _formattedProgram.sessionsCount}
                </Link>
            </td>
            <td className={styles.cell}>
                <Link to={_formattedProgram.url} className={styles.cellLink}>
                    {_formattedProgram.releaseMoment}
                </Link>
            </td>
            <td className={styles.cell}>
                <Link to={_formattedProgram.url} className={styles.cellLink}>
                    {_formattedProgram.lastSaveMoment || 'Not saved yet'}
                </Link>
            </td>
            <td className={styles.cell}>
                <Link to={_formattedProgram.url} className={styles.cellLink}>
                    {_formattedProgram.id}
                </Link>
            </td>
        </tr>
    );
};

export default ProgramsOverviewItem;
