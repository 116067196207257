import {
    MODULE,
    PROGRAM_BLOCK,
    PROGRAM_BLOCK_TYPES,
    PROGRAM_INTRODUCTION,
    PROGRAM_SESSION,
} from 'types/programs.types';

type SNIPPET_MODULE = Omit<MODULE, 'releaseDate'> & {
    releaseDate: string;
};

type SNIPPET_SESSION = Omit<PROGRAM_SESSION, 'actionType' | 'type'> & {
    actionType: string;
    type: string;
};

export const PROGRAM_SESSION_SNIPPET: SNIPPET_SESSION = {
    actionType: 'do | learn',
    description: '',
    id: '',
    index: -1,
    question: '',
    title: '',
    type: 'activity | reflection',
};

export const PROGRAM_MODULE_SNIPPET: SNIPPET_MODULE = {
    title: '',
    description: '',
    id: '',
    index: -1,
    label: '',
    premium: true,
    releaseDate: '',
    sessions: [],
};

export const PROGRAM_INTRODUCTION_SNIPPET: PROGRAM_INTRODUCTION = {
    id: '',
    description: '',
};

export const DETAIL_BLOCK_KEY_POINTS_SNIPPET: PROGRAM_BLOCK = {
    sort: -1,
    type: PROGRAM_BLOCK_TYPES.KEY_POINTS,
    content: {
        caption: '',
    },
    keyPoints: [
        {
            title: '',
            text: '',
        },
        {
            title: '',
            text: '',
        },
        {
            title: '',
            text: '',
        },
    ],
};

export const DETAIL_BLOCK_PHOTO_SNIPPET: PROGRAM_BLOCK = {
    sort: -1,
    type: PROGRAM_BLOCK_TYPES.PHOTO,
    content: {
        title: '',
        text: '',
        caption: '',
    },
    style: {
        backgroundImage: '',
    },
};

export const DETAIL_BLOCK_TEXT_SNIPPET: PROGRAM_BLOCK = {
    sort: -1,
    type: PROGRAM_BLOCK_TYPES.TEXT,
    content: {
        title: '',
        text: '',
        caption: '',
    },
};

export const DETAIL_BLOCK_QUOTE_SNIPPET: PROGRAM_BLOCK = {
    sort: -1,
    type: PROGRAM_BLOCK_TYPES.QUOTE,
    content: {
        title: '',
        caption: '',
    },
};

export const DETAIL_BLOCK_DIVIDER_SNIPPET: PROGRAM_BLOCK = {
    sort: -1,
    type: PROGRAM_BLOCK_TYPES.DIVIDER,
};
