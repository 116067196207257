import React, {useState} from 'react';
import cn from 'classnames';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ActivitiesPickerItem from 'components/activities-picker/activities-picker-item.view';
import {
    FeaturedCarouselData,
    FEATURED_CONTENT_TYPE,
    SortDirection,
} from 'types/app-screen.types';
import {ACTIVITY} from 'types/activity.types';
import {PROGRAM} from 'types/programs.types';

import styles from './featured-carousel-item.module.css';
import ProgramsPickerItem from 'components/program-picker-content/programs-picker-item.view';

interface FeaturedCarouselItemProps {
    index: number;
    totalCount: number;
    onActivityClick: (id: string, index: number) => void;
    onProgramClick: (key: string, index: number) => void;
    onArrowClick: (index: number, direction: SortDirection) => void;
    onDelete: (index: number) => void;
    value: FeaturedCarouselData;
}

const FeaturedCarouselItem = (
    props: FeaturedCarouselItemProps,
): JSX.Element => {
    const [_selectedType, _setSelectedType] = useState(
        props.value.type || FEATURED_CONTENT_TYPE.ACTIVITY,
    );
    /* Handlers */
    function _onActivityClick() {
        props.onActivityClick(props.value.id as string, props.index);
    }

    function _onProgramClick() {
        props.onProgramClick(props.value.key as string, props.index);
    }

    function _onArrowLeftClick() {
        props.onArrowClick(props.index, 'up');
    }

    function _onArrowRightClick() {
        props.onArrowClick(props.index, 'down');
    }

    function _onDeleteClick() {
        props.onDelete(props.index);
    }

    /* Render */
    const _defaultInputProps: TextFieldProps = {
        variant: 'outlined',
    };

    return (
        <Box boxShadow={3} className={styles.container}>
            <div className={cn(styles.row, styles.inputRow)}>
                <TextField
                    {..._defaultInputProps}
                    fullWidth
                    id={'featured_type_' + props.index}
                    label="Type of content"
                    name="type"
                    value={_selectedType}
                    onChange={(e) => {
                        _setSelectedType(
                            e.target.value as FEATURED_CONTENT_TYPE,
                        );
                    }}
                    select={true}
                    SelectProps={{
                        classes: {
                            icon: styles.inputIcon,
                        },
                    }}>
                    <MenuItem value={FEATURED_CONTENT_TYPE.ACTIVITY}>
                        <span className={styles.menuItemContent}>
                            {FEATURED_CONTENT_TYPE.ACTIVITY}
                        </span>
                    </MenuItem>
                    <MenuItem value={FEATURED_CONTENT_TYPE.PROGRAM}>
                        <span className={styles.menuItemContent}>
                            {'Program'}
                        </span>
                    </MenuItem>
                </TextField>
            </div>
            <div className={cn(styles.row, styles.rowStretched)}>
                {_selectedType === FEATURED_CONTENT_TYPE.ACTIVITY && (
                    <ActivitiesPickerItem
                        activity={
                            props.value.data
                                ? (props.value.data as ACTIVITY)
                                : null
                        }
                        onClick={_onActivityClick}
                    />
                )}
                {_selectedType === FEATURED_CONTENT_TYPE.PROGRAM && (
                    <ProgramsPickerItem
                        program={
                            props.value.data
                                ? (props.value.data as PROGRAM)
                                : null
                        }
                        onClick={_onProgramClick}
                    />
                )}
            </div>
            <div className={cn(styles.row, styles.buttonRow)}>
                <IconButton
                    disabled={props.index === 0}
                    color="secondary"
                    className={styles.arrowButton}
                    onClick={_onArrowLeftClick}
                    size="small">
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    color="secondary"
                    className={styles.deleteButton}
                    onClick={_onDeleteClick}
                    size="small">
                    <HighlightOffIcon />
                </IconButton>
                <IconButton
                    disabled={props.index === props.totalCount - 1}
                    color="secondary"
                    className={styles.arrowButton}
                    onClick={_onArrowRightClick}
                    size="small">
                    <ArrowForwardIcon />
                </IconButton>
            </div>
        </Box>
    );
};

export default FeaturedCarouselItem;
