import React from 'react';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from './experts-picker-item.module.css';
import {EXPERT} from 'types/experts.types';

export type ArrowDirection = 'left' | 'right';
interface ExpertsPickerItemProps {
    expert: EXPERT | null;

    index?: number;

    onClick: () => void;
    onDelete?: (index: number) => void;
    onArrowClick?: (index: number, direction: ArrowDirection) => void;
    totalCount?: number;
}

const ExpertsPickerItem = (props: ExpertsPickerItemProps): JSX.Element => {
    /* Handlers */
    function _onDeleteClick() {
        if (props.onDelete && props.index !== undefined)
            props.onDelete(props.index);
    }

    function _onArrowLeftClick() {
        if (props.onArrowClick && props.index !== undefined)
            props.onArrowClick(props.index, 'left');
    }

    function _onArrowRightClick() {
        if (props.onArrowClick && props.index !== undefined)
            props.onArrowClick(props.index, 'right');
    }

    /* Render */
    let _DeleteButton = null;
    let _ArrowButtons = null;
    if (props.onDelete) {
        _DeleteButton = (
            <IconButton
                color="secondary"
                classes={{root: styles.deleteButton}}
                onClick={_onDeleteClick}>
                <HighlightOffIcon />
            </IconButton>
        );
    }

    if (props.onArrowClick && props.totalCount !== undefined) {
        _ArrowButtons = (
            <div className={styles.arrowButtons}>
                <IconButton
                    disabled={props.index === 0}
                    color="primary"
                    className={styles.arrowButton}
                    onClick={_onArrowLeftClick}>
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    disabled={props.index === props.totalCount - 1}
                    color="primary"
                    className={styles.arrowButton}
                    onClick={_onArrowRightClick}>
                    <ArrowForwardIcon />
                </IconButton>
            </div>
        );
    }
    /* Render */
    if (!props.expert) {
        return (
            <Button onClick={props.onClick} className={styles.experts}>
                <ErrorOutlineIcon
                    className={styles.warningIcon}
                    fontSize="small"
                />
                Select experts
            </Button>
        );
    }

    const _Content = (
        <>
            <div className={styles.expertsContent}>
                <div
                    className={styles.expertsThumb}
                    style={{
                        backgroundImage: 'url("' + props.expert.imageUrl + '")',
                    }}></div>
                <div
                    className={
                        styles.expertsTitle
                    }>{`${props.expert.firstName} ${props.expert.lastName}`}</div>
                <div className={styles.expertsButtons}>
                    {_DeleteButton}
                    {_ArrowButtons}
                </div>
            </div>
        </>
    );

    return (
        <div className={cn(styles.experts, styles.expertsWithButtons)}>
            {_Content}
        </div>
    );
};

export default ExpertsPickerItem;
