// Trying to create an enum type for what I have.
// https://stackoverflow.com/questions/50158272/what-is-the-type-of-an-enum-in-typescript
type Enum<E> = Record<keyof E, string> & {[k: string]: string};

interface Options {
    label: string;
    value: string;
}

export default <E extends Enum<E>>(sessionEnum: E): Options[] =>
    Object.keys(sessionEnum).map(
        (key: string): Options => {
            return {
                label:
                    key[0] +
                    key.substring(1).toLowerCase().replaceAll('_', ' '),
                value: sessionEnum[key as keyof typeof sessionEnum],
            };
        },
    );
