import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {useHistory} from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import styles from './header.module.css';

interface HeaderButton {
    cta: string;
    action: () => void;
    loading?: boolean;
}

export interface HeaderProps {
    title?: string;
    titleAdornment?: React.ReactNode;
    textFieldProps?: TextFieldProps;
    rightButton?: HeaderButton;
}

const MIN_TITLE_WIDTH = 300;

const Header = (props: HeaderProps): JSX.Element => {
    /* Life cycle: Hooks init hooks and state */
    const [_titleWidth, _setTitleWidth] = useState<number>(MIN_TITLE_WIDTH);
    const _history = useHistory();

    useEffect(() => {
        let _newWidth = MIN_TITLE_WIDTH;
        if (props.textFieldProps?.value) {
            const _value = props.textFieldProps.value as string;
            _newWidth = _value.length * 10;
        }
        _setTitleWidth(
            _newWidth > MIN_TITLE_WIDTH ? _newWidth : MIN_TITLE_WIDTH,
        );
    }, [props.textFieldProps?.value]);

    /* Handlers */
    const _onBackClick = () => {
        _history.push('/' + _history.location.pathname.split('/')[1]);
    };

    /* Render */
    // Back button
    let _BackButtonNode = null;
    if (_history.location.pathname.split('/').length - 1 > 1) {
        _BackButtonNode = (
            <IconButton onClick={_onBackClick} className={styles.backButton}>
                <ChevronLeftIcon
                    color="secondary"
                    className={styles.backButtonIcon}
                />
            </IconButton>
        );
    }

    // Left: title or input
    let _TitleNode = null;
    if (props.title) {
        _TitleNode = <h1 className={styles.headerTitle}>{props.title}</h1>;
    } else if (props.textFieldProps) {
        _TitleNode = (
            <div
                className={styles.titleInputWrapper}
                style={{width: _titleWidth}}>
                <TextField
                    classes={{root: styles.titleInput}}
                    fullWidth
                    {...props.textFieldProps}
                />
            </div>
        );
    }

    // Title adornment
    let _TitleAdornmentNode = null;
    if (props.titleAdornment) {
        _TitleAdornmentNode = (
            <div className={styles.titleAdornment}>{props.titleAdornment}</div>
        );
    }

    // Buttons on the right
    let _RightNode = null;
    if (props.rightButton) {
        _RightNode = (
            <div className={styles.right}>
                {
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={props.rightButton.loading}
                        onClick={props.rightButton.action}>
                        {props.rightButton.loading
                            ? 'Saving'
                            : props.rightButton.cta}
                    </Button>
                }
            </div>
        );
    }

    return (
        <div className={styles.header}>
            <div className={styles.left}>
                {_BackButtonNode}
                {_TitleNode}

                {_TitleAdornmentNode}
            </div>
            {_RightNode}
        </div>
    );
};

export default Header;
