import React from 'react';
import cn from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './loader.module.css';

interface LoaderProps {
    overlay?: boolean;
}

const Loader = (props: LoaderProps): JSX.Element => {
    const _containerClass = cn(styles.container, {
        [styles.containerFixed]: props.overlay,
    });
    return (
        <div className={_containerClass}>
            <CircularProgress color="secondary" />
        </div>
    );
};

export default Loader;
