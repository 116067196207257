import React, {createContext, useState} from 'react';

import {TextFieldProps} from '@material-ui/core/TextField';

import {
    ModalProps,
    ModalConfirmProps,
    ModalPromptProps,
    GenericModalProps,
    ModalResult,
    ModalAPI,
    ModalProviderProps,
    ModalState,
} from 'types/modal.types';
import Modal from 'components/modal/modal.view';

const _initialState: ModalState = {
    component: null,
    isOpened: false,
    message: '',
    title: '',
    buttons: {
        confirm: {
            cta: 'Continue',
            onPress: null,
        },
        cancel: {
            cta: 'Cancel',
            onPress: null,
        },
    },
};

export const modalContext = createContext<ModalAPI>({} as ModalAPI);

const {Provider} = modalContext;

const _defaultTextInputProps: TextFieldProps = {
    margin: 'dense',
    variant: 'outlined',
};

const ModalProvider = (props: ModalProviderProps): JSX.Element => {
    const [_modalState, _setModalState] = useState<ModalState>(_initialState);
    const [_promiseData, _setPromiseData] = useState<any>(null);

    // Notice modal
    // Title + Description + Confirm button
    function _notice(modalProps: GenericModalProps) {
        return _showModalHandler(modalProps);
    }

    // Title + Description + Confirm button + Cancel button
    function _confirm(modalProps: ModalConfirmProps) {
        const _modalProps: ModalProps = {
            ...modalProps,
            // Add cancel button to props
            cancelCta:
                modalProps.cancelCta || _initialState.buttons.cancel?.cta,
        };

        return _showModalHandler(_modalProps);
    }

    // Confirm modal
    // Title + Description + Red confirm button + Cancel button
    function _confirmDanger(modalProps: ModalConfirmProps) {
        const _modalProps: ModalProps = {
            ...modalProps,
            // Add cancel button to props
            cancelCta:
                modalProps.cancelCta || _initialState.buttons.cancel?.cta,

            // Show a red confirm button
        };

        return _showModalHandler(_modalProps);
    }

    // Prompt modal
    // Title + Description + Text Input + Confirm button + Cancel button
    function _prompt(modalProps: ModalPromptProps) {
        const _textInputConfig = modalProps.textInputConfig || {};
        const _modalProps: ModalProps = {
            ...modalProps,
            // Add cancel button to props
            cancelCta:
                modalProps.cancelCta || _initialState.buttons.cancel?.cta,

            // Add textInputConfig and default text valuee to props
            textInputConfig: _textInputConfig,
            textInputValue: modalProps.textInputValue,
        };

        return _showModalHandler(_modalProps);
    }

    // Component modal
    // Title + Description + Component + Confirm button + Cancel button
    function _component(modalProps: ModalPromptProps) {
        const _textInputConfig = modalProps.textInputConfig || {};
        const _modalProps: ModalProps = {
            ...modalProps,
            // Add cancel button to props
            cancelCta:
                modalProps.cancelCta || _initialState.buttons.cancel?.cta,
        };

        return _showModalHandler(_modalProps);
    }

    // Handler used by all methods
    function _showModalHandler({
        title,
        message,
        component,
        confirmCta = _initialState.buttons.confirm.cta,
        cancelCta,
        textInputConfig,
        textInputValue,
    }: ModalProps): Promise<ModalResult> {
        return new Promise(function (resolve) {
            const _newState: ModalState = {
                component: component || null,
                isOpened: true,
                title,
                message,
                buttons: {
                    confirm: {
                        cta: confirmCta || _initialState.buttons.confirm.cta,
                        onPress: function (value: string | null) {
                            _close();
                            resolve({cancelled: false, success: true, value});
                        },
                    },
                    cancel: null,
                },
            };

            // Show cancel button if the cancelCta prop is passed
            if (cancelCta) {
                _newState.buttons.cancel = {
                    cta: cancelCta,
                    onPress: function () {
                        _close();
                        resolve({success: false, cancelled: true, value: null});
                    },
                };
            }

            // Show text input if textInputConfig is passed
            if (textInputConfig) {
                _newState.textInputConfig = {
                    ...textInputConfig,
                    ..._defaultTextInputProps,
                };
                _newState.textInputValue = textInputValue || '';
            }
            _setModalState(_newState);
        });
    }

    // Reusable close method resetting the state
    function _close() {
        _setModalState(_initialState);
        _setPromiseData(null);
    }

    // Set data to return in promise
    function _onSetPromiseData(data: any) {
        _setPromiseData(data);
    }

    // Pass all methods to the provider so we can call different types of modals
    const _providerValues: ModalAPI = {
        component: _component,
        confirm: _confirm,
        confirmDanger: _confirmDanger,
        notice: _notice,
        prompt: _prompt,
        setPromiseData: _onSetPromiseData,
    };

    return (
        <>
            <Provider value={_providerValues}>{props.children}</Provider>
            <Modal {..._modalState} close={_close} promiseData={_promiseData} />
        </>
    );
};

export default ModalProvider;
