import React, {createContext, useEffect, useRef, useState} from 'react';

import {getAllActivities} from 'api/activities.api';
import {ACTIVITY} from 'types/activity.types';

export interface ActivitiesAPI {
    all: ACTIVITY[];
    get: () => Promise<null | (() => void)>;
}
const _initialState: ActivitiesAPI = {
    all: [],
    get: async () => null,
};

type Props = {
    children: React.ReactNode;
};

export const ActivitiesContext = createContext<ActivitiesAPI>(_initialState);

const ActivitiesProvider = (props: Props): JSX.Element => {
    /* Hooks n State */
    const [_activities, _setActivities] = useState<ACTIVITY[]>([]);
    const _subscriptionRef = useRef<(() => void) | null>(null);

    /* Handlers */
    function _onActivitiesSnapshot(activities: ACTIVITY[]) {
        _setActivities(activities);
    }

    async function _getActivities() {
        const _unsubscribe = await getAllActivities(_onActivitiesSnapshot);

        _subscriptionRef.current = _unsubscribe;
        return _unsubscribe;
    }

    /* Render */
    const _value: ActivitiesAPI = {
        all: _activities,
        get: _getActivities,
    };
    return (
        <ActivitiesContext.Provider value={_value}>
            {props.children}
        </ActivitiesContext.Provider>
    );
};

export default ActivitiesProvider;
