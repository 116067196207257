import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import useExperts from 'hooks/use-experts.hook';
import {EXPERT} from 'types/experts.types';

import styles from './experts-picker-content.module.css';

interface ExpertsPickerContentProps {
    selected: EXPERT[];
    onChange: (experts: EXPERT[]) => void;
}

const ExpertsPickerContent = (
    props: ExpertsPickerContentProps,
): JSX.Element => {
    /* Hooks n State */
    const [_selectedExperts, _setSelectedExperts] = useState<EXPERT[]>(
        props.selected,
    );
    const _experts = useExperts().all;

    /* Handlers */
    function _onItemClick(expert: EXPERT) {
        const _wasSelected = !!_selectedExperts.find(
            (item) => item.id === expert.id,
        );

        let _updatedExperts = [..._selectedExperts];

        if (_wasSelected) {
            _updatedExperts = _updatedExperts.filter(
                (item) => item.id !== expert.id,
            );
        } else {
            _updatedExperts.push(expert);
        }

        _setSelectedExperts(_updatedExperts);
        props.onChange(_updatedExperts);
    }

    /* Render */
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.experts}>
                    <div className={styles.expertsScroll}>
                        {_experts.map((expert) => {
                            const _isSelected = !!_selectedExperts.find(
                                (item) => item.id === expert.id,
                            );
                            return (
                                <div className={styles.expert} key={expert.id}>
                                    <Button
                                        fullWidth
                                        endIcon={
                                            _isSelected ? (
                                                <CheckCircleIcon />
                                            ) : undefined
                                        }
                                        variant={
                                            _isSelected
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        color="secondary"
                                        onClick={() => _onItemClick(expert)}>
                                        <span className={styles.expertContent}>
                                            <span
                                                className={styles.expertTitle}>
                                                {`${expert.firstName} ${expert.lastName}`}
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpertsPickerContent;
