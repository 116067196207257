import {
    FIRESTORE_APP_SCREEN,
    FIRESTORE_CONTENT_BLOCK,
    APP_SCREEN,
    BLOCK_TYPE,
    BLOCK_TYPE_TRANSLATIONS,
    CONTENT_BLOCK,
} from 'types/app-screen.types';

const typeTranslations: BLOCK_TYPE_TRANSLATIONS = {
    gallery_carousel: 'Content Carousel',
    programmes_carousel: 'Programs Carousel',
    upsell: 'Upsell block',
    experts: 'Experts block',
    unsecified: '',
};

export const fireStoreObjectToScreen = (
    screen: FIRESTORE_APP_SCREEN,
    id: string,
): APP_SCREEN => {
    const _data: APP_SCREEN = {
        ...screen,
        id,
        createdAt: screen.createdAt.toDate(),
        lastSaveDate: screen.lastSaveDate ? screen.lastSaveDate.toDate() : null,
        lastSyncDate: screen.lastSyncDate ? screen.lastSyncDate.toDate() : null,
    };

    return _data;
};
export const firestoreObjectToBlock = (
    block: FIRESTORE_CONTENT_BLOCK,
): CONTENT_BLOCK => {
    const {content, ...otherBlockProps} = block;

    let _buttonProps = null;
    if (content?.button) {
        _buttonProps = {
            buttonLabel: content.button.label,
            buttonStyle: content.button.style,
            buttonUrl: content.button.url.url,
            buttonPremium: content.button.url.premium,
        };
    }
    return {
        ...otherBlockProps,
        ...content,
        ..._buttonProps,
        createdAt: block.createdAt ? block.createdAt.toDate() : null,
        lastSaveDate: block.lastSaveDate ? block.lastSaveDate.toDate() : null,
        lastSyncDate: block.lastSyncDate ? block.lastSyncDate.toDate() : null,
        startDate: block.startDate ? block.startDate.toDate() : null,
        endDate: block.endDate ? block.endDate.toDate() : null,
    };
};

export const firestoreObjectToBlocks = (
    blocks: FIRESTORE_CONTENT_BLOCK[],
): CONTENT_BLOCK[] => {
    const _blocks: CONTENT_BLOCK[] = [];
    blocks.forEach((block) => {
        const _newBlock = firestoreObjectToBlock(block);
        _blocks.push(_newBlock);
    });
    return _blocks;
};

export const translateBlockType = (type: string): string => {
    if (typeTranslations[type]) {
        return typeTranslations[type];
    }
    return '';
};

export const getScreenUrl = (screen: string): string => {
    return screen.toLowerCase();
};
