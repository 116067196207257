import firebase from 'firebase/app';
import {
    KAJABI_USER,
    KAJABI_USER_CONVERSATIONS,
} from 'types/kajabi-course.types';

/* Get all tags */
export async function getAllKajabiUsers(): Promise<KAJABI_USER[]> {
    try {
         const _cloudHandler = firebase
            .functions()
            .httpsCallable('getKajabiUserWithMessageCount');

        const response = await _cloudHandler();
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

export async function getUserKajabiConversationAsCSV(
    userId: string,
): Promise<string> {
    try {
        const _cloudHandler = firebase
           .functions()
           .httpsCallable('getChatbotConversationAsCSVString')

       const response = await _cloudHandler({userId});
       return response.data;
   } catch (error) {
       throw new Error(error);
   }
}
