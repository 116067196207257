import React from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {Button, Theme} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.contrastText,
            textTransform: 'none',
        },
    });

const StyledSignInButton = withStyles(styles)(Button);
export default StyledSignInButton;
