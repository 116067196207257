import React, {useContext} from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import cn from 'classnames';

import {ProtectedRoute, LoggedOutRoute} from 'components';
import Sidebar from 'components/sidebar/sidebar';
import Landing from 'pages/landing/landing';
import {UserContext, AUTH_STATE} from 'providers/user.provider';

import styles from './main-router.module.css';
import routeconfig, {RouteGroup, SidebarRoute} from './router.config';

const MainRouter: React.FC = () => {
    const _context = useContext(UserContext);
    const _contentClasses = cn(styles.content, {
        [styles.contentWithSidebar]:
            _context.state === AUTH_STATE.AUTHENTICATED,
    });

    // To do - don't redirect back to '/' then content every time refresh
    return (
        <Router>
            <div className={styles.container}>
                <Sidebar />
                <div className={_contentClasses}>
                    <Switch>
                        {routeconfig.map((item: RouteGroup) =>
                            item.routes.map(
                                (route: SidebarRoute, i: number) => (
                                    <ProtectedRoute key={i} {...route} />
                                ),
                            ),
                        )}

                        <LoggedOutRoute path="/" component={Landing} />
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default MainRouter;
