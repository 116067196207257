import firebase from 'config/firebase';
import {TRANSCRIPT_BLOCK} from './activity-transcript.types';

// TODO: Make status more generic!
export enum ACTIVITY_STATUS {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    NOT_SYNCED = 'not_synced',
    UNPUBLISHED = 'unpublished',
}
export enum VOICE {
    FEMALE = 'f',
    MALE = 'm',
    UNSPECIFIED = '',
}
export enum CONTENT_TYPE {
    FOOD_FOR_THOUGHT = 'Food for Thought',
    GUIDED_PRACTICE = 'Guided Practice',
    ONBOARDING = 'Onboarding',
    PODCAST = 'Podcast',
    PROGRAMME_LANDING_INTRO = 'Programme Landing Intro',
    STORY = 'Story',
    UNSPECIFIED = '',
    // EXP_CO_01_MINI_PACK = 'Exp CO 01 - Mini Pack',
}
// to do - any others?
export enum CONTENT_GROUP {
    GUIDED_PRACTICE = 'Guided Practice',
}
export enum SCREEN {
    DISCOVER = 'discover',
    STORIES = 'stories',
    UNSPECIFIED = '',
}

export enum TAGS {
    BDSM = 'BDSM',
    COUPLES = 'Couples',
    DISABILITY = 'Disability',
    FANTASY = 'Fantasy',
    FEATURED = 'Featured',
    GETTING_CURIOUS = 'Getting Curious',
    HER = 'Her',
    HER_AND_HER = 'Her + Her',
    HER_AND_HER_AND_HER = 'Her + Her + Her',
    HER_AND_HIM = 'Her + Him',
    HER_AND_HIM_AND_HER = 'Her + Him + Her',
    IN_PUBLIC = 'In Public',
    PARTNERED_PLAY = 'Partnered Play',
    QUEER = 'Queer',
    SELF_PLEASURE = 'Self-Pleasure',
    SOLO_PRACTICE = 'Solo Practice',
    THREESOME = 'Threesome',
    TOYS = 'Toys',
    TRAVEL = 'Travel',
    VOYEURISM = 'Voyeurism',
}

export interface BASE_ACTIVITY {
    id: string;
    author: string;
    contentTags: string[];
    contentType: CONTENT_TYPE | null;
    downloadUrl: string;
    draft: boolean;
    durationInSeconds: number;
    hasAudio?: boolean;
    hasTranscript?: boolean;
    hasUnsyncedMedia?: boolean;
    hidden: boolean | null;
    hotness: 0 | 1 | 2 | 3 | 4 | 5 | null;
    licensed: boolean;
    premium: boolean;
    previewAudioUrl: string;
    public: boolean;
    status: ACTIVITY_STATUS;
    screen: SCREEN | '';
    shortBlurb: string;
    streamableUrl: string;
    thumbnailUrl: string;
    transcriptDurationInSeconds?: number;
    title: string;
    transcriptionUrl: string;
    voice: VOICE | null;
    voiceActor: string;
}

export interface ACTIVITY extends BASE_ACTIVITY {
    createdAt: Date | null;
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
    releaseDate: Date | null;
}

export interface FIRESTORE_ACTIVITY extends BASE_ACTIVITY {
    createdAt: firebase.firestore.Timestamp;
    lastSaveDate: firebase.firestore.Timestamp | null;
    lastSyncDate: firebase.firestore.Timestamp | null;
    releaseDate: firebase.firestore.Timestamp;
}

export interface ACTIVITY_WITH_TRANSCRIPT extends ACTIVITY {
    transcriptBlocks?: TRANSCRIPT_BLOCK[];
}

export interface ACTIVITY_TABLE_DATA {
    thumbnailUrl: string;
    title: string;
    duration: string | number;
    releaseDate: Date | null;
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
    id: string;
    status: string;
}

export interface SwitchedOnAreas {
    general: boolean;
    media: boolean;
    availability: boolean;
}

export interface BLOCK_ACTIVITY {
    id: string;
}
