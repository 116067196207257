import {ACTIVITY} from 'types/activity.types';

export function filterActivitiesByProp(
    activities: ACTIVITY[],
    prop: string,
    value: string | string[],
): ACTIVITY[] {
    let _filteredActivities = activities;

    switch (prop) {
        case 'title':
            _filteredActivities = activities.filter((activity) => {
                return activity.title.toLowerCase().startsWith(value as string);
            });
            break;

        case 'contentTags':
            if ((value as string[]).length) {
                _filteredActivities = activities.filter((activity) => {
                    return (value as string[]).some((tagID) => {
                        return activity.contentTags.includes(tagID);
                    });
                });
            } else {
                return activities;
            }
            break;

        default:
            return activities;
            break;
    }
    return _filteredActivities;
}
