import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';

import StatusChip from 'components/status-chip/status-chip';
import {PROGRAM} from 'types/programs.types';

import styles from './other-program-versions.module.css';

interface OtherProgramVersionsProps {
    programs: PROGRAM[];
}

const OtherProgramVersions = (
    props: OtherProgramVersionsProps,
): JSX.Element | null => {
    if (!props.programs.length) return null;

    return (
        <div className={styles.container}>
            <div className={styles.title}>Other versions</div>
            <div className={styles.list}>
                {props.programs.map((program) => {
                    const _itemClass = cn(styles.item, {
                        [styles.itemDeprecated]: !program.latest_version,
                    });

                    let _RightIcon = null;
                    if (program.latest_version) {
                        _RightIcon = <CheckCircleIcon fontSize="small" />;
                    } else {
                        _RightIcon = <ArrowForwardIcon fontSize="small" />;
                    }

                    let _Image = null;
                    if (program.backgroundUrl) {
                        _Image = <img src={program.backgroundUrl} />;
                    } else {
                        _Image = (
                            <div className={styles.itemNoImage}>
                                <BrokenImageIcon />
                            </div>
                        );
                    }
                    return (
                        <div className={_itemClass} key={program.id}>
                            <div className={styles.itemThumb}>
                                {_Image}
                                <div className={styles.itemStatus}>
                                    <StatusChip
                                        minimal
                                        status={program.status}
                                        releaseDate={program.releaseDate}
                                        lastSaveDate={program.lastSaveDate}
                                        lastSyncDate={program.lastSyncDate}
                                    />
                                </div>
                            </div>
                            <div className={styles.itemText}>
                                <div className={styles.itemVersion}>
                                    {'V' + program.version}
                                </div>
                                <div className={styles.itemTitle}>
                                    {program.title}
                                </div>
                            </div>
                            {_RightIcon}

                            <Link
                                className={styles.itemLink}
                                key={program.id}
                                to={'/programs/' + program.id}
                                target="_blank"></Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OtherProgramVersions;
