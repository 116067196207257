import React from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {Headset} from '@material-ui/icons';
import {Theme} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            border: 0,
            margin: 0,
            padding: 0,
            color: theme.palette.primary.main,
            width: '6vw',
            height: '6vw',
        },
    });
const StyleNoAudioIcon = withStyles(styles)(Headset);
export default StyleNoAudioIcon;
