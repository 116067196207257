import React from 'react';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    Cell,
} from 'react-table';
import {useHistory} from 'react-router-dom';

import BrokenImageIcon from '@material-ui/icons/BrokenImage';

import columns from 'constants/column-headers.constants';
import {
    DefaultColumnFilter,
    GlobalFilter,
    SortingIndicator,
    NoImage,
} from 'components';
import StatusChip from 'components/status-chip/status-chip';

import styles from './table.module.css';

interface Props {
    data: any;
}
// To do - in the example I have, they use HeaderGroup<T> but I think not fully understanding
// https://github.com/ggascoigne/react-table-example/blob/master/src/Table/Table.tsx

const Table: React.FC<Props> = (props: Props) => {
    const {data} = props;
    const history = useHistory();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: data,
            defaultColumn: {Filter: DefaultColumnFilter},
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
    );

    return (
        <div>
            <div className={styles.search}>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>

            <table
                {...getTableProps()}
                className={styles.mainTable}
                cellSpacing={0}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`header-group-${i}`}>
                            {headerGroup.headers.map((column) => {
                                const _headerProps = column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                );

                                if (column.id === 'status') return null;

                                return (
                                    // to do - when I assign a key here typescript says that it's already been assigned
                                    <th
                                        {..._headerProps}
                                        key={_headerProps.key}>
                                        <div className={styles.columnTitle}>
                                            {column.render('Header')}
                                            <SortingIndicator column={column} />
                                        </div>
                                        {/* If we want to bring back the filtering by column */}
                                        {/* <div>
                                            {column.id !== 'col1' && <Filter column={column} />}
                                        </div> */}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any) => {
                        prepareRow(row);

                        return (
                            <tr
                                key={row.id}
                                className={styles.row}
                                {...row.getRowProps()}
                                onClick={(event) => {
                                    if (event.metaKey || event.ctrlKey) {
                                        window.open(
                                            `/audio-content/${row.original.id}`,
                                            '_blank',
                                        );
                                    } else {
                                        history.push(
                                            `/audio-content/${row.original.id}`,
                                        );
                                    }
                                }}>
                                {row.cells.map((cell: Cell) => {
                                    const _cellProps = cell.getCellProps();

                                    if (cell.column.id === 'status') {
                                        return null;
                                    }

                                    return (
                                        <td
                                            {..._cellProps}
                                            key={_cellProps.key}
                                            // To do - how to do dynamic styling in css modules
                                            className={`${styles.cell} ${
                                                cell.column.id === 'title' &&
                                                styles.cellBold
                                            }`}>
                                            {cell?.column?.id ===
                                            'thumbnailUrl' ? (
                                                <span
                                                    className={
                                                        styles.thumbnailWrapper
                                                    }>
                                                    {cell.row.values
                                                        .thumbnailUrl ? (
                                                        <img
                                                            src={
                                                                cell.row.values
                                                                    .thumbnailUrl
                                                            }
                                                            className={
                                                                styles.thumbnail
                                                            }
                                                            alt={
                                                                cell.row.values
                                                                    .thumbnailUrl
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.noImage
                                                            }>
                                                            <BrokenImageIcon fontSize="large" />
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            styles.status
                                                        }>
                                                        <StatusChip
                                                            status={
                                                                cell.row.values
                                                                    .status
                                                            }
                                                            releaseDate={
                                                                cell.row.values
                                                                    .releaseDate
                                                            }
                                                        />
                                                    </div>
                                                </span>
                                            ) : (
                                                cell.render('Cell')
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
export default Table;
