import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';

import Loader from 'components/loader/loader';
import {UserContext, AUTH_STATE} from 'providers/user.provider';

interface Props {
    path: string;
    component: React.ElementType;
    showInSidebar: boolean;
}

const ProtectedRoute = (props: Props): JSX.Element => {
    const _userContext = useContext(UserContext);

    return (
        <Route
            exact={props.showInSidebar}
            path={props.path}
            render={() => {
                switch (_userContext.state) {
                    case AUTH_STATE.AUTHENTICATED:
                        return <props.component />;
                    case AUTH_STATE.NOT_AUTHENTICATED:
                        return (
                            <Redirect
                                to={{
                                    pathname: '/',
                                }}
                            />
                        );

                    default:
                        return <Loader />;
                }
            }}
        />
    );
};

export default ProtectedRoute;
