import React, {createContext, useEffect, useState} from 'react';
import firebase, {User} from 'firebase/app';

import useActivities from 'hooks/use-activities.hooks';
import usePrograms from 'hooks/use-programs.hooks';
import useTags from 'hooks/use-tags.hooks';
import useExperts from 'hooks/use-experts.hook';
import {isFerlyMember} from 'helpers/user.helper';

export enum AUTH_STATE {
    BOOTING,
    AUTHENTICATED,
    NOT_AUTHENTICATED,
}

interface USER_STATE {
    state: AUTH_STATE;
    user: User | null;
}

const _initialState: USER_STATE = {
    state: AUTH_STATE.BOOTING,
    user: null,
};

export const UserContext = createContext(_initialState);

type Props = {
    children: React.ReactNode;
};

const UserProvider = (props: Props): JSX.Element => {
    const [_userState, _setUserState] = useState<USER_STATE>(_initialState);
    const _sessions = useActivities();
    const _tags = useTags();
    const _programs = usePrograms();
    const _experts = useExperts();

    useEffect(() => {
        const _cleanUp = firebase.auth().onAuthStateChanged(async (user) => {
            if (user && isFerlyMember(user)) {
                await _sessions.get();
                await _tags.get();
                await _programs.get();
                await _experts.get();

                if (process.env.REACT_APP_DEBUG) {
                    firebase
                        .functions()
                        .useFunctionsEmulator('http://localhost:5001');
                }

                _setUserState({
                    state: AUTH_STATE.AUTHENTICATED,
                    user,
                });
            } else {
                if (user) {
                    firebase.auth().signOut();
                }

                _setUserState({
                    state: AUTH_STATE.NOT_AUTHENTICATED,
                    user: null,
                });
            }
        });
        return () => {
            _cleanUp();
        };
    }, []);

    // Render
    return (
        <UserContext.Provider value={_userState}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;
