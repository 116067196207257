import firebase from 'firebase/app';
import 'firebase/storage';
import {
    FILE_LOCATION,
    TEST_FILE_LOCATION,
} from 'constants/file-locations.constants';

export enum FileName {
    downloadUrl = 'audio.mp3',
    thumbnailUrl = 'thumbnail.png',
}

export interface FileToUpload {
    file: File;
    name: FileName;
    property: MediaUrlProperty;
}

export interface FilesMap {
    [property: string]: FileToUpload;
}

export type MediaUrlProperty = 'downloadUrl' | 'thumbnailUrl';

export interface DownloadUrl {
    property: MediaUrlProperty;
    url: string;
}

export interface FileMetadata {
    deprecatedBucket?: boolean;
    size: number;
}

const fileLocation = process.env.REACT_APP_IS_TEST_COLLECTION
    ? TEST_FILE_LOCATION
    : FILE_LOCATION;

export const uploadFile = async (
    file: File,
    fileName: string,
    id: string,
): Promise<firebase.storage.UploadTask> => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageStore = storageRef.child(`${fileLocation}/${id}/${fileName}`);
    const uploadTask = await imageStore.put(file);
    return uploadTask;
};

const _uploadFileAndGetUrl = async (
    id: string,
    file: FileToUpload,
): Promise<DownloadUrl> => {
    const _uploadTask = await uploadFile(file.file, file.name, id);
    const _url = await _uploadTask.ref.getDownloadURL();

    return {
        property: file.property,
        url: _url,
    };
};

export const uploadAllFiles = async (
    id: string,
    files: FileToUpload[],
): Promise<DownloadUrl[]> => {
    try {
        const _promises = [];

        for (const _file of files) {
            _promises.push(_uploadFileAndGetUrl(id, _file));
        }

        return Promise.all(_promises);
    } catch (error) {
        throw new Error(error);
    }
};

export const getFileInfoByUrl = async (url: string): Promise<FileMetadata> => {
    try {
        if (url.indexOf('leika-audio-content') > -1) {
            return {
                deprecatedBucket: true,
                size: -1,
            };
        }
        const _lastSlashIndex = url.lastIndexOf('/');
        const _urlWithoutSlashes = url.substring(_lastSlashIndex + 1);

        const _fileLocation = _urlWithoutSlashes.split('?')[0];

        const _storage = firebase.storage();
        const _storageRef = _storage.ref();

        // Audio only for now

        const _fileRef = _storageRef.child(decodeURIComponent(_fileLocation));
        const _metadata = await _fileRef.getMetadata();

        return {
            deprecatedBucket: false,
            size: _metadata.size,
        };
    } catch (error) {
        throw new Error(error);
    }
};
