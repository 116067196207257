import {FIRESTORE_TAG, TAG} from 'types/tags.types';

export const firestoreObjectToTag = (tag: FIRESTORE_TAG, id: string): TAG => {
    const _data: TAG = {
        id,
        label: tag.label,
        lastSaveDate: tag.lastSaveDate ? tag.lastSaveDate.toDate() : null,
        lastSyncDate: tag.lastSyncDate ? tag.lastSyncDate.toDate() : null,
    };

    return _data;
};
