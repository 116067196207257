import {useContext} from 'react';

import {modalContext} from 'providers/modal/modal.provider';
import {ModalAPI} from 'types/modal.types';

function useModal(): ModalAPI {
    const _modal = useContext(modalContext);

    return _modal;
}

export default useModal;
