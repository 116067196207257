import {useContext} from 'react';

import {ExpertsApi} from 'providers/experts/experts.provider';
import {ExpertsContext} from '../providers/experts/experts.provider';

function useExperts(): ExpertsApi {
    const _experts = useContext(ExpertsContext);

    return _experts;
}

export default useExperts;
