import {useContext} from 'react';
import {
    CourseChatsAPI,
    CourseChatsContext,
} from 'providers/kajabi-course/kajabi-course.provider';

function useCourseChats(): CourseChatsAPI {
    const _course = useContext(CourseChatsContext);

    return _course;
}

export default useCourseChats;
