export function underScoresToSpaces(string: string): string {
    if (!string) return '';
    return string.replace(/_/g, ' ');
}

export function titleCase(string: string): string {
    return string
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function lowerCase(string: string): string {
    return string
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
        .join(' ');
}
