import React from 'react';
import cn from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {IMAGE_MAP} from '../../../constants/transcript.constants';
import TranscriptPreviewImage from '../../transcript-preview/transcript-preview-image/transcript-preview-image.view';

import styles from './transcript-image-picker.module.css';

interface TranscriptImagePickerProps {
    value?: number;
    onChange: (value: number) => void;
}

function TranscriptImagePicker(props: TranscriptImagePickerProps): JSX.Element {
    return (
        <div className={styles.container}>
            {IMAGE_MAP.map((config, index) => {
                const _version = index + 1;
                const _isActive = _version === props.value;

                const _buttonClasses = cn(styles.button, {
                    [styles.buttonActive]: _isActive,
                });
                return (
                    <button
                        className={_buttonClasses}
                        key={_version}
                        onClick={() => props.onChange(_version)}>
                        <TranscriptPreviewImage version={_version} />
                        {_isActive && (
                            <CheckCircleIcon
                                color="primary"
                                className={styles.buttonCheck}
                            />
                        )}
                    </button>
                );
            })}
        </div>
    );
}

export default TranscriptImagePicker;
