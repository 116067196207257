import React from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {IconButton, Theme} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.secondary.contrastText,
            },
            marginLeft: '5%',
        },
    });

const StyledCreateContentButton = withStyles(styles)(IconButton);
export default StyledCreateContentButton;
