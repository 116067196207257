import firebase from 'firebase/app';
import {EXPERT} from 'types/experts.types';

/* Get all experts */
export async function subscribeToExperts(
    handler: (experts: EXPERT[]) => void,
): Promise<() => void> {
    try {
        const _db = firebase.firestore();

        const _subscription = _db
            .collection('experts')
            .orderBy('firstName')
            .onSnapshot((snapshot) => {
                const _experts: EXPERT[] = [];
                snapshot.docs.forEach((expert) => {
                    const _expertData = expert.data() as EXPERT;
                    const {id} = expert;
                    _experts.push({..._expertData, id});
                });
                handler(_experts);
            });

        return _subscription;
    } catch (error) {
        throw new Error(error);
    }
}
