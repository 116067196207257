import React, {createContext, useRef, useState, useEffect} from 'react';

import {subscribeToPrograms} from 'api/programs.api';
import {PROGRAM} from 'types/programs.types';

export interface ProgramsAPI {
    all: PROGRAM[];
    allByKey: PROGRAM[];
    get: () => Promise<null | (() => void)>;
}

const _initialState: ProgramsAPI = {
    all: [],
    allByKey: [],
    get: async () => null,
};

type Props = {
    children: React.ReactNode;
};

export const ProgramsContext = createContext<ProgramsAPI>(_initialState);

const ProgramsProvider = (props: Props): JSX.Element => {
    /* Hooks n State */
    const [_programs, _setPrograms] = useState<PROGRAM[]>([]);
    const [_programsByKey, _setProgramsByKey] = useState<PROGRAM[]>([]);

    const _subscriptionRef = useRef<(() => void) | null>(null);

    useEffect(() => {
        const _tmpPrograms: {[key: string]: PROGRAM} = {};

        _programs.forEach((item) => {
            if (!_tmpPrograms[item.key]) {
                _tmpPrograms[item.key] = item;
            } else {
                // check if this version is larger than the one we have now
                if (item.latest_version) {
                    _tmpPrograms[item.key] = item;
                }
            }
        });

        _setProgramsByKey(Object.values(_tmpPrograms));
    }, [_programs]);

    /* Handlers */
    function _onProgramsSnapshot(programs: PROGRAM[]) {
        _setPrograms(programs);
    }

    async function _getPrograms() {
        const _unsubscribe = await subscribeToPrograms(_onProgramsSnapshot);

        _subscriptionRef.current = _unsubscribe;

        return _unsubscribe;
    }

    /* Render */
    const _value: ProgramsAPI = {
        all: _programs,
        allByKey: _programsByKey,

        get: _getPrograms,
    };

    return (
        <ProgramsContext.Provider value={_value}>
            {props.children}
        </ProgramsContext.Provider>
    );
};

export default ProgramsProvider;
