import React from 'react';
import {HashLink} from 'react-router-hash-link';

import {updateTag} from 'api/tags.api';
import ActivitiesPickerItem from 'components/activities-picker/activities-picker-item.view';
import ErrorMessage from 'components/error-message/error-message.view';
import PageSection from 'components/page-section/page-section.view';
import useModal from 'hooks/use-modal';
import useSnackbar from 'hooks/use-snackbar.hooks';
import {getScreenUrl} from 'helpers/app-screen.helper';
import {TAG_WITH_ACTIVITIES} from 'types/tags.types';

import styles from './content-tag-info.module.css';

interface ContentTagInfoProps {
    data: TAG_WITH_ACTIVITIES;
}

const ContentTagInfo = (props: ContentTagInfoProps): JSX.Element => {
    /* Hooks n State */
    const _modal = useModal();
    const _snackbar = useSnackbar();

    /* Handlers */
    async function _renameTag() {
        const _modalResult = await _modal.prompt({
            title: 'Rename tag "' + props.data.tag.label + '"',
            message: "What would you like this tag's new name to be?",
        });
        if (_modalResult.success && _modalResult.value) {
            await updateTag(props.data.tag.id, {
                label: _modalResult.value,
            });
            _snackbar.success(
                'Successfully renamed tag to "' + _modalResult.value + '"',
            );
        }
    }
    function _deleteTag() {
        // console.log('Delete tag: ', props.data.tag.label);
    }
    /* Render */
    const _sectionActions = [
        {
            action: _renameTag,
            key: 'rename-' + props.data.tag.id,
            label: 'Rename tag',
        },
        // {
        //     action: _deleteTag,
        //     key: 'delete-' + props.data.tag.id,
        //     label: 'Delete tag',
        // },
    ];

    let _ActivitiesContent = null;
    if (props.data.activities.length) {
        _ActivitiesContent = props.data.activities.map((activity, index) => {
            return (
                <div
                    className={styles.gridItem}
                    key={props.data.tag.id + '-' + activity.id}>
                    <ActivitiesPickerItem
                        activity={activity}
                        index={index}
                        totalCount={props.data.activities.length}
                        link
                    />
                </div>
            );
        });
    } else {
        _ActivitiesContent = (
            <ErrorMessage message="This tag has no activities attached to it." />
        );
    }

    let _Blocks = null;
    if (props.data.blocks.length) {
        _Blocks = (
            <div className={styles.block}>
                <div className={styles.blockTitle}>
                    {'Content blocks (Exercises / Relax / Playground)'}
                </div>
                <div className={styles.blockContent}>
                    <div className={styles.container}>
                        <div className={styles.grid}>
                            {props.data.blocks.map((block) => {
                                return (
                                    <HashLink
                                        className={styles.blockItem}
                                        to={
                                            '/' +
                                            getScreenUrl(
                                                block.screenName || '',
                                            ) +
                                            '#' +
                                            block.id
                                        }
                                        key={
                                            props.data.tag.id + '-' + block.id
                                        }>
                                        <div className={styles.blockItemScreen}>
                                            {block.screenName + ' screen'}
                                        </div>
                                        <div className={styles.blockItemTitle}>
                                            {block.title}
                                        </div>
                                    </HashLink>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <PageSection
            actions={_sectionActions}
            title={props.data.tag.label}
            description={props.data.tag.id}>
            <div className={styles.container}>
                <div className={styles.block}>
                    <div className={styles.blockTitle}>{'Audio content'}</div>
                    <div className={styles.blockContent}>
                        <div className={styles.container}>
                            <div className={styles.grid}>
                                {_ActivitiesContent}
                            </div>
                        </div>
                    </div>
                </div>
                {_Blocks}
            </div>
        </PageSection>
    );
};

export default ContentTagInfo;
