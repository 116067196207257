import React, {MouseEvent, useContext, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import firebase from 'config/firebase';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Avatar from '@material-ui/core/Avatar';

import {isRouteActive} from 'helpers/route.helper';
import {UserContext, AUTH_STATE} from 'providers/user.provider';
import routeConfig, {SidebarRoute, RouteGroup} from 'routes/router.config';

import IMG_LOGO from 'assets/images/logo/logo.svg';

import styles from './sidebar.module.css';

interface SidebarRouteProps {
    route: SidebarRoute;
    currentRoute: string;
}

const SidebarLink = ({
    route,
    currentRoute,
}: SidebarRouteProps): JSX.Element | null => {
    if (!route.showInSidebar) return null;

    const _isActive = isRouteActive(route.path, currentRoute);
    return (
        <ListItem
            classes={{selected: styles.linkActive, root: styles.link}}
            selected={_isActive}
            component={NavLink}
            to={route.path}>
            <ListItemIcon classes={{root: styles.linkIcon}}>
                <route.icon
                    color={_isActive ? 'primary' : 'secondary'}
                    fontSize={'small'}
                />
            </ListItemIcon>
            <ListItemText
                primary={route.label}
                classes={{root: styles.linkText, primary: styles.linkTextBody}}
            />
        </ListItem>
    );
};

const Sidebar = (): JSX.Element | null => {
    const _location = useLocation();
    const _userContext = useContext(UserContext);

    // Return nothing if user is not logged in
    const [_activeMenu, _setActiveMenu] = useState<Element | null>(null);

    if (_userContext?.state !== AUTH_STATE.AUTHENTICATED) return null;

    // Handlers
    function _onAvatarClick(event: MouseEvent): void {
        _setActiveMenu(event.currentTarget);
    }

    function _onLogoutClick(): void {
        firebase.auth().signOut();
    }

    function _userMenuClose() {
        _setActiveMenu(null);
    }

    // Render
    const _profile = _userContext.user?.providerData[0];

    const _userAvatar = _profile?.photoURL || undefined;
    const _firstLetterOfName = _profile?.displayName?.substring(0, 1);

    return (
        <Drawer variant="permanent" className={styles.drawer}>
            <div className={styles.container}>
                <div className={styles.head}>
                    <div className={styles.logo}>
                        <img src={IMG_LOGO} alt="AVERY" />
                    </div>
                </div>
                <nav className={styles.navigation}>
                    {routeConfig.map((group: RouteGroup) => {
                        return (
                            <List
                                key={group.id}
                                className={styles.list}
                                component="div"
                                subheader={
                                    group.label ? (
                                        <ListSubheader
                                            component="div"
                                            classes={{
                                                root: styles.subheader,
                                            }}>
                                            {group.label}
                                        </ListSubheader>
                                    ) : undefined
                                }>
                                {group.routes.map((route) => {
                                    return (
                                        <SidebarLink
                                            key={route.id}
                                            route={route}
                                            currentRoute={_location.pathname}
                                        />
                                    );
                                })}
                            </List>
                        );
                    })}
                </nav>
                <div className={styles.user}>
                    <button
                        aria-controls="user-menu"
                        aria-haspopup={true}
                        onClick={_onAvatarClick}
                        className={styles.userAvatarButton}>
                        <Avatar
                            alt={_firstLetterOfName}
                            classes={{root: styles.userAvatar}}
                            src={_userAvatar}>
                            {_firstLetterOfName}
                        </Avatar>
                    </button>
                    <Menu
                        id="user-menu"
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        anchorEl={_activeMenu}
                        open={_activeMenu !== null}
                        onClose={_userMenuClose}>
                        <MenuItem onClick={_onLogoutClick}>Log out</MenuItem>
                    </Menu>
                </div>
            </div>
        </Drawer>
    );
};

export default Sidebar;
