import {JTDSchemaType} from 'ajv/dist/jtd';
import {ACTIVITY, ACTIVITY_STATUS} from './activity.types';

export enum PROGRAM_SESSION_TYPES {
    ACTIVITY = 'activity',
    REFLECTION = 'reflection',
}
export enum PROGRAM_SESSION_ACTION_TYPES {
    LEARN = 'learn',
    DO = 'do',
}

export enum PROGRAM_STATUS {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    NOT_SYNCED = 'not_synced',
    UNPUBLISHED = 'unpublished',
}

export interface PROGRAM_SESSION {
    id: string;
    title: string;
    description?: string;
    question?: string;
    index: number;
    type: PROGRAM_SESSION_TYPES;
    actionType?: PROGRAM_SESSION_ACTION_TYPES;
}

interface BASE_MODULE {
    id: string;
    index: number;
    label?: string;
    title: string;
    description?: string;
    sessions: PROGRAM_SESSION[];
    premium: boolean;
}

export interface FIREBASE_MODULE extends BASE_MODULE {
    releaseDate: firebase.firestore.Timestamp;
}

export interface MODULE extends BASE_MODULE {
    releaseDate: Date;
}
export interface PROGRAM_INTRODUCTION {
    id: string;
    description: string;
}

export interface PROGRAM_SESSION_WITH_ACTIVITY extends PROGRAM_SESSION {
    activity: ACTIVITY | null;
}

export type MODULE_WITH_ACTIVITIES = Omit<MODULE, 'sessions'> & {
    sessions: PROGRAM_SESSION_WITH_ACTIVITY[];
};

export enum PROGRAM_BLOCK_TYPES {
    KEY_POINTS = 'key_points',
    PHOTO = 'photo',
    TEXT = 'text',
    QUOTE = 'quote',
    DIVIDER = 'divider',
}

export interface PROGRAM_BLOCK_STYLE {
    backgroundImage?: string;
}

export interface PROGRAM_BLOCK_CONTENT {
    title?: string;
    text?: string;
    caption?: string;
}

export interface KEYPOINT {
    title: string;
    text?: string;
}

export interface PROGRAM_BLOCK {
    sort: number;
    type: PROGRAM_BLOCK_TYPES;
    content?: PROGRAM_BLOCK_CONTENT;
    style?: PROGRAM_BLOCK_STYLE;
    keyPoints?: KEYPOINT[];
}

export interface BASE_PROGRAM {
    backgroundUrl?: string;
    blocks?: PROGRAM_BLOCK[];
    description: string;
    designedFor?: string;
    id: string;
    introduction?: PROGRAM_INTRODUCTION | null;
    key: string;
    latest_version: boolean;
    previousLatestVersion?: string | null;
    recommendedFrequency: number;
    relatedActivities?: string[];
    status: ACTIVITY_STATUS;
    title: string;
    version: number;
}

export interface FIRESTORE_PROGRAM extends BASE_PROGRAM {
    createdAt: firebase.firestore.Timestamp;
    modules: FIREBASE_MODULE[];
    lastSaveDate: firebase.firestore.Timestamp | null;
    lastSyncDate: firebase.firestore.Timestamp | null;
    releaseDate: firebase.firestore.Timestamp;
}

export interface PROGRAM extends BASE_PROGRAM {
    createdAt: Date | null;
    modules: MODULE[];
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
    releaseDate: Date | null;
}

export const EMPTY_PROGRAM: Omit<PROGRAM, 'createdAt' | 'releaseDate'> = {
    backgroundUrl: '',
    description: '',
    designedFor: '',
    id: '',
    introduction: null,
    key: '',
    lastSaveDate: null,
    lastSyncDate: null,
    latest_version: true,
    modules: [],
    recommendedFrequency: 2,
    status: ACTIVITY_STATUS.DRAFT,
    title: 'Untitled program',
    version: 0,
};

export interface PROGRAM_SOURCE {
    key: string;
    version?: number;
    sort?: number;
}

export const ProgramValidationSchema: JTDSchemaType<
    PROGRAM,
    {
        introduction: PROGRAM_INTRODUCTION;
        session: PROGRAM_SESSION;
        module: MODULE;
        block: PROGRAM_BLOCK;
        keyPoint: KEYPOINT;
        programBlockContent: PROGRAM_BLOCK_CONTENT;
        programBlockStyle: PROGRAM_BLOCK_STYLE;
    }
> = {
    definitions: {
        introduction: {
            properties: {
                id: {type: 'string'},
                description: {type: 'string'},
            },
        },
        session: {
            properties: {
                id: {type: 'string'},
                title: {type: 'string'},
                index: {type: 'uint16'},
                type: {
                    enum: [
                        PROGRAM_SESSION_TYPES.ACTIVITY,
                        PROGRAM_SESSION_TYPES.REFLECTION,
                    ],
                },
            },
            optionalProperties: {
                description: {type: 'string'},
                question: {type: 'string'},
                actionType: {
                    enum: [
                        PROGRAM_SESSION_ACTION_TYPES.DO,
                        PROGRAM_SESSION_ACTION_TYPES.LEARN,
                    ],
                },
            },
        },
        module: {
            properties: {
                id: {type: 'string'},
                index: {type: 'uint16'},
                releaseDate: {type: 'timestamp'},
                premium: {type: 'boolean'},
                sessions: {
                    elements: {
                        ref: 'session',
                    },
                },
                title: {type: 'string'},
            },
            optionalProperties: {
                label: {type: 'string'},
                description: {type: 'string'},
            },
        },
        keyPoint: {
            properties: {
                title: {type: 'string'},
            },
            optionalProperties: {
                text: {type: 'string'},
            },
        },
        programBlockContent: {
            optionalProperties: {
                title: {type: 'string'},
                text: {type: 'string'},
                caption: {type: 'string'},
            },
        },
        programBlockStyle: {
            optionalProperties: {
                backgroundImage: {type: 'string'},
            },
        },
        block: {
            properties: {
                sort: {type: 'uint16'},
                type: {
                    enum: [
                        PROGRAM_BLOCK_TYPES.DIVIDER,
                        PROGRAM_BLOCK_TYPES.KEY_POINTS,
                        PROGRAM_BLOCK_TYPES.PHOTO,
                        PROGRAM_BLOCK_TYPES.QUOTE,
                        PROGRAM_BLOCK_TYPES.TEXT,
                    ],
                },
            },
            optionalProperties: {
                content: {ref: 'programBlockContent'},
                style: {ref: 'programBlockStyle'},
                keyPoints: {
                    elements: {ref: 'keyPoint'},
                },
            },
        },
    },
    properties: {
        createdAt: {type: 'timestamp', nullable: true},
        description: {type: 'string'},
        id: {type: 'string'},
        key: {type: 'string'},
        lastSaveDate: {type: 'timestamp', nullable: true},
        lastSyncDate: {type: 'timestamp', nullable: true},
        latest_version: {type: 'boolean'},
        modules: {
            elements: {
                ref: 'module',
            },
        },
        releaseDate: {type: 'timestamp', nullable: true},
        status: {
            enum: [
                ACTIVITY_STATUS.DRAFT,
                ACTIVITY_STATUS.PUBLISHED,
                ACTIVITY_STATUS.NOT_SYNCED,
                ACTIVITY_STATUS.UNPUBLISHED,
            ],
        },
        title: {type: 'string'},
        recommendedFrequency: {type: 'uint16'},
        version: {type: 'uint16'},
    },
    optionalProperties: {
        backgroundUrl: {type: 'string'},
        blocks: {
            elements: {
                ref: 'block',
            },
        },
        designedFor: {type: 'string'},
        relatedActivities: {elements: {type: 'string'}},
        introduction: {ref: 'introduction', nullable: true},
        previousLatestVersion: {type: 'string', nullable: true},
    },
};
