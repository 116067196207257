import React, {useEffect, useState} from 'react';
import moment from 'moment';
import cn from 'classnames';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

import UpdateIcon from '@material-ui/icons/Update';

import styles from './sync-bar.module.css';

interface SyncBarProps {
    lastSaveDate: Date | null;
    lastSyncDate: Date | null;
    type: 'screen' | 'content' | 'tags';
    onSyncClick: () => void;
}

const baseTitleText = ' not synced with the live app.';

const SyncBar = (props: SyncBarProps): JSX.Element | null => {
    /* Hooks n State */
    const [_isVisible, _setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        _setIsVisible(
            props.lastSaveDate?.getTime() !== props.lastSyncDate?.getTime(),
        );
    }, [props.lastSaveDate, props.lastSyncDate]);

    /* Render */
    let _titlePrefix = null;
    switch (props.type) {
        case 'tags':
            _titlePrefix = 'Tags are';
            break;

        default:
            _titlePrefix = `This ${props.type} is`;
            break;
    }
    const _titleText = _titlePrefix + baseTitleText;
    let _lastSaveText = 'Last saved on ';
    let _lastSyncText = 'Last synced on ';

    if (props.lastSaveDate) {
        _lastSaveText += moment(props.lastSaveDate).format('D MMMM - HH:mm');
    }

    if (props.lastSyncDate) {
        _lastSyncText += moment(props.lastSyncDate).format('D MMMM - HH:mm');
    } else {
        _lastSyncText = '';
    }

    return (
        <Slide in={_isVisible} direction="up">
            <Box boxShadow={1} className={styles.container}>
                <UpdateIcon fontSize="large" />
                <div className={styles.text}>
                    <div className={styles.title}>{_titleText}</div>
                    <div className={cn(styles.saveInfo, styles.lastSave)}>
                        {_lastSaveText}
                    </div>
                    {_lastSyncText && (
                        <div className={cn(styles.saveInfo, styles.lastSync)}>
                            {_lastSyncText}
                        </div>
                    )}
                </div>
                <Button
                    color="secondary"
                    className={styles.button}
                    onClick={props.onSyncClick}
                    variant="outlined">
                    {'Sync now'}
                </Button>
            </Box>
        </Slide>
    );
};

export default SyncBar;
