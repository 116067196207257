import React, {useEffect, useState} from 'react';

import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/Error';
import LockIcon from '@material-ui/icons/Lock';

import ActivitiesPickerItem from 'components/activities-picker/activities-picker-item.view';
import {
    MODULE,
    MODULE_WITH_ACTIVITIES,
    PROGRAM_SESSION_WITH_ACTIVITY,
    PROGRAM_SESSION_TYPES,
    PROGRAM_INTRODUCTION,
} from 'types/programs.types';
import useActivities from 'hooks/use-activities.hooks';

import styles from './program-modules.module.css';
import {ACTIVITY} from 'types/activity.types';

interface ProgramModulesProps {
    introduction?: PROGRAM_INTRODUCTION | null;
    modules: MODULE[];
}

const actionTypes = {
    learn: 'Theory',
    do: 'Practice',
};

const ProgramModules = (props: ProgramModulesProps): JSX.Element => {
    /* Hooks n State */
    const [_modules, _setModules] = useState<MODULE_WITH_ACTIVITIES[]>([]);
    const [_introduction, _setIntroduction] = useState<ACTIVITY | null>(null);
    const _activities = useActivities().all;

    useEffect(() => {
        if (_activities && props.modules) {
            const _formattedModules: MODULE_WITH_ACTIVITIES[] = props.modules.map(
                (module) => {
                    const _sessions = module.sessions.map((session) => {
                        const _matchingActivity = _activities.find(
                            (activity) => activity.id === session.id,
                        );

                        return {
                            ...session,
                            activity: _matchingActivity || null,
                        } as PROGRAM_SESSION_WITH_ACTIVITY;
                    });

                    return {
                        ...module,
                        sessions: _sessions,
                    };
                },
            );

            _setModules(_formattedModules);

            if (_activities && props.introduction) {
                const _matchingIntroductionActivity = _activities.find(
                    (activity) => activity.id === props.introduction?.id,
                );

                if (_matchingIntroductionActivity) {
                    _setIntroduction(_matchingIntroductionActivity);
                }
            }
        }
    }, [_activities, props.modules, props.introduction]);

    /* Render */
    return (
        <div className={styles.modules}>
            {_introduction && (
                <div className={styles.module}>
                    <div className={styles.moduleHead}>
                        <div className={styles.moduleTitle}>
                            {'Introduction'}
                        </div>
                    </div>
                    <div className={styles.moduleDescription}>
                        {props.introduction?.description ||
                            'Missing description'}
                    </div>
                    <div className={styles.sessionsWrapper}>
                        <div className={styles.sessions}>
                            <Box boxShadow={3} className={styles.session}>
                                <div className={styles.property}>
                                    <div className={styles.propertyTitle}>
                                        ID
                                    </div>
                                    <div className={styles.propertyValue}>
                                        {_introduction.id}
                                    </div>
                                </div>
                                <div className={styles.property}>
                                    <div className={styles.propertyTitle}>
                                        {'Content'}
                                    </div>
                                    <div className={styles.propertyValue}>
                                        <ActivitiesPickerItem
                                            activity={_introduction}
                                            link
                                        />
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            )}
            {_modules &&
                _modules.map((module, index) => {
                    return (
                        <div className={styles.module} key={module.id}>
                            <div className={styles.moduleHead}>
                                <div className={styles.moduleTitle}>
                                    {module.premium && (
                                        <LockIcon
                                            classes={{root: styles.lockIcon}}
                                        />
                                    )}
                                    {'Module ' +
                                        (index + 1) +
                                        ': ' +
                                        module.title}
                                </div>
                                <div className={styles.moduleInfo}>
                                    <div className={styles.moduleInfoItem}>
                                        {module.sessions.length + ' sessions'}
                                    </div>
                                    <div className={styles.moduleInfoItem}>
                                        {'ID: ' + module.id}
                                    </div>
                                </div>
                                <div className={styles.moduleDescription}>
                                    {module.description ||
                                        'Missing description'}
                                </div>
                            </div>
                            <div className={styles.sessionsWrapper}>
                                {module.sessions.length ? (
                                    <div className={styles.sessions}>
                                        {module.sessions.map(
                                            (session, sessionIndex) => {
                                                let _actionType = 'Reflect';
                                                if (session.actionType)
                                                    _actionType =
                                                        actionTypes[
                                                            session.actionType
                                                        ];

                                                let _Content = null;
                                                if (
                                                    session.type ===
                                                    PROGRAM_SESSION_TYPES.ACTIVITY
                                                ) {
                                                    _Content = (
                                                        <div
                                                            className={
                                                                styles.property
                                                            }>
                                                            <div
                                                                className={
                                                                    styles.propertyTitle
                                                                }>
                                                                {'Content'}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.propertyValue
                                                                }>
                                                                {session.activity && (
                                                                    <ActivitiesPickerItem
                                                                        activity={
                                                                            session.activity
                                                                        }
                                                                        link
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    _Content = (
                                                        <>
                                                            <div
                                                                className={
                                                                    styles.property
                                                                }>
                                                                <div
                                                                    className={
                                                                        styles.propertyTitle
                                                                    }>
                                                                    Question
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.propertyValue
                                                                    }>
                                                                    {session.question ||
                                                                        '/'}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.property
                                                                }>
                                                                <div
                                                                    className={
                                                                        styles.propertyTitle
                                                                    }>
                                                                    Description
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.propertyValue
                                                                    }>
                                                                    {session.description ||
                                                                        '/'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                                return (
                                                    <Box
                                                        boxShadow={3}
                                                        className={
                                                            styles.session
                                                        }
                                                        key={session.id}>
                                                        <div
                                                            className={
                                                                styles.sessionHead
                                                            }>
                                                            <div
                                                                className={
                                                                    styles.propertyTitle
                                                                }>
                                                                {'Session ' +
                                                                    (sessionIndex +
                                                                        1) +
                                                                    ' - ' +
                                                                    _actionType}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.sessionTitle
                                                                }>
                                                                {session.title}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.property
                                                            }>
                                                            <div
                                                                className={
                                                                    styles.propertyTitle
                                                                }>
                                                                ID
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.propertyValue
                                                                }>
                                                                {session.id}
                                                            </div>
                                                        </div>
                                                        {_Content}
                                                    </Box>
                                                );
                                            },
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.empty}>
                                        <ErrorIcon color="error" />
                                        <div className={styles.emptyText}>
                                            {'This module has no sessions.'}
                                        </div>
                                    </div>
                                )}
                                <div className={styles.sessionsGradient}></div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default ProgramModules;
