import {useContext} from 'react';

import {
    ProgramsContext,
    ProgramsAPI,
} from 'providers/programs/programs.provider';

function usePrograms(): ProgramsAPI {
    const _programs = useContext(ProgramsContext);
    return _programs;
}

export default usePrograms;
