import React, {useRef, useState} from 'react';
import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {MenuBook, MoreVert} from '@material-ui/icons';

import {
    TRANSCRIPT_BLOCK,
    TRANSCRIPT_BLOCK_CALLOUT,
    TRANSCRIPT_BLOCK_IMAGE,
    TRANSCRIPT_BLOCK_TEXT,
    TRANSCRIPT_BLOCK_TITLE,
    TRANSCRIPT_BLOCK_TYPES,
} from '../../../types/activity-transcript.types';
import styles from './block.module.css';
import ReactMarkdown from 'react-markdown';
import TranscriptPreviewImage from '../transcript-preview-image/transcript-preview-image.view';
import useModal from '../../../hooks/use-modal';
import {useDrag, useDrop} from 'react-dnd';
import {Identifier, XYCoord} from 'dnd-core';
import {DRAG_TYPES} from '../../../types/drag-n-drop.types';

interface TranscriptPreviewBlockProps {
    block: TRANSCRIPT_BLOCK;
    onAddBlockBelowClick: (
        target: HTMLElement & EventTarget,
        id: string,
    ) => void;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    onDrag: (dragIndex: number, hoverIndex: number) => void;
    onDragStop: () => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

function TranscriptPreviewBlock(
    props: TranscriptPreviewBlockProps,
): JSX.Element {
    /* Hooks n State */
    const [_activeMenu, _setActiveMenu] = useState<HTMLElement | null>(null);
    const _modal = useModal();
    const _ref = useRef<HTMLDivElement>(null);

    /* Drag n Drop */
    const [{handlerId}, drop] = useDrop<
        DragItem,
        void,
        {handlerId: Identifier | null}
    >({
        accept: DRAG_TYPES.TRANSCRIPT_BLOCK,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!_ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.block.sort;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = _ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            props.onDrag(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        drop() {
            props.onDragStop();
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: DRAG_TYPES.TRANSCRIPT_BLOCK,
        item: () => {
            return {id: props.block.id, sort: props.block.sort};
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const _opacity = isDragging ? 0.3 : 1;
    drag(drop(_ref));

    /* Handlers */
    function _onMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        _setActiveMenu(event.currentTarget);
    }

    function _onMenuClose() {
        _setActiveMenu(null);
    }

    function _onEditClick() {
        props.onEditClick(props.block.id);
        _onMenuClose();
    }

    function _onAddBlockBelowClick(
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) {
        props.onAddBlockBelowClick(
            event.currentTarget as HTMLElement & EventTarget,
            props.block.id,
        );
        _onMenuClose();
    }

    async function _onDeleteClick() {
        const _confirmed = await _modal.confirmDanger({
            title: 'Remove this block?',
            message:
                "Removing this block will permanently deleted and can't be undone.",
            confirmCta: 'Remove block',
        });
        if (_confirmed.success) {
            props.onDeleteClick(props.block.id);
        }
        _onMenuClose();
    }

    /* Render */
    let _Content = null;
    switch (props.block.type) {
        case TRANSCRIPT_BLOCK_TYPES.TEXT:
            const _typedTextContent = props.block
                .content as TRANSCRIPT_BLOCK_TEXT;
            const _text = _typedTextContent.text.en;
            _Content = (
                <div className={styles.previewText}>
                    <ReactMarkdown>{_text}</ReactMarkdown>
                </div>
            );
            break;
        case TRANSCRIPT_BLOCK_TYPES.TITLE:
            const _typedTitleContent = props.block
                .content as TRANSCRIPT_BLOCK_TITLE;
            const _title = _typedTitleContent.text.en;
            _Content = <div className={styles.previewTitle}>{_title}</div>;
            break;

        case TRANSCRIPT_BLOCK_TYPES.CALLOUT:
            const _typedCalloutContent = props.block
                .content as TRANSCRIPT_BLOCK_CALLOUT;
            const _callout = _typedCalloutContent.text.en;
            _Content = (
                <div className={styles.previewCallout}>
                    <MenuBook
                        color="primary"
                        fontSize="small"
                        className={styles.previewCalloutIcon}
                    />
                    <div className={styles.previewCalloutMarkdown}>
                        <ReactMarkdown>{_callout}</ReactMarkdown>
                    </div>
                </div>
            );
            break;
        case TRANSCRIPT_BLOCK_TYPES.IMAGE:
            const _typedImageContent = props.block
                .content as TRANSCRIPT_BLOCK_IMAGE;
            const _image = _typedImageContent.version;
            _Content = (
                <div className={styles.previewImage}>
                    <TranscriptPreviewImage version={_image} />
                </div>
            );
            break;
        case TRANSCRIPT_BLOCK_TYPES.DIVIDER:
            _Content = <div className={styles.previewDivider} />;
            break;

        default:
            break;
    }

    return (
        <div className={styles.wrapper} ref={_ref} style={{opacity: _opacity}}>
            <div className={styles.dragHandle}>
                <DragIndicatorIcon
                    fontSize="small"
                    className={styles.dragHandleIcon}
                />
            </div>

            <div className={styles.container}>
                <div className={styles.content}>
                    {/* <div className={styles.header}>
                    <div className={styles.type}>{props.block.type}</div>
                </div> */}
                    <div className={styles.preview}>{_Content}</div>
                </div>
                <div className={styles.edit}>
                    <IconButton
                        color="secondary"
                        onClick={_onMenuClick}
                        size="small">
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id={`menu-block-${props.block.id}`}
                        anchorEl={_activeMenu}
                        open={Boolean(_activeMenu)}
                        onClose={_onMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <MenuItem onClick={_onEditClick}>
                            <ListItemIcon>
                                <EditIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Edit block" />
                        </MenuItem>
                        <MenuItem onClick={_onAddBlockBelowClick}>
                            <ListItemIcon>
                                <AddIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Add block below" />
                        </MenuItem>
                        <MenuItem onClick={_onDeleteClick}>
                            <ListItemIcon>
                                <DeleteIcon color="error" />
                            </ListItemIcon>
                            <ListItemText primary="Remove block" />
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default TranscriptPreviewBlock;
