import {useContext} from 'react';

import {TagsContext, TagsAPI} from 'providers/tags/tags.provider';

function useTags(): TagsAPI {
    const _tags = useContext(TagsContext);

    return _tags;
}

export default useTags;
