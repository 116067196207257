import React, {useState, useEffect} from 'react';
import cn from 'classnames';

import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';

import AddIcon from '@material-ui/icons/Add';

import ActivitiesPickerItem, {
    ArrowDirection,
} from 'components/activities-picker/activities-picker-item.view';
import useActivities from 'hooks/use-activities.hooks';
import {CB_ACTIVITY} from 'types/app-screen.types';
import {ACTIVITY} from 'types/activity.types';

import ActivitiesPickerDialog from './activities-picker-dialog.view';
import styles from './activities-picker.module.css';

interface ActivitiesPickerProps {
    name: string;
    value: CB_ACTIVITY[] | null;
    onChange: (activities: CB_ACTIVITY[], name: string) => void;
}

const SessionPicker = (props: ActivitiesPickerProps): JSX.Element => {
    /* Hooks n State */
    const [_isDialogOpened, _setIsDialogOpened] = useState<boolean>(false);
    const [_activitiesToDisplay, _setActivitiesToDisplay] = useState<
        ACTIVITY[]
    >([]);

    const _activities = useActivities().all;

    useEffect(() => {
        const _matchingSessions: ACTIVITY[] = [];

        if (props.value) {
            props.value.forEach((selectedSession: CB_ACTIVITY) => {
                const _matchingSession = _activities.find(
                    (session: ACTIVITY) => selectedSession.id === session.id,
                );

                if (_matchingSession) {
                    _matchingSessions.push(_matchingSession);
                }
            });
        }

        _setActivitiesToDisplay(_matchingSessions);
    }, [props.value, _activities]);

    /* Handlers */
    function _onAddNewSessionClick() {
        _setIsDialogOpened(true);
    }

    function _closeDialog() {
        _setIsDialogOpened(false);
    }

    function _onChange(sessions: CB_ACTIVITY[]) {
        props.onChange(sessions, props.name);
    }

    function _onActivityDeleteClick(index: number) {
        const _newValue = props.value ? [...props.value] : [];
        _newValue.splice(index, 1);

        _onChange(_newValue);
    }

    function _onActivityArrowClick(index: number, direction: ArrowDirection) {
        const _newValue = props.value ? [...props.value] : [];
        const _indexToUpdate = direction === 'left' ? index - 1 : index + 1;
        const _movedBlock = _newValue[_indexToUpdate];
        _newValue[_indexToUpdate] = _newValue[index];
        _newValue[_indexToUpdate].sort = _indexToUpdate;
        _newValue[index] = _movedBlock;
        _newValue[index].sort = index;

        _onChange(_newValue);
    }

    /* Render */
    return (
        <div className={styles.container}>
            <div className={styles.selectedSessions}>
                <div className={styles.grid}>
                    <div className={styles.gridItem}>
                        <ButtonBase
                            className={cn(styles.addButton, styles.session)}
                            onClick={_onAddNewSessionClick}>
                            <AddIcon color="primary" />
                        </ButtonBase>
                    </div>

                    {_activitiesToDisplay.map(
                        (session: ACTIVITY, index: number) => {
                            return (
                                <div
                                    className={styles.gridItem}
                                    key={session.id}>
                                    <ActivitiesPickerItem
                                        activity={session}
                                        index={index}
                                        totalCount={_activitiesToDisplay.length}
                                        onDelete={_onActivityDeleteClick}
                                        onArrowClick={_onActivityArrowClick}
                                    />
                                </div>
                            );
                        },
                    )}
                </div>
            </div>
            <ActivitiesPickerDialog
                multiselect
                value={props.value}
                onClose={_closeDialog}
                open={_isDialogOpened}
                onChange={_onChange}
            />
        </div>
    );
};

export default SessionPicker;
