import React, {useContext} from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';

import Loader from 'components/loader/loader';
import {UserContext, AUTH_STATE} from 'providers/user.provider';

interface Props {
    path: string; // todo replace with enums
    component: React.ElementType;
}

const LoggedOutRoute: React.FC<Props> = (props: Props) => {
    const _userContext = useContext(UserContext);

    return (
        <Route
            render={() => {
                switch (_userContext.state) {
                    case AUTH_STATE.AUTHENTICATED:
                        return (
                            <Redirect
                                to={{
                                    pathname: '/audio-content',
                                }}
                            />
                        );
                    case AUTH_STATE.NOT_AUTHENTICATED:
                        return <props.component />;

                    default:
                        return <Loader />;
                }
            }}
        />
    );
};
export default LoggedOutRoute;
