import React from 'react';
// import CalendarViewIcon from '@material-ui/icons/CalendarViewDay';
import HeadSetIcon from '@material-ui/icons/Headset';
import HelpIcon from '@material-ui/icons/HelpOutline';
import LabelIcon from '@material-ui/icons/Label';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import School from '@material-ui/icons/School';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

import ActivitiesPage from 'pages/activities/activities.view';
import ActivityPage from 'pages/activity/activity.view';

import ExercisesPage from 'pages/exercises/exercises';
import RelaxPage from 'pages/relax/relax';
import PlaygroundPage from 'pages/playground/playground';

import ProgramsPage from 'pages/programs/programs.view';
import ProgramPage from 'pages/program/program.view';
import CourseChatsPage from 'pages/course-chat/course-chat.view';

import TagsPage from 'pages/tags/tags.view';

import HelpPage from 'pages/help/help';

import {ReactComponent as ImgExercises} from 'assets/images/screens/exercises.svg';
import {ReactComponent as ImgRelax} from 'assets/images/screens/relax.svg';

import {} from '@material-ui/core';
export interface SubRoute {
    path: string;
    component: React.ElementType;
}

export interface SidebarRoute {
    id: string;
    label: string;
    path: string;
    icon: React.ElementType;
    component: React.ElementType;
    showInSidebar: boolean;
}

export interface RouteGroup {
    id: string;
    label?: string;
    routes: SidebarRoute[];
}

export type RouteConfig = RouteGroup[];

const ExercisesIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            {...props}
            className={'icon_' + props.color}
            viewBox="0 0 128 128">
            <ImgExercises />
        </SvgIcon>
    );
};

const RelaxIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            {...props}
            className={'icon_' + props.color}
            viewBox="0 0 128 128">
            <ImgRelax />
        </SvgIcon>
    );
};

const routeconfig: RouteConfig = [
    {
        id: 'app',
        label: 'Mobile app',
        routes: [
            {
                id: 'exercices',
                label: 'Exercises screen',
                path: '/exercises',
                icon: ExercisesIcon,
                component: ExercisesPage,
                showInSidebar: true,
            },
            {
                id: 'relax',
                label: 'Relax screen',
                path: '/relax',
                icon: RelaxIcon,
                component: RelaxPage,
                showInSidebar: true,
            },
        ],
    },
    {
        id: 'content',
        label: 'Content',
        routes: [
            {
                id: 'audio-content-detail',
                label: 'Audio content detail',
                path: '/audio-content/:id',
                icon: HeadSetIcon,
                component: ActivityPage,
                showInSidebar: false,
            },
            {
                id: 'audio-content',
                label: 'Audio content',
                path: '/audio-content',
                icon: HeadSetIcon,
                component: ActivitiesPage,
                showInSidebar: true,
            },
            {
                id: 'programs',
                label: 'Programs',
                path: '/programs/:id',
                icon: PlaylistPlayIcon,
                component: ProgramPage,
                showInSidebar: false,
            },
            {
                id: 'programs-detail',
                label: 'Programs',
                path: '/programs',
                icon: PlaylistPlayIcon,
                component: ProgramsPage,
                showInSidebar: true,
            },
            {
                id: 'tags',
                label: 'Tags',
                path: '/tags',
                icon: LabelIcon,
                component: TagsPage,
                showInSidebar: true,
            },
        ],
    },
    {
        id: 'Kajabi Participants',
        routes: [
            {
                id: 'kajabi_participants',
                label: 'Kajabi Participants',
                path: '/kajabi-participants',
                icon: School,
                component: CourseChatsPage,
                showInSidebar: true,
            },
        ],
    },
    {
        id: 'other',
        routes: [
            {
                id: 'help',
                label: 'Help',
                path: '/help',
                icon: HelpIcon,
                component: HelpPage,
                showInSidebar: true,
            },
        ],
    },
];

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    routeconfig[0].routes.push({
        id: 'playground',
        label: 'Playground',
        path: '/playground',
        icon: OfflineBoltIcon,
        component: PlaygroundPage,
        showInSidebar: true,
    });
}

export default routeconfig;
