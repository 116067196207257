import React, {createContext, useState} from 'react';

import Snackbar from 'components/snackbar/snackbar';
import {
    SnackbarProviderProps,
    SnackbarState,
    SnackbarAPI,
} from './snackbar.types';

/* Initial State */
const _initialState: SnackbarState = {
    isOpened: false,
    activeSnackbar: null,
};

// Create Snackbar Context
export const snackbarContext = createContext<SnackbarAPI>({} as SnackbarAPI);
const {Provider} = snackbarContext;

const SnackbarProvider = (props: SnackbarProviderProps): JSX.Element => {
    const [_snackbarState, _setSnackbarState] = useState(_initialState);

    // Show an error
    const _error = (message: string, caption = '') => {
        _setSnackbarState({
            isOpened: true,
            activeSnackbar: {
                severity: 'error',
                message,
                caption,
            },
        });
    };

    // Show success message
    const _success = (message: string, caption = '') => {
        _setSnackbarState({
            isOpened: true,
            activeSnackbar: {
                severity: 'success',
                message,
                caption,
            },
        });
    };

    const _close = () => {
        _setSnackbarState(_initialState);
    };

    const _snackbarAPI: SnackbarAPI = {
        error: _error,
        success: _success,
    };

    return (
        <>
            <Provider value={_snackbarAPI}>{props.children}</Provider>
            <Snackbar
                isOpened={_snackbarState.isOpened}
                config={_snackbarState.activeSnackbar}
                onClose={_close}
            />
        </>
    );
};

export default SnackbarProvider;
