import React, {useState} from 'react';
import cn from 'classnames';

import Backdrop from '@material-ui/core/Backdrop';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';

import Header, {HeaderProps} from 'components/header/header';

import styles from './page.module.css';

interface horizontalPadding {
    left: boolean;
    right: boolean;
}

export interface FabOption {
    label: string;
    value: any;
}

export interface PageProps extends HeaderProps {
    children: React.ReactNode;
    onFabPress?: (value?: any) => void;
    fabOptions?: FabOption[];
    isLoading?: boolean;
    loadingText?: string;
    horizontalPadding?: horizontalPadding;
}

const defaultSideMargin = {
    left: true,
    right: true,
};

const AppScreen = ({
    children,
    isLoading = false,
    loadingText,
    onFabPress,
    fabOptions,
    horizontalPadding = defaultSideMargin,
    ...headerProps
}: PageProps): JSX.Element => {
    /* Hooks n State */
    const [_fabMenu, _setFabMenu] = useState<HTMLElement | null>(null);

    /* Handlers */
    function _onFabPress(event: React.MouseEvent<HTMLButtonElement>) {
        if (fabOptions) {
            _setFabMenu(event.currentTarget);
        } else if (onFabPress) {
            onFabPress();
        }
    }

    function _onFabOptionClick(type: string): void {
        _setFabMenu(null);
        if (onFabPress) {
            onFabPress(type);
        }
    }

    function _onFabMenuClose() {
        _setFabMenu(null);
    }

    /* Render */
    const _contentClasses = cn(styles.content, {
        [styles.contentPaddingLeft]: horizontalPadding.left,
        [styles.contentPaddingRight]: horizontalPadding.right,
    });
    return (
        <div className={styles.container}>
            <Header {...headerProps} />

            <div className={_contentClasses}>{children}</div>
            {onFabPress && (
                <>
                    <Fab
                        aria-controls="page-fab"
                        className={styles.fab}
                        color="primary"
                        onClick={_onFabPress}>
                        <AddIcon />
                    </Fab>
                    {fabOptions && (
                        <Menu
                            id="page-fab"
                            anchorEl={_fabMenu}
                            open={Boolean(_fabMenu)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            onClose={_onFabMenuClose}>
                            {fabOptions.map((fabOption: FabOption) => {
                                return (
                                    <MenuItem
                                        key={fabOption.value}
                                        onClick={() =>
                                            _onFabOptionClick(fabOption.value)
                                        }>
                                        {fabOption.label}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    )}
                </>
            )}
            <Backdrop open={isLoading} classes={{root: styles.backdrop}}>
                <CircularProgress color="secondary" />
                {loadingText && (
                    <div className={styles.loadingText}>{loadingText}</div>
                )}
            </Backdrop>
        </div>
    );
};

export default AppScreen;
