import firebase from 'firebase/app';
import {firestoreObjectToTag} from 'helpers/tags.helper';
import {FIRESTORE_TAG, TAG} from 'types/tags.types';

/* Get all tags */
export async function getAllTags(
    handler: (tags: TAG[]) => void,
): Promise<() => void> {
    try {
        const _db = firebase.firestore();

        const _subscription = _db
            .collection('tags')
            .orderBy('label')
            .onSnapshot((snapshot) => {
                const _tags: TAG[] = [];
                snapshot.docs.forEach((tag) => {
                    const _tagData = tag.data() as FIRESTORE_TAG;

                    _tags.push(firestoreObjectToTag(_tagData, tag.id));
                });
                handler(_tags);
            });

        return _subscription;
    } catch (error) {
        throw new Error(error);
    }
}

/* Create new tag */
export async function createTag(label: string): Promise<TAG> {
    try {
        const _db = firebase.firestore();

        const _saveDate = new Date();

        const _newTagRef = await _db.collection('tags').add({
            label,
            lastSaveDate: _saveDate,
            lastSyncDate: null,
        });

        const _newTag = await _newTagRef.get();

        return _newTag.data() as TAG;
    } catch (error) {
        throw new Error(error);
    }
}

export async function updateTag(id: string, data: Partial<TAG>): Promise<TAG> {
    try {
        const _saveDate = new Date();

        const _dataToSave: Partial<TAG> = {
            ...data,
            lastSaveDate: _saveDate,
        };

        const _db = firebase.firestore();
        const _tagRef = _db.collection('tags').doc(id);

        await _tagRef.set(_dataToSave, {merge: true});

        const _newTag = await _tagRef.get();

        return _newTag.data() as TAG;
    } catch (error) {
        throw new Error(error);
    }
}

export async function syncTagsWithProduction(): Promise<void> {
    try {
        const _cloudHandler = firebase
            .functions()
            .httpsCallable('syncTagsWithProduction');

        await _cloudHandler({
            isDev: process.env.REACT_APP_ENVIRONMENT === 'development',
        });
    } catch (error) {
        throw new Error(error);
    }
}
