import {useContext} from 'react';

import {
    ActivitiesContext,
    ActivitiesAPI,
} from 'providers/activities/activities.provider';

function useActivities(): ActivitiesAPI {
    const _activities = useContext(ActivitiesContext);

    return _activities;
}

export default useActivities;
