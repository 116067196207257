import {
    ACTIVITY,
    ACTIVITY_STATUS,
    CONTENT_TYPE,
    VOICE,
    SCREEN,
} from 'types/activity.types';

const initialActivityState: ACTIVITY = {
    id: '',
    author: '',
    contentTags: [],
    contentType: CONTENT_TYPE.UNSPECIFIED,
    createdAt: new Date(),
    downloadUrl: '',
    draft: true,
    durationInSeconds: 0,
    hotness: 0,
    hasAudio: false,
    hasTranscript: false,
    lastSaveDate: null,
    lastSyncDate: null,
    licensed: false,
    premium: false,
    previewAudioUrl: '',
    public: false,
    releaseDate: null,
    status: ACTIVITY_STATUS.DRAFT,
    screen: SCREEN.UNSPECIFIED,
    shortBlurb: '',
    streamableUrl: '',
    thumbnailUrl: '',
    title: 'Untitled',
    transcriptionUrl: '',
    voice: VOICE.UNSPECIFIED,
    voiceActor: '',
    hidden: false,
};
export default initialActivityState;
