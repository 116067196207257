import React from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {IconButton, Theme} from '@material-ui/core';

const stylesEditIcon = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.primary.dark,
            },
            borderRadius: '50%',
            color: theme.palette.primary.contrastText,
            width: '6vw',
            height: '6vw',
            transition: 'none',
        },
    });

const EditIconButton = withStyles(stylesEditIcon)(IconButton);

const EditIconAbsoluteStyle = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.primary.dark,
            },
            borderRadius: '50%',
            color: theme.palette.primary.contrastText,
            width: '6vw',
            height: '6vw',
            position: 'absolute',
            transition: 'none',
        },
    });
const EditIconButtonAbsolute = withStyles(EditIconAbsoluteStyle)(IconButton);

export {EditIconButton, EditIconButtonAbsolute};
