import React, {useEffect, useState} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';

import {SnackbarConfig, Severity} from 'providers/snackbar/snackbar.types';

import styles from './snackbar.module.css';

interface SnackbarProps {
    isOpened: boolean;
    onClose?: () => void;
    config: SnackbarConfig | null;
}

const Alert = (props: AlertProps): JSX.Element => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CustomSnackbar = (props: SnackbarProps): JSX.Element | null => {
    /* Hooks n States */
    const [_isOpened, _setIsOpened] = useState<boolean>(false);
    const [
        _activeSnackbar,
        _setActiveSnackbar,
    ] = useState<SnackbarConfig | null>(null);

    useEffect(() => {
        if (props.isOpened) {
            _setActiveSnackbar(props.config);
        } else {
            _setIsOpened(false);
        }
    }, [props.isOpened, props.config]);

    // Listen for changes on _activeSnackbar state to actually show it when it exists.
    useEffect(() => {
        if (_activeSnackbar) {
            _setIsOpened(true);
        }
    }, [_activeSnackbar]);

    // Listen for changes on _activeSnackbar state to actually show it when it exists.
    useEffect(() => {
        if (!_isOpened) {
            setTimeout(() => {
                _setActiveSnackbar(null);
            }, 300);
        }
    }, [_isOpened]);

    if (!_activeSnackbar) return null;

    return (
        <Snackbar
            open={_isOpened}
            onClose={props.onClose}
            autoHideDuration={5000}>
            <div>
                <Alert
                    severity={_activeSnackbar.severity}
                    onClose={props.onClose}>
                    <div className={styles.container}>
                        {_activeSnackbar.caption && (
                            <div className={styles.caption}>
                                {_activeSnackbar.caption}
                            </div>
                        )}
                    </div>
                    <div className={styles.message}>
                        {_activeSnackbar.message || ''}
                    </div>
                </Alert>
            </div>
        </Snackbar>
    );
};

export default CustomSnackbar;
