import React from 'react';
import {HeaderGroup} from 'react-table';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './sorting-indicator.module.css';

// To do, find a better type than any
interface Props {
    column: HeaderGroup<any>;
}

const SortingIndicator: React.FC<Props> = (props: Props) => {
    const {column} = props;

    let _IconNode = null;
    if (column.isSorted) {
        const _Icon = column.isSortedDesc ? ExpandLessIcon : ExpandMoreIcon;

        _IconNode = (
            <_Icon
                {...column.getHeaderProps(column.getSortByToggleProps())}
                classes={{root: styles.icon}}
            />
        );
    }
    return <div className={styles.sort}>{_IconNode}</div>;
};
export default SortingIndicator;
