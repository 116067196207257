import firebase, {firestore} from 'firebase/app';
import {
    FIRESTORE_APP_SCREEN,
    FIRESTORE_CONTENT_BLOCK,
    SCREEN_WITH_BLOCKS,
    BLOCK_TYPE,
    CONTENT_BLOCK,
    emptyCarouselBlock,
    emptyUpsellBlock,
    APP_SCREEN,
    CB_BUTTON_STYLE,
    CB_FEATURED_CONTENT,
    emptyExpertsBlock,
    emptyProgramsBlock,
} from 'types/app-screen.types';
import {
    fireStoreObjectToScreen,
    firestoreObjectToBlock,
    firestoreObjectToBlocks,
} from 'helpers/app-screen.helper';
import {ACTIVITY_STATUS} from 'types/activity.types';
import {TEMP_PREFIX} from 'constants/strings.constants';

/* Get a single screen */
export async function getAppScreen(id: string): Promise<SCREEN_WITH_BLOCKS> {
    try {
        const _docRef = firestore().collection('screens').doc(id);
        const _doc = await _docRef.get();

        if (_doc.exists) {
            const _firestoreBlocks = await _docRef
                .collection('blocks')
                .orderBy('sort')
                .where('status', '==', 'published')
                .get();

            const _screen = _doc.data() as FIRESTORE_APP_SCREEN;
            const _blocks: FIRESTORE_CONTENT_BLOCK[] = [];
            _firestoreBlocks.docs.forEach((blockDoc) => {
                const _block: FIRESTORE_CONTENT_BLOCK = {
                    ...(blockDoc.data() as FIRESTORE_CONTENT_BLOCK),
                    id: blockDoc.id,
                };

                _blocks.push(_block);
            });

            return {
                screen: fireStoreObjectToScreen(_screen, id),
                blocks: firestoreObjectToBlocks(_blocks),
            };
        }
        throw new Error("Screen doesn't exist");
    } catch (error) {
        throw new Error(error);
    }
}

export async function createNewBlockForScreen(
    screenId: string,
    type: BLOCK_TYPE,
    sort: number,
): Promise<CONTENT_BLOCK> {
    try {
        const _screenDoc = firestore().collection('screens').doc(screenId);

        let _newBlock = null;

        switch (type) {
            case BLOCK_TYPE.GALLERY_CAROUSEL:
                _newBlock = emptyCarouselBlock;
                break;
            case BLOCK_TYPE.PROGRAMS_CAROUSEL:
                _newBlock = emptyProgramsBlock;
                break;
            case BLOCK_TYPE.UPSELL:
                _newBlock = emptyUpsellBlock;
                break;
            case BLOCK_TYPE.EXPERTS:
                _newBlock = emptyExpertsBlock;
                break;

            default:
                break;
        }

        const _newDocRef = await _screenDoc.collection('blocks').add({
            ..._newBlock,
            createdAt: new Date(),
            sort,
        });
        const _newDoc = await _newDocRef.get();

        return firestoreObjectToBlock({
            ..._newDoc.data(),
            id: _newDocRef.id,
        } as FIRESTORE_CONTENT_BLOCK);
    } catch (error) {
        throw new Error(error);
    }
}

async function saveBlock(
    screenId: string,
    block: CONTENT_BLOCK,
    newSaveDate: Date,
) {
    const _blockDoc = firestore()
        .collection('screens')
        .doc(screenId)
        .collection('blocks')
        .doc(block.id);

    const {
        title,
        internalTitle,
        text,
        activities,
        programmes,
        tags,
        experts,
        sort,
        endDate,
        startDate,
        status,

        buttonLabel,
        buttonStyle,
        buttonUrl,
        buttonPremium,
    } = block;

    const _block: Partial<FIRESTORE_CONTENT_BLOCK> = {
        sort,
        status,
    };

    switch (block.type) {
        case BLOCK_TYPE.GALLERY_CAROUSEL:
            _block.content = {
                title,
                internalTitle,
                text,
            };
            _block.activities = activities;
            _block.tags = tags;
            break;
        case BLOCK_TYPE.PROGRAMS_CAROUSEL:
            _block.content = {
                title,
                internalTitle,
                text,
            };
            _block.programmes = programmes;
            break;
        case BLOCK_TYPE.UPSELL:
            _block.content = {
                title,
                internalTitle,
                text,
                button: {
                    label: buttonLabel || '',
                    style: buttonStyle || CB_BUTTON_STYLE.DARK,
                    url: {
                        url: buttonUrl || '',
                        premium: buttonPremium || false,
                    },
                },
            };
            break;
        case BLOCK_TYPE.EXPERTS:
            _block.content = {
                title,
                internalTitle,
                text,
            };
            _block.experts = experts;
            break;

        default:
            break;
    }

    await _blockDoc.set(
        {
            ..._block,
            startDate,
            endDate,
            lastSaveDate: newSaveDate,
        },
        {
            merge: true,
        },
    );
}

export async function saveScreen(
    screen: APP_SCREEN,
    blocks: CONTENT_BLOCK[],
    fromSync = false,
): Promise<SCREEN_WITH_BLOCKS> {
    try {
        const _newSaveDate = new Date();
        const {id: screenId, ...screenData} = screen;

        if (!fromSync) {
            const _promises = [];
            // Save all changed blocks
            for (const block of blocks) {
                if (block.isDirty) {
                    _promises.push(saveBlock(screenId, block, _newSaveDate));
                }
            }

            await Promise.all(_promises);
        }

        const _screenRef = firestore().collection('screens').doc(screenId);
        const _firestoreBlocks = await _screenRef
            .collection('blocks')
            .orderBy('sort')
            .where('status', '==', 'published')
            .get();

        const _featured: CB_FEATURED_CONTENT[] = [];
        if (screenData.featured) {
            screenData.featured.forEach((item) => {
                if (!item.id?.startsWith(TEMP_PREFIX)) {
                    _featured.push(item);
                }
            });
        }

        const _screenUpdateData: Partial<APP_SCREEN> = {
            featured: _featured,
            lastSaveDate: _newSaveDate,
            status: ACTIVITY_STATUS.NOT_SYNCED,
        };

        if (fromSync) {
            _screenUpdateData.lastSyncDate = _newSaveDate;
            _screenUpdateData.status = ACTIVITY_STATUS.PUBLISHED;
        }

        await _screenRef.set(_screenUpdateData, {
            merge: true,
        });

        // Get new block values
        const _blocks: FIRESTORE_CONTENT_BLOCK[] = [];
        _firestoreBlocks.docs.forEach((blockDoc) => {
            const _block: FIRESTORE_CONTENT_BLOCK = {
                ...(blockDoc.data() as FIRESTORE_CONTENT_BLOCK),
                id: blockDoc.id,
            };

            _blocks.push(_block);
        });

        // Get new screen value
        const _updatedScreen = await _screenRef.get();

        return {
            screen: fireStoreObjectToScreen(
                _updatedScreen.data() as FIRESTORE_APP_SCREEN,
                screenId,
            ),
            blocks: firestoreObjectToBlocks(_blocks),
        };
    } catch (error) {
        throw new Error(error);
    }
}

/* Sync */

export async function syncScreenWithProduction(id: string): Promise<void> {
    try {
        const _cloudHandler = firebase
            .functions()
            .httpsCallable('syncScreenWithProduction');
        await _cloudHandler({
            id,
            isDev: process.env.REACT_APP_ENVIRONMENT === 'development',
        });
    } catch (error) {
        throw new Error(error);
    }
}
