import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ScreenBlock from 'components/screen-block/screen-block.view';
import {CONTENT_BLOCK, SortDirection} from 'types/app-screen.types';
import {TAG} from 'types/tags.types';

import blockStyles from 'components/screen-block/screen-block.module.css';

interface UpsellBlockProps {
    block: CONTENT_BLOCK;
    blocksCount: number;
    errors: any;
    onChange: (blockId: string, key: string, value: any) => void;
    onSort: (sort: number, direction: SortDirection) => void;
}

const UpsellBlock = (props: UpsellBlockProps): JSX.Element => {
    /* Hooks n State */

    /* Handlers */
    function _onInputChange({target}: React.ChangeEvent<HTMLInputElement>) {
        props.onChange(props.block.id, target.name, target.value);
    }

    function _onCheckboxChange({target}: React.ChangeEvent<HTMLInputElement>) {
        props.onChange(props.block.id, target.name, target.checked);
    }

    /* Render */
    const _defaultInputProps: TextFieldProps = {
        variant: 'outlined',
        classes: {
            root: blockStyles.formInput,
        },
    };

    return (
        <ScreenBlock
            block={props.block}
            blocksCount={props.blocksCount}
            onChange={props.onChange}
            onSort={props.onSort}
            errors={props.errors}>
            {/* General info */}

            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>General info</div>
                <div className={blockStyles.inputRow}>
                    {/* Title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'title' + props.block.id}
                            label="Title*"
                            name="title"
                            value={props.block.title}
                            onChange={_onInputChange}
                            error={props.errors.title !== undefined}
                            helperText={props.errors.title}
                        />
                    </div>

                    {/* Internal title */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'internal-title' + props.block.id}
                            label="Internal title"
                            name="internalTitle"
                            value={props.block.internalTitle}
                            onChange={_onInputChange}
                            error={props.errors.internalTitle !== undefined}
                            helperText={props.errors.internalTitle}
                        />
                    </div>
                </div>
                <div className={blockStyles.inputRow}>
                    {/* Description */}
                    <div
                        className={cn(
                            blockStyles.formControl,
                            blockStyles.formControlXL,
                        )}>
                        <TextField
                            {..._defaultInputProps}
                            id={'text' + props.block.id}
                            label="Text*"
                            name="text"
                            value={props.block.text}
                            onChange={_onInputChange}
                            error={props.errors.text !== undefined}
                            helperText={props.errors.text}
                            multiline={true}
                            rows={2}
                        />
                    </div>
                </div>
            </div>
            <div className={blockStyles.inputGroup}>
                <div className={blockStyles.inputGroupTitle}>Button</div>
                <div className={blockStyles.inputRow}>
                    {/* Button */}
                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'button-label' + props.block.id}
                            label="Button label*"
                            name="buttonLabel"
                            value={props.block.buttonLabel}
                            onChange={_onInputChange}
                            error={props.errors.button !== undefined}
                            helperText={props.errors.button}
                        />
                    </div>

                    <div className={blockStyles.formControl}>
                        <TextField
                            {..._defaultInputProps}
                            id={'button-link' + props.block.id}
                            label="Button link"
                            name="buttonUrl"
                            value={props.block.buttonUrl}
                            onChange={_onInputChange}
                            error={props.errors.button !== undefined}
                            helperText={props.errors.button}
                        />
                    </div>
                </div>
                <div className={blockStyles.inputRow}>
                    <div className={blockStyles.formControl}>
                        {/* Public listen */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.block.buttonPremium}
                                    onChange={_onCheckboxChange}
                                    name="buttonPremium"
                                />
                            }
                            label="Links to premium content"
                        />
                    </div>
                </div>
            </div>
        </ScreenBlock>
    );
};

export default UpsellBlock;
