import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ScheduleIcon from '@material-ui/icons/Schedule';
import UpdateIcon from '@material-ui/icons/Update';

import {underScoresToSpaces} from 'helpers/strings.helper';
import {ACTIVITY_STATUS} from 'types/activity.types';

import styles from './status-chip.module.css';
import moment from 'moment';

interface StatusChipProps {
    clickable?: boolean;
    onChange?: (status: ACTIVITY_STATUS) => void;
    releaseDate?: Date | string | null;
    lastSaveDate?: Date | null;
    lastSyncDate?: Date | null;
    status: ACTIVITY_STATUS;
    minimal?: boolean;
}

type WarningIcon = 'calendar' | 'sync' | null;

const StatusChip = (props: StatusChipProps): JSX.Element => {
    /* Hooks n State */
    const [_isMenuVisible, _setIsMenuVisible] = useState<boolean>(false);
    const _chipRef = useRef<HTMLButtonElement | null>(null);
    const [_shouldShowWarning, _setShouldShowWarning] = useState<WarningIcon>(
        null,
    );

    useEffect(() => {
        let _newWarningState: WarningIcon = null;
        if (
            props.lastSaveDate?.getTime() !== props.lastSyncDate?.getTime() &&
            props.status === ACTIVITY_STATUS.UNPUBLISHED
        ) {
            _newWarningState = 'sync';
        }

        if (!props.releaseDate || moment(props.releaseDate).isAfter(moment())) {
            _newWarningState = 'calendar';
        }

        if (_newWarningState !== _shouldShowWarning) {
            _setShouldShowWarning(_newWarningState);
        }
    }, [
        props.status,
        props.lastSaveDate,
        props.lastSyncDate,
        props.releaseDate,
    ]);

    /* Handlers */
    function _onLabelPress() {
        if (props.clickable) {
            _setIsMenuVisible(true);
        }
    }

    function _onMenuClose() {
        _setIsMenuVisible(false);
    }

    function _onItemSelect(status: ACTIVITY_STATUS) {
        if (props.onChange) {
            props.onChange(status);
        }
        _setIsMenuVisible(false);
    }

    let _WarningIcon = null;
    switch (_shouldShowWarning) {
        case 'calendar':
            _WarningIcon = (
                <ScheduleIcon
                    classes={{root: styles.warningIcon}}
                    fontSize="small"
                />
            );
            break;

        case 'sync':
            _WarningIcon = (
                <UpdateIcon
                    classes={{root: styles.warningIcon}}
                    fontSize="small"
                />
            );
            break;

        default:
            break;
    }

    // Minimal chip
    if (props.minimal) {
        if (_shouldShowWarning && _WarningIcon) {
            return _WarningIcon;
        }
        return (
            <div className={styles.minimalContainer}>
                <div
                    className={cn(
                        styles.minimalChip,
                        styles['chip_' + props.status],
                    )}></div>
            </div>
        );
    }

    const _isClickable = props.clickable;

    let _statusText = underScoresToSpaces(props.status);

    if (props.status === ACTIVITY_STATUS.PUBLISHED) {
        _statusText = 'published';
    }

    if (props.releaseDate) {
        const _releaseDateMoment = moment(props.releaseDate);
        if (
            props.status === ACTIVITY_STATUS.PUBLISHED &&
            _releaseDateMoment.isAfter(moment())
        ) {
            _statusText += ' on ' + _releaseDateMoment.format('DD/M');
        }
    }

    /* Render */
    return (
        <div className={styles.container}>
            {_WarningIcon}
            <Chip
                aria-controls="status-menu"
                aria-haspopup
                classes={{
                    root: styles['chip_' + props.status],
                    label: styles.label,
                }}
                deleteIcon={_isClickable ? <ArrowDownIcon /> : undefined}
                component="button"
                clickable={_isClickable}
                label={_statusText}
                onClick={_onLabelPress}
                onDelete={_isClickable ? _onLabelPress : undefined}
                ref={_chipRef}
                size="small"
            />
            <Menu
                anchorEl={_chipRef.current}
                id="status-menu"
                open={_isMenuVisible}
                onClose={_onMenuClose}>
                {(props.status === ACTIVITY_STATUS.DRAFT ||
                    props.status === ACTIVITY_STATUS.UNPUBLISHED) && (
                    <MenuItem
                        onClick={() =>
                            _onItemSelect(ACTIVITY_STATUS.PUBLISHED)
                        }>
                        {ACTIVITY_STATUS.PUBLISHED}
                    </MenuItem>
                )}
                {props.status !== ACTIVITY_STATUS.UNPUBLISHED && (
                    <MenuItem
                        onClick={() =>
                            _onItemSelect(ACTIVITY_STATUS.UNPUBLISHED)
                        }>
                        {ACTIVITY_STATUS.UNPUBLISHED}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default StatusChip;
