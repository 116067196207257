import React from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import {Theme} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            border: 0,
            margin: 0,
            padding: 0,
            color: theme.palette.primary.light,
            width: '6vw',
            height: '6vw',
        },
    });
const StyledNoImageIcon = withStyles(styles)(BrokenImageIcon);
export default StyledNoImageIcon;
