import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import {ModalState} from 'types/modal.types';

import styles from './modal.module.css';

interface ModalProps extends ModalState {
    close: () => void;
    promiseData: any;
}

const Modal = (props: ModalProps): JSX.Element | null => {
    const [_activeModal, _setActiveModal] = useState<ModalState | null>(null);
    const [_textInputValue, _setTextInputValue] = useState<string>('');

    useEffect(() => {
        if (props.isOpened) {
            // Set default text value if necessary
            _setTextInputValue(props.textInputValue || '');

            const {
                component,
                title,
                message,
                buttons,
                isOpened,
                textInputConfig,
                textInputValue,
            } = props;

            _setActiveModal({
                component,
                title,
                message,
                buttons,
                isOpened,
                textInputConfig,
                textInputValue,
            });
        } else {
            _setActiveModal(null);
        }
    }, [props.isOpened]);

    function _onConfirm() {
        if (_activeModal?.buttons.confirm.onPress) {
            let _returnValue = null;
            if (_textInputValue) {
                _returnValue = _textInputValue;
            }

            if (props.promiseData) {
                _returnValue = props.promiseData;
            }

            _activeModal.buttons.confirm.onPress(_returnValue);
        }
    }

    function _onCancel() {
        if (_activeModal?.buttons.cancel?.onPress) {
            _activeModal.buttons.cancel.onPress(null);
        }
    }

    /* Handlers */
    function _onTextInputValueChange(
        event: React.ChangeEvent<HTMLInputElement>,
    ) {
        _setTextInputValue(event.currentTarget.value);
    }

    /* Render */

    if (!_activeModal) return null;
    /* Buttons */
    let _CancelButton = null;
    if (_activeModal.buttons.cancel) {
        _CancelButton = (
            <Button onClick={_onCancel} color="primary">
                {_activeModal.buttons.cancel.cta}
            </Button>
        );
    }

    // Show an input from the prompt() method
    let _TextInputView = null;

    if (_activeModal.textInputConfig) {
        _TextInputView = (
            <div>
                <TextField
                    {..._activeModal.textInputConfig}
                    onChange={_onTextInputValueChange}
                    value={_textInputValue}
                />
            </div>
        );
    }

    // Show component from component() method
    let _ComponentView = null;
    if (_activeModal.component) {
        _ComponentView = (
            <div className={styles.component}>{_activeModal.component}</div>
        );
    }

    return (
        <Dialog open={_activeModal.isOpened} onClose={props.close}>
            <DialogTitle id="modal-title">{_activeModal.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="modal-description">
                    {_activeModal.message}
                </DialogContentText>
                {_TextInputView}
                {_ComponentView}
            </DialogContent>
            <DialogActions>
                {_CancelButton}
                <Button
                    color="primary"
                    autoFocus
                    variant="contained"
                    onClick={_onConfirm}>
                    {_activeModal.buttons.confirm.cta}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
