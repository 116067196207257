import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';

import {createProgram} from 'api/programs.api';
import Page from 'components/page/page';
import ProgramPickerContent from 'components/program-picker-content/program-picker-content.view';
import ProgramsOverview from 'components/programs-overview/programs-overview.view';
import usePrograms from 'hooks/use-programs.hooks';
import useModal from 'hooks/use-modal';
import {CB_PROGRAM} from 'types/app-screen.types';
import {PROGRAM, PROGRAM_SOURCE, PROGRAM_STATUS} from 'types/programs.types';

/* Interfaces */
enum PAGE_STATE {
    ERROR,
    BOOTING,
    READY,
    SAVING,
}

const ProgramsScreen = (): JSX.Element => {
    /* Hooks n State */
    const _programs = usePrograms().all;
    const [_pageState, _setPageState] = useState<PAGE_STATE>(
        PAGE_STATE.BOOTING,
    );

    const _modal = useModal();
    const _history = useHistory();

    // Init
    useEffect(() => {
        if (_programs && _pageState === PAGE_STATE.BOOTING) {
            _setPageState(PAGE_STATE.READY);
        }
    }, [_programs]);

    /* Handlers */
    function _onProgramPickerChange(sources: PROGRAM_SOURCE[]) {
        _modal.setPromiseData(sources[0]);
    }

    async function _onCreateClick() {
        const _result = await _modal.component({
            title: 'Create new program',
            message:
                'Would you like to create a new program or change an existing one?',
            component: (
                <ProgramPickerContent onChange={_onProgramPickerChange} />
            ),
        });

        if (_result.success) {
            _setPageState(PAGE_STATE.SAVING);
            let _tempNewProgram: PROGRAM | undefined = undefined;
            if (_result.value?.key) {
                _tempNewProgram = _programs.find(
                    (program) =>
                        program.key === _result.value.key &&
                        program.version === _result.value.version,
                );
                if (_tempNewProgram) {
                    let _highestVersion = _tempNewProgram.version;
                    _programs.forEach((program) => {
                        if (
                            program.key === _tempNewProgram?.key &&
                            program.version > _highestVersion
                        ) {
                            _highestVersion = program.version;
                        }
                    });
                    _tempNewProgram.version = _highestVersion;
                }
            }

            _setPageState(PAGE_STATE.READY);
            const _newProgramId = await createProgram(_tempNewProgram);
            _history.push('/programs/' + _newProgramId);
        }
    }

    /* Render */
    const _isLoading =
        _pageState === PAGE_STATE.BOOTING || _pageState === PAGE_STATE.SAVING;

    const _onFabPress =
        process.env.REACT_APP_ENVIRONMENT === 'development'
            ? _onCreateClick
            : undefined;
    return (
        <Page isLoading={_isLoading} title="Programs" onFabPress={_onFabPress}>
            <ProgramsOverview programs={_programs} />
        </Page>
    );
};

export default ProgramsScreen;
