import React from 'react';

import styles from './transcipt-preview-image.module.css';

import {IMAGE_MAP} from '../../../constants/transcript.constants';

interface TranscriptPreviewImageProps {
    version: number;
}

function TranscriptPreviewImage(
    props: TranscriptPreviewImageProps,
): JSX.Element | null {
    const _imageConfig = IMAGE_MAP[props.version - 1];

    if (!_imageConfig) return null;
    return (
        <div className={styles.container}>
            <img className={styles.image} src={_imageConfig.left} />
            <img className={styles.image} src={_imageConfig.right} />
        </div>
    );
}

export default TranscriptPreviewImage;
