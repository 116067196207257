import React, {useState} from 'react';
import {Box, Button, Menu, MenuItem} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import cn from 'classnames';

import {
    TRANSCRIPT_BLOCK,
    TRANSCRIPT_BLOCK_CONTENT,
    TRANSCRIPT_BLOCK_TYPES,
} from '../../types/activity-transcript.types';

import TranscriptPreviewBlock from './block/block.view';
import TranscriptEditorDialog from '../transcript-editor-dialog/transcript-editor-dialog.view';
import {
    createNewTranscriptBlock,
    deleteTranscriptBlock,
    saveTranscriptBlock,
} from '../../api/activities.api';
import {titleCase} from '../../helpers/strings.helper';

import styles from './transcript-preview.module.css';

interface TranscriptPreviewProps {
    activityID: string;
    blocks?: TRANSCRIPT_BLOCK[];
    error?: string;
    onChange: (
        blocks: TRANSCRIPT_BLOCK[],
        shouldSaveActivity?: boolean,
    ) => Promise<void>;
}

function TranscriptPreview(props: TranscriptPreviewProps): JSX.Element {
    /* Hooks n State */
    const [
        _activeAddBlockMenu,
        _setActiveAddBlockMenu,
    ] = useState<HTMLElement | null>(null);
    const [
        _currentlyEditingBlock,
        _setCurrentlyEditingBlock,
    ] = useState<TRANSCRIPT_BLOCK | null>(null);
    const [_tempNewBlockSort, _setTempNewBlockSort] = useState<number>(-1);

    /* Handlers */
    function _onAddBlockClick(event: React.MouseEvent<HTMLButtonElement>) {
        _setActiveAddBlockMenu(event.currentTarget);
    }

    function _onAddBlockMenuClose() {
        _setActiveAddBlockMenu(null);
    }

    async function _onAddBlockItemSelect(
        type: TRANSCRIPT_BLOCK_TYPES,
        sort?: number,
    ) {
        // console.log(_tempNewBlockSort);

        let _newIndex = 0;
        if (_tempNewBlockSort > -1) {
            _newIndex = _tempNewBlockSort;
        } else if (props.blocks?.length) {
            _newIndex = props.blocks[props.blocks.length - 1].sort + 1;
        }
        _onAddBlockMenuClose();
        const _newBlock: TRANSCRIPT_BLOCK = {
            id: '',
            type: type,
            sort: _newIndex,
        };
        switch (type) {
            case TRANSCRIPT_BLOCK_TYPES.TEXT:
            case TRANSCRIPT_BLOCK_TYPES.TITLE:
            case TRANSCRIPT_BLOCK_TYPES.CALLOUT:
                _newBlock.content = {
                    text: {
                        en: '',
                    },
                };
                _setCurrentlyEditingBlock(_newBlock);
                break;
            case TRANSCRIPT_BLOCK_TYPES.IMAGE:
                _newBlock.content = {
                    version: 0,
                };
                _setCurrentlyEditingBlock(_newBlock);
                break;

            case TRANSCRIPT_BLOCK_TYPES.DIVIDER:
                const _savedBlocks = await createNewTranscriptBlock(
                    props.activityID,
                    _newBlock,
                    _tempNewBlockSort,
                );
                props.onChange(_savedBlocks, true);
                break;
            default:
                break;
        }
    }

    function _onEditorModalClose() {
        _setTempNewBlockSort(-1);
        _setCurrentlyEditingBlock(null);
    }

    async function _onTextEditorModalSave(value?: TRANSCRIPT_BLOCK_CONTENT) {
        if (_currentlyEditingBlock) {
            const _blockToSave: TRANSCRIPT_BLOCK = {
                ..._currentlyEditingBlock,
                content: value,
            };
            let _newBlocks: TRANSCRIPT_BLOCK[] = [...(props.blocks || [])];
            if (!_blockToSave.id) {
                _newBlocks = await createNewTranscriptBlock(
                    props.activityID,
                    _blockToSave,
                    _tempNewBlockSort,
                );
            } else {
                const _response = await saveTranscriptBlock(
                    props.activityID,
                    _blockToSave,
                    true,
                );
                if (_response) {
                    _newBlocks = _response;
                }
            }
            props.onChange(_newBlocks, true);
        }
        _onEditorModalClose();
    }

    function _onBlockAddBlockBelowClick(
        target: HTMLElement & EventTarget,
        id: string,
    ) {
        _setActiveAddBlockMenu(target);
        const _matchingBlock = props.blocks?.find((block) => block.id === id);

        if (_matchingBlock) {
            _setTempNewBlockSort(_matchingBlock.sort + 1);
        }
    }

    function _onBlockEditClick(id: string) {
        const _matchingBlock = props.blocks?.find((block) => block.id === id);
        if (_matchingBlock) {
            _setCurrentlyEditingBlock(_matchingBlock);
        }
    }

    async function _onBlockDeleteClick(blockID: string) {
        const _newBlocks = await deleteTranscriptBlock(
            props.activityID,
            blockID,
        );

        props.onChange(_newBlocks, true);
    }

    function _onDrag(dragIndex: number, hoverIndex: number) {
        if (typeof dragIndex !== 'undefined') {
            const _newBlocks = [...(props.blocks || [])];
            const _movedBlock = _newBlocks[dragIndex];
            _movedBlock.sort = hoverIndex;
            _movedBlock.dirty = true;

            const _replacedBlock = _newBlocks[hoverIndex];
            _replacedBlock.sort = dragIndex;
            _replacedBlock.dirty = true;

            _newBlocks[dragIndex] = _replacedBlock;
            _newBlocks[hoverIndex] = _movedBlock;

            props.onChange(_newBlocks);
        }
    }

    async function _onDragStop() {
        const _promises: Promise<TRANSCRIPT_BLOCK[] | void>[] = [];
        props.blocks?.forEach((block) => {
            if (block.dirty) {
                _promises.push(
                    saveTranscriptBlock(props.activityID, block, false),
                );
            }
        });
        props.onChange(props.blocks || [], true);
        await Promise.all(_promises);
    }

    const _addBlockClasses = cn(styles.addBlockContainer, {
        [styles.addBlockContainerEmpty]: !props.blocks?.length,
        [styles.addBlockContainerError]: props.error,
    });

    /* Render */
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.blocks}>
                    {props.blocks &&
                        props.blocks.map((block) => {
                            return (
                                <TranscriptPreviewBlock
                                    block={block}
                                    key={block.id}
                                    onAddBlockBelowClick={
                                        _onBlockAddBlockBelowClick
                                    }
                                    onDeleteClick={_onBlockDeleteClick}
                                    onEditClick={_onBlockEditClick}
                                    onDrag={_onDrag}
                                    onDragStop={_onDragStop}
                                />
                            );
                        })}
                </div>
                <div className={_addBlockClasses}>
                    {!props.blocks?.length && (
                        <div className={styles.addBlockTitle}>
                            {"This activity doesn't have any blocks yet"}
                        </div>
                    )}
                    <Button
                        startIcon={<AddIcon />}
                        color="secondary"
                        size="large"
                        onClick={_onAddBlockClick}
                        variant="contained">
                        {'Add block'}
                    </Button>
                    {props.error && (
                        <div className={styles.addBlockError}>
                            {props.error}
                        </div>
                    )}
                </div>
                <Menu
                    id="menu-transcript-add-block"
                    anchorEl={_activeAddBlockMenu}
                    open={Boolean(_activeAddBlockMenu)}
                    onClose={_onAddBlockMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    {Object.keys(TRANSCRIPT_BLOCK_TYPES).map((type) => {
                        const _value: TRANSCRIPT_BLOCK_TYPES =
                            TRANSCRIPT_BLOCK_TYPES[
                                type as keyof typeof TRANSCRIPT_BLOCK_TYPES
                            ];
                        return (
                            <MenuItem
                                key={type}
                                onClick={() => _onAddBlockItemSelect(_value)}>
                                {titleCase(_value)}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
            <TranscriptEditorDialog
                block={_currentlyEditingBlock || undefined}
                onClose={_onEditorModalClose}
                onSave={_onTextEditorModalSave}
            />
        </div>
    );
}

export default TranscriptPreview;
