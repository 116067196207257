import React, {useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import usePrograms from 'hooks/use-programs.hooks';
import {PROGRAM, PROGRAM_SOURCE} from 'types/programs.types';
import {CB_PROGRAM} from 'types/app-screen.types';

import styles from './program-picker-content.module.css';

interface ProgramPickerContent {
    selectOnly?: boolean;
    latestOnly?: boolean;
    multiselect?: boolean;
    onChange: (source: PROGRAM_SOURCE[]) => void;
    value?: CB_PROGRAM[];
}

const _initialSource = [{key: '', version: -1}];

const ProgramPickerContent = (props: ProgramPickerContent): JSX.Element => {
    /* Hooks n State */
    const [_programSource, _setProgramSource] = useState<PROGRAM_SOURCE[]>([]);
    const _programs = props.latestOnly
        ? usePrograms().allByKey
        : usePrograms().all;

    const _selectOnly = props.selectOnly;

    useEffect(() => {
        if (props.value) {
            _setProgramSource(props.value);
        }
    }, [props.value]);

    /* Handlers */
    function _onNewClick() {
        _setProgramSource(_initialSource);
        props.onChange(_initialSource);
    }

    function _onItemClick(program: PROGRAM) {
        let _newValue: PROGRAM_SOURCE[] = [..._programSource];
        if (props.multiselect) {
            const _index = _newValue.findIndex(
                (source) => program.key === source.key,
            );
            if (_index > -1) {
                _newValue.splice(_index, 1);
            } else {
                _newValue.push({
                    key: program.key,
                    sort: _newValue.length,
                });
            }

            _setProgramSource(_newValue);
            props.onChange(_newValue);
        } else {
            _newValue = [
                {
                    key: program.key,
                    version: program.version,
                },
            ];
            _setProgramSource(_newValue);
            props.onChange(_newValue);
        }
    }

    /* Render */
    return (
        <div
            className={`${styles.container} ${
                _selectOnly ? styles.containerFullWidth : ''
            }`}>
            {!_selectOnly && (
                <>
                    <div className={styles.side}>
                        <div className={styles.new}>
                            <Button
                                fullWidth
                                endIcon={
                                    _programSource[0]?.key ? undefined : (
                                        <CheckCircleIcon fontSize="small" />
                                    )
                                }
                                variant={
                                    _programSource[0]?.key
                                        ? 'outlined'
                                        : 'contained'
                                }
                                color="secondary"
                                onClick={_onNewClick}>
                                {'Create new program'}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.divider}>
                        <Divider flexItem orientation="vertical" />
                    </div>
                </>
            )}
            <div className={`${!_selectOnly ? styles.side : ''}`}>
                <div className={styles.programs}>
                    <div className={styles.programsScroll}>
                        {_programs.map((program) => {
                            const _isSelected =
                                _programSource.findIndex((source) => {
                                    let _doVersionsMatch = false;
                                    if (
                                        props.multiselect ||
                                        program.version === source.version
                                    ) {
                                        _doVersionsMatch = true;
                                    }
                                    return (
                                        program.key === source.key &&
                                        _doVersionsMatch
                                    );
                                }) > -1;

                            return (
                                <div
                                    className={styles.program}
                                    key={program.id}>
                                    <Button
                                        fullWidth
                                        endIcon={
                                            _isSelected ? (
                                                <CheckCircleIcon />
                                            ) : undefined
                                        }
                                        variant={
                                            _isSelected
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        color="secondary"
                                        onClick={() => _onItemClick(program)}>
                                        <span className={styles.programContent}>
                                            <span
                                                className={styles.programTitle}>
                                                {program.title}
                                            </span>
                                            <span
                                                className={
                                                    styles.programVersion
                                                }>
                                                {'- v' + program.version}
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgramPickerContent;
