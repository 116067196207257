import moment from 'moment';

export default [
    {
        Header: '',
        accessor: 'thumbnailUrl', // accessor is the "key" in the data
    },
    {
        Header: '',
        accessor: 'status', // accessor is the "key" in the data
    },
    {
        Header: 'Title',
        accessor: 'title',
    },
    {
        Header: 'Duration',
        accessor: 'duration',
    },
    {
        Header: 'Release date',
        accessor: 'releaseDate',
        Cell: (props: any) => {
            if (!props.value) return '/';

            return moment(props.value).format('MMM DD, YYYY');
        },
    },
    {
        Header: 'Last saved',
        accessor: (row: any) => {
            return `${moment(row.lastSaveDate).toISOString()}`;
        },
        Cell: (props: any) => {
            if (!props.value) return '/';

            return moment(props.value).format('MMM DD, YYYY');
        },
    },
    {
        Header: 'Last synced',
        accessor: (row: any) => {
            return `${moment(row.lastSyncDate).toISOString()}`;
        },
        Cell: (props: any) => {
            if (!props.value) return '/';

            return moment(props.value).format('MMM DD, YYYY');
        },
    },
    {
        Header: 'ID',
        accessor: 'id',
    },
];
