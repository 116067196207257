import {firestore} from 'firebase/app';
export async function generateId(): Promise<string> {
    try {
        const _firestore = firestore();

        const _docRef = await _firestore.collection('generated_ids').add({});
        return _docRef.id;
    } catch (error) {
        throw new Error(error);
    }
}
